import { createAxios } from "./client.js";
const client = createAxios();

export default {
  /*
   * email: llalla@sad.com
   * products: [{ product_id: 1, quantity: 1 }]
   **/

  signIn(email, password) {
    client.defaults.auth = { username: email, password: password };
    return client.get("sign-in");
  },
  verify(token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.get("verify");
  },
  refresh(token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post("refresh");
  }
};
