import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import paypal from "./modules/paypal";
import cart from "./modules/cart";
import auth from "./modules/auth";
import shoppinglist from "./modules/shoppinglist";
import pricing from "./modules/pricing";
import user from "./modules/user";
import shipment from "./modules/shipment";
import purchase from "./modules/purchase";
import product from "./modules/product";
import order from "./modules/order";
import image from "./modules/image";
import country from "./modules/country";

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      paypal,
      cart,
      auth,
      shoppinglist,
      pricing,
      user,
      shipment,
      country,
      purchase,
      product,
      order,
      image
    }
    //plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
  });
}
