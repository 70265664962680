<template>
  <div class="dib">
    <button
      @click="showPopup"
      class="f6 fw6 link dim ph3 pv2 dib white bg-piiia-grey lh-copy roboto sans-serif ttu tracked tc"
    >
      <font-awesome-icon size="lg" :icon="['fas', 'globe']" />
    </button>

    <van-popup get-container="#app" v-model="isGlobalView">
      <section class="bg-piiia-dark-grey mw5">
        <div class="cf">
          <button @click="close" class="fr f3 pa3">
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>

          <div class="pa4 w-100">
            <h2
              class="fw4 roboto sans-serif ttu b lh-copy mt0 mb3"
              v-html="$t('globe_view.country_title', { country: 'Suomi' })"
            ></h2>

            <div class="pb4">
              <p
                class="lh-copy mv0 piiia-grey karla tracked-light f4"
                v-html="$t('globe_view.country_text')"
              ></p>
              <country-changer />
            </div>
            <div class="pb4">
              <p
                class="lh-copy mv0 piiia-grey karla tracked-light f4"
                v-html="$t('globe_view.currency_text')"
              ></p>
              <currency-changer />
            </div>

            <button
              @click="save"
              class="f6 fw6 link dim ph3 pv2 mb2 dib white bg-piiia-grey lh-copy roboto sans-serif mr1 mr2-m mr3-l ttu tracked tc"
            >
              {{ $t("globe_view.confirm") }}
            </button>
          </div>
        </div>
      </section>
    </van-popup>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import CountryChanger from "./CountryChanger.vue";
import CurrencyChanger from "./CurrencyChanger.vue";

export default {
  name: "globe-view",
  components: {
    "country-changer": CountryChanger,
    "currency-changer": CurrencyChanger
  },
  computed: {
    ...mapGetters("user", {
      residentCode: "_ResidentCode",
      currency: "_Currency"
    }),
    isGlobalView: {
      get() {
        return this.$store.state.user.isGlobalView;
      },
      set(show) {
        this.$store.commit("user/setIsGlobalView", show);
      }
    }
  },
  methods: {
    showPopup() {
      this.$store.commit("user/setIsGlobalView", true);
    },
    save() {
      this.$store.commit("user/setIsBurgerMenu", false);
      this.$store.commit("user/setIsGlobalView", false);
      if (this.residentCode != null) {
        this.$store.dispatch("user/residentCode", {
          residentCode: this.residentCode,
          cookie: this.$cookie
        });
      }
      if (this.currency != null) {
        this.$store.dispatch("user/currency", {
          cookie: this.$cookie,
          currency: this.currency
        });
      }
    },
    close() {
      this.$store.commit("user/setIsGlobalView", false);
    }
  }
};
</script>
