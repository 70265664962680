import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");
import Vue from "vue";

// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: true,
  products: {},
  maxItems: 20,
  isPurchasable: true
};

// getters
const getters = {
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  products: state => {
    return state.products;
  },
  products2(state) {
    return Object.keys(state.products).map(key => {
      const item = state.products[key];
      return item;
    });
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  isPurchasable: state => {
    return state.isPurchasable;
  },
  countries: (state, getters, rootState) => {
    return rootState.country.countries;
  }
};

// actions
const actions = {
  async product({ commit, getters, dispatch }, { sku, productId, language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      var product = getters.products[sku];
      if (product == null) {
        const resp0 = await orderpayment.getProduct(
          sku,
          productId,
          language,
          getters.currency,
          getters.residentCode
        );
        product = resp0.data.product;
        commit("setProduct", { product: product, language: language });
      }
      const id = product.id;
      const resp1 = await orderpayment.isPurchasable(id, getters.residentCode);
      const isPurchasable = resp1.data.is_purchasable === "true" ? true : false;
      commit("setIsPurchasable", isPurchasable);
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  },
  async products({ commit, state, getters, dispatch }, { language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      if (getters.products2.length > 1) {
        return;
      }
      var productListId = 0;
      const resp0 = await orderpayment.productLists(language);
      resp0.data.product_lists.forEach(function(list) {
        if (list.is_default == true) {
          productListId = list.id;
        }
      });
      var pageToken = 0;
      var isNotEmpty = true;
      while (isNotEmpty) {
        // paginate until found empty response
        const { data } = await orderpayment.getProducts(
          state.maxItems,
          pageToken,
          language,
          getters.currency,
          getters.residentCode,
          productListId
        );
        const products = data.products;
        products.sort(function(a, b) {
          return a.order_at - b.order_at;
        });
        const pageNextToken = data.next_page_token;
        if (!Array.isArray(products) || !products.length) {
          // array is empty or not array
          isNotEmpty = false;
        } else {
          commit("setProducts", { products: products, language: language });
          pageToken = pageNextToken;
        }
      }
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setProduct(state, { product, language }) {
    Vue.set(state.products, product.sku, product);
  },
  setProducts(state, { products, language }) {
    products.forEach(item => {
      Vue.set(state.products, item.sku, item);
    });
  },
  setIsPurchasable(state, isPurchasable) {
    state.isPurchasable = isPurchasable;
  },
  deleteBySku(state, sku) {
    Vue.delete(state.products, sku);
  },
  delete(state, products) {
    products.forEach(item => {
      Vue.delete(state.products, item.sku);
    });
  }
};

export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations
};
