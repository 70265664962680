//https://router.vuejs.org/guide/essentials/nested-routes.html
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import { Trans } from "../i18n/trans.js";

// route-level code splitting
const ProductListView = () => import("../views/ProductList.vue");
const ProductView = () => import("../views/ProductView.vue");
const OrderSaveView = () => import("../views/OrderSaveView.vue");
const OrderErrorView = () => import("../views/OrderErrorView.vue");
const TermsView = () => import("../views/TermsView.vue");
const PrivacyView = () => import("../views/PrivacyView.vue");
const SignInView = () => import("../views/SignInView.vue");
const PurchasedProductsView = () => import("../views/PurchasedProductsView.vue");
const ShoppingListView = () => import("../views/ShoppingListView.vue");
const FAQView = () => import("../views/FAQView.vue");
const NewAccount = () => import("../views/NewAccount.vue");
const PasswordResetView = () => import("../views/PasswordResetView.vue");
const ResetPasswordView = () => import("../views/ResetPasswordView.vue");
const OrderSuccessView = () => import("../views/OrderSuccessView.vue");

export function createRouter() {
  const router = new Router({
    mode: "history",
    fallback: false,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      { path: "/:lang/product/:sku", name: "product", component: ProductView },
      { path: "/:lang/terms", name: "terms", component: TermsView },
      { path: "/:lang/privacy", name: "privacy", component: PrivacyView },
      { path: "/:lang/sign-in", name: "signIn", component: SignInView },
      { path: "/:lang/purchases", name: "purchases", component: PurchasedProductsView },
      {
        path: "/:lang/order/save",
        name: "order/save",
        component: OrderSaveView
      },
      {
        path: "/:lang/order/error",
        name: "order/error",
        component: OrderErrorView
      },
      {
        path: "/:lang/new_account/:token",
        name: "new_account",
        component: NewAccount
      },
      {
        path: "/:lang/password_reset",
        name: "password_reset",
        component: PasswordResetView
      },
      {
        path: "/:lang/reset_password/:token",
        name: "reset_password",
        component: ResetPasswordView
      },
      {
        path: "/:lang/shopping-list/:sku",
        name: "shopping_list",
        meta: { requiresAuth: true },
        component: ShoppingListView
      },
      {
        path: "/:lang/order/success/:order_hash",
        name: "order_success",
        component: OrderSuccessView
      },
      { path: "/:lang/faq", name: "faq", component: FAQView },
      { path: "/:lang", name: "home", component: ProductListView },
      {
        path: "*",
        redirect(to) {
          return Trans.getUserSupportedLang();
        }
      }
    ]
  });
  router.beforeEach((to, from, next) => {
    Trans.routeMiddleware(to, from, next);
  });
  return router;
}
