import axios from "axios";
import { i18n } from "./index";

// https://github.com/dobromir-hristov/vue-i18n-starter

const Trans = {
  get defaultLanguage() {
    return "fi";
  },
  get supportedLanguages() {
    return ["en", "fi"];
  },
  get currentLanguage() {
    return i18n.locale;
  },
  set currentLanguage(lang) {
    i18n.locale = lang;
  },
  getUserSupportedLang() {
    const userPreferredLang = Trans.getUserLang();

    // Check if user preferred browser lang is supported
    if (Trans.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang;
    }
    // Check if user preferred lang without the ISO is supported
    if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO;
    }
    return Trans.defaultLanguage;
  },
  getUserLang() {
    const lang = Trans.defaultLanguage;
    return {
      lang: lang,
      langNoISO: lang.split("-")[0]
    };
  },
  setI18nLanguageInServices(lang) {
    i18n.locale = lang;
    Trans.currentLanguage = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    return lang;
  },
  changeLanguage(lang) {
    return new Promise(function(resolve, reject) {
      if (!Trans.isLangSupported(lang))
        reject(new Error("Language not supported"));
      if (i18n.locale === lang) {
        resolve(lang);
      }
      Trans.setI18nLanguageInServices(lang);
      resolve(lang);
    });
  },
  isLangSupported(lang) {
    return Trans.supportedLanguages.includes(lang);
  },
  routeMiddleware(to, from, next) {
    const lang = to.params.lang;
    if (!Trans.isLangSupported(lang)) return next(Trans.getUserSupportedLang());
    return Trans.changeLanguage(lang).then(() => next());
  },
  /**
   * Returns a new route object that has the current language already defined
   * To be used on pages and components, outside of the main \ route, like on Headers and Footers.
   * @example <router-link :to="$i18nRoute({ name: 'someRoute'})">Click Me </router-link>
   * @param {Object} to - route object to construct
   */
  i18nRoute(to) {
    return {
      ...to,
      params: { lang: this.currentLanguage, ...to.params }
    };
  }
};

export { Trans };
