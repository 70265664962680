import Vue from "vue";
import { createApp } from "./main";
const { app, router, store } = createApp();
import paypal from "paypal-checkout";
import VueGtag from "vue-gtag";
Vue.component("paypal-button", paypal.Button.driver("vue"));
// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS
    }
  },
  router
);

router.onReady(() => {
  app.$mount("#app");
});
