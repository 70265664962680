import axios from "axios";

const domain = "https://httpbin.org";
const baseURL = `${domain}`;

//READ MORE:
// https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
// https://hackernoon.com/javascript-promises-best-practices-anti-patterns-b32309f65551
// https://pouchdb.com/2015/05/18/we-have-a-problem-with-promises.html
// https://httpbin.org/
// https://vuejs.org/v2/cookbook/using-axios-to-consume-apis.html
// https://github.com/axios/axios/tree/master/examples

export function createAxios() {
  return axios.create({
    baseURL: baseURL
  });
}
