<template>
  <select @change="changeCurrency">
    <option
      class
      v-for="curr in currencies"
      :key="curr"
      :selected="isCurrent(curr)"
      :value="curr"
      >{{ curr }}</option
    >
  </select>
</template>
<script>
import { Trans } from "../i18n/trans.js";
import { mapState, mapGetters } from "vuex";
export default {
  name: "currency-changer",
  data() {
    return {};
  },
  computed: {
    currencies() {
      return ["EUR", "USD", "GBP"];
    },
    ...mapState({
      currency: state => state.user.currency
    })
  },
  methods: {
    changeCurrency(e) {
      const currency = e.target.value;
      this.$store.commit("user/setTempCurrency", currency);
    },
    isCurrent(curr) {
      return curr === this.currency;
    }
  }
};
</script>
