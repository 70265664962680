<template>
  <router-link
    v-if="isRouter"
    :class="cssClass"
    v-on:click.native="onClick"
    :to="$i18nRoute({ name: toLink })"
  >
    <font-awesome-icon v-if="isFaIcon" fas fa :icon="faIcon" />
    {{ link }}</router-link
  >
  <a v-else :href="toLink" :class="cssClass">{{ link }}</a>
</template>
<script>
export default {
  name: "nav-link",
  props: {
    faIcon: { type: String, value: "" },
    translation: { type: String, value: "home" },
    toLink: { type: String, value: "" },
    isTranslation: { type: Boolean, default: true },
    isRouter: { type: Boolean, default: true },
    css: { type: String, default: "white" }
  },
  computed: {
    link() {
      return this.isTranslation == true
        ? this.$t(this.translation)
        : this.translation;
    },
    cssClass() {
      return (
        "f6 fw6 hover-piiia-turqoise link roboto sans-serif ttu tracked " +
        this.css
      );
    },
    isFaIcon() {
      return this.faIcon == null ? false : true;
    }
  },
  methods: {
    onClick() {
      this.$store.commit("user/setIsBurgerMenu", false);
    }
  }
};
</script>
