<template>
  <div
    v-if="!isAccept"
    class="w-100 f6 bottom-0 fixed bg-piiia-dark-grey pa2 pt3 tc"
  >
    <span
      class="piiia-grey karla tracked-light lh-copy"
      v-html="$t('cookies.text')"
    ></span>
    <button
      v-on:click="accept()"
      class="ml3 f7 fw7 link dim ph2 pv1 mb2 dib white bg-piiia-grey lh-copy roboto sans-serif mr1 mr2-m mr3-l ttu tracked tc"
    >
      {{ $t("cookies.accept") }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "cookies-privacy-police-view",
  methods: {
    accept() {
      this.$store.dispatch("user/privacyPolice", { cookie: this.$cookie });
    }
  },
  computed: {
    ...mapGetters("user", {
      isAccept: "privacyPolice"
    })
  },
  created() {},
  mounted() {},
  components: {}
};
</script>
