import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");

import { CURRENCY } from "./cookie.type";
import { RESIDENT_CODE } from "./cookie.type";
import { LANGUAGE } from "./cookie.type";
import { PRIVACY_POLICE } from "./cookie.type";
import { LOCALIZATION } from "./cookie.type";

// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: false,
  residentCode: "fi",
  currency: "EUR",
  currencySign: "euro-sign",
  privacyPolice: null,
  _ResidentCode: null /* Temp */,
  _Currency: null /* Temp */,
  localization: false /* Temp */,
  isTermsAccepted: false,
  isBurgerMenu: false,
  isGlobalView: false,
  user: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    is_mail_list_subscription: false,
    shipping_address: {
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country_code: ""
    }
  }
};

// getters
const getters = {
  _Currency(state) {
    return state._Currency;
  },
  isBurgerMenu(state) {
    return state.isBurgerMenu;
  },
  _ResidentCode(state) {
    return state._ResidentCode;
  },
  currency(state) {
    return state.currency;
  },
  residentCode(state) {
    return state.residentCode;
  },
  user(state) {
    return state.user;
  },
  privacyPolice(state) {
    return state.privacyPolice;
  },
  isTermsAccepted(state) {
    return state.isTermsAccepted;
  },
  isGlobalView(state) {
    return state.isGlobalView;
  },
  localization(state) {
    return state.localization;
  },
  currencySign(state) {
    if (state.currency == "USD") {
      return "dollar-sign";
    }
    if (state.currency == "GBP") {
      return "pound-sign";
    }
    if (state.currency == "EUR") {
      return "euro-sign";
    }
    return "euro-sign";
  }
};

// actions
const actions = {
  async currency({ commit }, { currency, cookie }) {
    cookie.set(CURRENCY, currency);
    commit("setCurrency", currency);
  },

  async localization({ commit, dispatch, getters }, { cookie }) {
    const isLocalized = getters.localization;
    if (isLocalized) {
      return;
    }
    const { data } = await orderpayment.localization();
    commit("setLocalization", true);
    cookie.set(LOCALIZATION, true);
    const currency = data.currency;
    const residentCode = data.resident_code;
    const lang = data.language;
    cookie.set(CURRENCY, currency);
    commit("setCurrency", currency);
    commit("setResidentCode", residentCode);
    cookie.set(RESIDENT_CODE, residentCode);
  },
  async residentCode({ commit, dispatch }, { residentCode, cookie }) {
    cookie.set(RESIDENT_CODE, residentCode);
    commit("setResidentCode", residentCode);
  },
  async privacyPolice({ commit, dispatch }, { cookie }) {
    cookie.set(PRIVACY_POLICE, true);
    commit("setPrivacyPolice", true);
  },
  async setUserFromPayPal({ commit }, { user }) {
    commit("setUserFromPayPal", user);
  },
  async update({ commit }, { key, value }) {
    switch (key) {
      case "first_name":
        commit("setFirstName", value);
        break;
      case "last_name":
        commit("setLastName", value);
        break;
      case "email":
        commit("setEmail", value);
        break;
      case "country_code":
        commit("setCountryCode", value);
        break;
      case "postal_code":
        commit("setPostalCode", value);
        break;
      case "city":
        commit("setCity", value);
        break;
      case "line1":
        commit("setLine1", value);
        break;
      case "line2":
        commit("setLine2", value);
        break;
      case "phone":
        commit("setPhone", value);
        break;
      default:
        console.log("unknown key");
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setPrivacyPolice(state, isAccept) {
    state.privacyPolice = isAccept;
  },
  setSSRPrivacyPolice(state, cookies) {
    const isAccept = cookies.get(PRIVACY_POLICE);
    state.privacyPolice = isAccept == null ? false : true;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setResidentCode(state, residentCode) {
    state.residentCode = residentCode;
  },
  setSSRResidentCode(state, cookies) {
    const residentCode = cookies.get(RESIDENT_CODE);
    state.residentCode = residentCode == null ? "fi" : residentCode;
  },
  setSSRCurrency(state, cookies) {
    const currency = cookies.get(CURRENCY);
    state.currency = currency == null ? "EUR" : currency;
  },
  setSSRLanguage(state, cookies) {
    const language = cookies.Language;
    state.language = language == null ? "fi" : language;
  },
  setSSRLocalization(state, cookies) {
    const isFetched = cookies.get(LOCALIZATION);
    state.localization = isFetched == null ? false : true;
  },
  setLocalization(state, isfetched) {
    state.localization = isfetched;
  },
  setCurrency(state, currency) {
    state.currency = currency;
  },
  setTempResidentCode(state, residentCode) {
    state._ResidentCode = residentCode;
  },
  setTempCurrency(state, currency) {
    state._Currency = currency;
  },
  setUserFromPayPal(state, user) {
    state.user.first_name = user.first_name;
    state.user.last_name = user.last_name;
    state.user.phone = user.phone;
    state.user.email = user.email;
    state.user.shipping_address.line1 = user.shipping_address.line1;
    state.user.shipping_address.line2 = user.shipping_address.line2;
    state.user.shipping_address.city = user.shipping_address.city;
    state.user.shipping_address.state = user.shipping_address.state;
    state.user.shipping_address.country_code = user.shipping_address.country_code.toLowerCase();
    state.user.shipping_address.postal_code = user.shipping_address.postal_code;
  },
  setFirstName(state, firstName) {
    state.user.first_name = firstName;
  },
  setLastName(state, lastName) {
    state.user.last_name = lastName;
  },
  setEmail(state, email) {
    state.user.email = email;
  },
  setPhone(state, phone) {
    state.user.phone = phone;
  },
  setCountryCode(state, countryCode) {
    state.user.shipping_address.country_code = countryCode;
  },
  setPostalCode(state, postalCode) {
    state.user.shipping_address.postal_code = postalCode;
  },
  setCity(state, city) {
    state.user.shipping_address.city = city;
  },
  setIsMailListSubscription(state, isMailListSubscription) {
    state.user.is_mail_list_subscription = isMailListSubscription;
  },
  setIsTermsAccepted(state, isTermsAccepted) {
    state.isTermsAccepted = isTermsAccepted;
  },
  setLine1(state, line1) {
    state.user.shipping_address.line1 = line1;
  },
  setLine2(state, line2) {
    state.user.shipping_address.line2 = line2;
  },
  setIsBurgerMenu(state, show) {
    state.isBurgerMenu = show;
  },
  setIsGlobalView(state, show) {
    state.isGlobalView = show;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
