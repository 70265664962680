import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");

// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: false
};

// getters
const getters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  cart: (state, getters, rootState, rootGetters) => {
    return rootGetters["cart/productIds"];
  },
  user: (state, getters, rootState) => {
    return rootState.user.user;
  },
  shipmentCostId: (state, getters, rootState) => {
    return rootState.shipment.shipmentCostId;
  },
  taxRateId: (state, getters, rootState, rootGetters) => {
    return rootGetters["country/taxRateId"];
  },
  payment: (state, getters, rootState) => {
    return rootState.paypal.payment;
  }
};

// actions
const actions = {
  async createPayPalPayment(
    { commit, getters, dispatch },
    { language, router }
  ) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const user = getters.user;
      const products = getters.cart;
      const shipmentCostId = getters.shipmentCostId;
      const taxRateId = getters.taxRateId;
      const currency = getters.currency;

      const resp0 = await orderpayment.createOrder(
        user,
        products,
        shipmentCostId,
        taxRateId,
        currency,
        language
      );

      const payment = getters.payment;
      const hash = resp0.data.hash;

      const resp1 = await orderpayment.createPayPalPayment(
        payment.id,
        hash,
        user,
        language
      );
      commit("setLoading", false);
      router.push({ name: "order_success", params: { order_hash: hash } });
    } catch (error) {
      commit("setError", error);
    }
  },
  async createBankTransferPayment(
    { commit, getters, dispatch },
    { language, router }
  ) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const user = getters.user;
      const products = getters.cart;
      const shipmentCostId = getters.shipmentCostId;
      const taxRateId = getters.taxRateId;
      const currency = getters.currency;

      const resp0 = await orderpayment.createOrder(
        user,
        products,
        shipmentCostId,
        taxRateId,
        currency,
        language
      );
      const hash = resp0.data.hash;
      const resp1 = await orderpayment.createBankTransferPayment(
        hash,
        user,
        language
      );
      commit("setLoading", false);
      router.push({ name: "order_success", params: { order_hash: hash } });
    } catch (error) {
      commit("setError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
