<template>
  <button
    v-on:click="changeLanguage()"
    class="f6 fw6 link dim ph3 pv2 dib black bg-white lh-copy roboto sans-serif ttu tracked tc"
  >
    {{ currentLanguage }}
  </button>
</template>
<script>
import { Trans } from "../i18n/trans.js";
export default {
  name: "language-changer",
  computed: {
    currentLanguage() {
      return Trans.currentLanguage === "en" ? "fi" : "en";
    }
  },
  methods: {
    changeLanguage() {
      const lang = this.currentLanguage === "en" ? "en" : "fi";
      const to = this.$router.resolve({ params: { lang } });
      this.$store.commit("country/setDelete");
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location);
      });
    }
  }
};
</script>
