// READ MORE: https://kazupon.github.io/vue-i18n/guide/formatting.html

export default {
  shopping_list: {
    attribute: {
      type: "Tyyppi:",
      used_for: "Käyttökohde:",
      piece: "Määrä:",
      unit: "Yksikkö:",
      name: "Nimi:",
      model: "Malli:",
      color: "Väri:",
      size: "Koko:",
      thickness: "Paksuus:",
      angle: "Kulma:",
      diameter: "Halkaisija:",
      voltage: "Jännite:",
      package_size: "Pakkauskoko:",
      manufacturer: "Valmistaja:",
      place_of_purchase: "Ostopaikka:",
      item_number: "Tuotenumero:",
      hyperlink: "Linkki"
    },
    open: "Avaa"
  },
  reset_password: {
    password: "Salasana:",
    re_enter_password: "Toista salasana:",
    activate: "Aktivoi",
    reset_title: "Unohditko salasanasi?",
    reset_article:
      "Ilmoita sähköpostiosoitteesi, jota olet käyttänyt kirjautuessasi PIIIAshop-verkkokauppaan. Lähetämme sinulle sähköpostilla linkin, jonka avulla voit vaihtaa salasanasi.",
    email: "Sähköpostiosoite",
    submit: "Nollaa salasana",
    reset_success_title: "Nollaa salasanasi",
    reset_success_article:
      "Tarkista sähköpostisi - sait meiltä viestin, joka sisältää linkin, jonka avulla voit vaihtaa salasanasi. Jos viesti ei saavu muutaman minuutin kuluessa, tarkista roskapostikansiosi.",
    return_to_sign_in: "Palaa sisäänkirjautumiseen",
    change_password_title: "Vaihda salasana",
    change_password_article:
      "Voit vaihtaa salasanan syöttämällä uuden salasanan alla oleviin kenttiin.",
    button: "Tallenna muutos"
  },
  cookies: {
    text:
      'Käytämme tällä sivustolla evästeitä. Jatkamalla palvelun käyttöä hyväksyt evästeiden käytön. Tutustu <a href="/fi/privacy/">tietosuojaselosteeseen</a>.',
    accept: "Hyväksyn"
  },

  globe_view: {
    country_title: "Selaat maassa {country}",
    country_text: "Muuta sijaintia:",
    currency_text: "Valuutta:",
    confirm: "Vahvista"
  },

  order: {
    error_title: "Virhe",
    confirm_title: "Vahvista tietosi",
    confirm_article:
      "Vahvista ja hyväksy vielä tilauksen toimittamiseen liittyvät tietosi. Vasta tietojesi vahvistamisen jälkeen tilaus siirtyy vahvistetuksi ja saat tilausvahvistuksen sähköpostiisi. Ethän poistu sivulta tai päivitä sitä ilman tietojesi vahvistamista, sillä silloin tilauksesi keskeytyy.",
    activation_title: "Aktivoi käyttäjätilisi",
    activation_article:
      "Aktivoi vielä PIIIAshop-käyttäjätilisi asettamalla sille salasana. Voit luoda sen alla.",
    success_title: "Kiitos!",
    success_article:
      "Kiitokset tilauksestasi <b>{order_hash}</b>! Olemme nyt vastaanottaneet tilauksesi. Saat pian tilausvahvistuksen sähköpostiisi (jos valitsit maksutavaksi pankkisiirron, suorita ensin maksu saamiesi maksutietojen mukaan). <br/><br/> <b>Postitse toimitettavien</b> tilausten, kuten tarrojen, avaimenperien, yksilöllisten kuvitustuotteiden ja yksilöllisten kuvakirjojen, osalta lähetämme sinulle toimitusvahvistuksen, kun toimitus on lähtenyt matkaan. <br/><br/> <b>Digitaalisessa muodossa toimitettavien</b> tilausten, kuten konsultaatioiden ja lahjakorttien, osalta tilaus toimitetaan erillisellä sähköpostiviestillä. <br/><br/> <b>Digitaaliset palvelut</b>, kuten e-ostoslistat, toimivat sähköisesti ja niihin on pääsy PIIIAshop-verkkokauppaan sisäänkirjautumalla. Ensimmäisen oston yhteydessä sinun tulee aktivoida PIIIAshop-käyttäjätilisi. Seuraa sähköpostiisi saamiasi ohjeita. <br/><br/> Kiitos kun teit ostoksia meillä! Toivomme, että olet tyytyväinen ostokseesi! ♥",
    success_continue: "Palaa etusivulle",
    purchased_products_title: "Ostokseni:",
    purchased_products_article:
      "Alla näet PIIIAshop:ista tekemäsi ostokset ja pääset käyttämään ostamiasi digitaalisia palveluita, kuten e-ostoslistoja.",
    first_name: "Etunimi:",
    last_name: "Sukunimi:",
    line1: "Katuosoite:",
    line2: "",
    postal_code: "Postinumero:",
    city: "Kaupunki:",
    country: "Maa:",
    country_select: "Valitse maa",
    phone_number: "Puhelinnumero:",
    email: "Sähköpostiosoite:",
    confirm: "Vahvista"
  },
  navigation: {
    /* navigation => Header.vue */
    faq: "UKK",
    hireUs: "tilaustyöt",
    pakuprojekti: "pakuprojekti",
    myOrders: "ostot",
    products: "Tuotteet"
  },
  footer: {
    /* footer => Footer.vue */
    terms: "ehdot",
    privacy: "tietosuoja",
    share1:
      "Muistathan jakaa oman projektisi meille somessa käyttäen tunnistetta",
    share2: "#pakuprojekti",
    share3: "♥"
  },
  signInButton: {
    /* sign-in-button => SignInButton.vue */
    signIn: "kirjaudu",
    signOut: "kirjaudu ulos"
  },

  product: {
    country: "Maa:",
    /* => ProductView.vue */
    not_purchasable: "Ei ostettavissa {country}",
    buy_now: "Osta nyt",
    terms1: "Ostaessani hyväksyn",
    terms2: "ehdot",
    terms3: "",
    newsletter: "Haluan vastaanottaa uutiskirjeitä tai muuta markkinointia",
    sign_in1: "Oletko jo ostanut kyseisen e-ostoslistan?",
    sign_in2: "->",
    sign_in3: "Kirjaudu sisään",
    type_digital: "-digitaalinen tuote",
    type_sticker: "-tuote",
    type_shopping_list: "-e-ostoslista",
    find_out_more: "Tutustu lisää ->"
  },

  privacy: {
    /* => Privacy.vue */
    title: "Tietosuoja",
    subtitle: "Rekisteriseloste ja tietosuoja & Evästeet",
    created_at: "Päivitetty 25.3.2021",
    paragraph:
      "PIIIAshop-verkkokaupan henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Luethan rekisteriselosteen ja tietosuojakäytännön huolellisesti etukäteen ennen palvelun käytön aloittamista. Käyttämällä palveluamme hyväksyt tietojesi käsittelyn tämän tietosuojaselosteen mukaisesti. Tässä rekisteriselosteessa ja tietosuojakäytännössä viitataan usein 'asiakkaaseen' tai 'käyttäjään'. Asiakkaalla tai käyttäjällä tarkoitetaan sivustoilla käyviä henkilöitä, yrityksen asiakkaita, maksuttomien tuotteiden tai palveluiden tilaajia sekä erilaisiin kilpailuihin ja arvontoihin osallistuvia henkilöitä, yrityksiä ja yhteisöjä.",
    article: [
      {
        paragraphs: [
          {
            title: "Rekisterinpitäjä:",
            nested_paragraphs: [
              "PIIIA.com (2950462-4), Mannarintie 62, 45370 Valkeala, Suomi"
            ]
          },

          {
            title: "Rekisteristä vastaava yhteyshenkilö:",
            nested_paragraphs: ["Tietosuojavastaava, info@piiia.com"]
          },

          {
            title: "Rekisterin nimi:",
            nested_paragraphs: ["PIIIAshop asiakasrekisteri"]
          },

          {
            title: "Rekisteriin liittyvät sivustot:",
            nested_paragraphs: ["https://shop.piiia.com"]
          },

          {
            title: "Oikeusperuste ja henkilötietojen käsittelyn tarkoitus:",
            nested_paragraphs: [
              "EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on:",
              "<ul><li>asiakkaan kanssa tehty sopimus ja sen toteuttaminen (esim. tilaus verkkokaupasta);</li> <li>käyttäjän suostumus;</li> <li>asianmukainen tarve eli oikeutetun edun peruste; ja/tai</li> <li>lakisääteinen velvoite.</li></ul>",
              "Henkilötietoja voidaan käsitellä seuraavia tarkoituksia varten:",
              "<ul><li>asiakassuhteen ylläpito, hoitaminen ja kehittäminen;</li> <li>palvelujen toteuttaminen;</li> <li>asiakaspalvelun, toiminnan ja sivustojen kehittäminen;</li> <li>mielipide- ja markkinatutkimusten toteuttaminen;</li> <li>sisällön kohdentaminen ja jälleenmarkkinointi, käyttäen mahdollisesti Facebookin, Googlen ja sähköpostimarkkinointiin tarkoitettuja työkaluja;</li> <li>tuotteiden ja palvelujen markkinointi;</li> <li>tietojen tilastointi ja analysointi; ja/tai</li> <li>kumppanuus- ja affiliatemarkkinoinnin toteuttaminen.</li></ul>",
              "Lakisääteisten velvotteiden täyttämiseksi, meidän on joissakin tilanteissa käsiteltävä hallussamme olevia henkilötietoja joko yhteistyössä viranomaisten kanssa tai kirjanpidosta säädettyjen lakien velvoittamana.",
              "Voimme käsitellä tietoja myös estääksemme, havaitaksemme ja tutkiaksemme mahdollisesti kiellettyä tai laitonta toimintaa (esimerkiksi petokset), palveluidemme väärinkäytön ehkäisemiseksi, tieto-, järjestelmä- ja verkkoturvallisuuden vuoksi sekä varmistaaksemme, että Käyttöehtojamme noudatetaan.",
              "Sähköpostilistalle liittynyt on antanut suostumuksen uutiskirjeiden tai muun markkinointimateriaalin hänelle lähettämiseen.",
              "Sivustojen käyttäjä hyväksyy, että sivustoilla, sosiaalisen median kanavissamme tai muussa viestinnässämme, kuten uutiskirjeissämme, voi olla myös kaupallista sisältöä tai markkinointia."
            ]
          },

          {
            title: "Rekisterin tietosisältö:",
            nested_paragraphs: [
              "Voimme kerätä käyttäjistä seuraavia tietoja: Käyttäjien antamat tiedot, Palveluamme käyttäessä kertyvät tiedot ja Muista lähteistä saadut tiedot.",
              "Käyttäjien antamat tiedot:",
              "<ul><li>Etunimi;</li> <li>Sukunimi;</li> <li>Sähköpostiosoite;</li> <li>Postiosoite, Postinumero, Postitoimipaikka ja Maa;</li> <li>Puhelinnumero;</li> <li>Syntymäaika;</li> <li>Sukupuoli;</li> <li>Sosiaalisen median kanavien profiilit;</li> <li>Muu tieto, jota käyttäjä antaa itsestään;</li> <li>Tilaustiedot, kuten Maksutapahtumat, Tiedot tilatuista tuotteista ja palveluista, Muutokset tilauksissa ja Maksuhistoria;</li> <li>Yritysasiakkailta henkilö- ja/tai yritystiedot (Y-tunnus tai Alv-numero, Sähköpostiosoite, Postiosoite, Postinumero, Postitoimipaikka ja Maa);</li> <li>Sähköpostilistalle liittymisajankohta ja liittymistapa;</li> <li>Luvat ja suostumukset;</li> <li>Rekisteröitymistiedot: Sähköposti ja Salasana; ja/tai</li> <li>Tiedot, joita luovutetaan viestinnässä kanssamme: Yhteydenotot, Sähköpostiviestit, Lomakkeet, Asiakastapaamiset, Palautteet, Kommentit sekä Arvonta- ja kilpailuvastaukset.</li></ul>",
              "Pyrimme tietoja kerätessämme kertomaan, mitkä tiedot ovat pakollisia sopimuksen tekemistä varten ja mitkä voi antaa vapaaehtoisesti. Mikäli asiakas ei anna palvelussamme tarvittavia tietoja, tämä voi tarkoittaa sitä, ettemme pysty tarjoamaan palvelua.",
              "Palveluamme käyttäessä kertyvät tiedot:",
              "<ul><li>Sivustojen käyttötiedot, kuten Linkkien klikkaaminen, Sivut, joissa vierailtu ja Toimintojen ajankohta;</li> <li>Laitetiedot ja tekniset tiedot, kuten Laite, Maantieteellinen sijainti, IP-osoite, Käytetty selain ja selainversio sekä Käyttöjärjestelmä; ja/tai</li> <li>Muut evästetiedot (voit lukea lisää Evästekäytännöistämme alta).</li></ul>",
              "Käsittelemme myös henkilötietoja, joita mahdollisesti saamme esimerkiksi kolmansilta osapuolilta tai julkisista lähteistä.",
              "Muista lähteistä saadut tiedot:",
              "<ul><li>Tiedot Teknisiltä palveluntarjoajiltamme, jotka avustavat palvelumme, sisältöjemme ja ominaisuuksiemme tarjoamisessa;</li> <li>Tiedot Mainostajilta tai mainoskumppaneilta; ja/tai</li> <li>Tiedot Julkisesti saatavilla olevista lähteistä, kuten sosiaalisen median kanavissa tehdyistä julkaisuista tai niissä kerrotuista tiedoista sekä omien sisältöjemme käytöstä sosiaalisessa mediassa.</li></ul>"
            ]
          },

          {
            title: "Säännönmukaiset tietolähteet:",
            nested_paragraphs: [
              "Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. rekisteröityessä, sopimuksen teon yhteydessä, sivustojen kautta lähetettyinä, sähköpostitse, puhelimitse, sosiaalisen median palveluiden kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan, sekä erilaisista arvonnoista, kilpailuista ja asiakaseduista, joihin asiakas osallistuu."
            ]
          },

          {
            title: "Tietojen säännönmukaiset luovutukset:",
            nested_paragraphs: [
              "Voimme luovuttaa rekisteriin kerättyjä tietoja ulkopuolelle seuraavissa tilanteissa:",
              "<ul><li>Julkiset tiedot;</li> <li>Käyttäjän suostumus;</li> <li>Kolmannen osapuolen palveluntarjoajat;</li> <li>Mainoskumppanit;</li> <li>Lainvalvonta ja Oikeudelliset pyynnöt; ja/tai</li> <li>Yritysjärjestelyt.</li></ul>",
              "Julkiset tiedot: Voimme luovuttaa ja jakaa edelleen sivustoillamme julkaisemaasi julkista sisältöä, kuten kommentteja ja sosiaalisen median kanavissamme meille lähettämääsi sisältöä, julkisina tietoina palveluissamme ja niiden ulkopuolella.",
              "Käyttäjän suostumus: Voimme luovuttaa tietojasi kolmansille osapuolille annettuasi siihen suostumuksesi, esimerkiksi kun haluat käyttää kumppanimme tarjousta.",
              "Kolmannen osapuolen palveluntarjoajat: Luovutamme tietoja käyttämillemme kolmannen osapuolen palveluntarjoajille (kuten esimerkiksi IT-palveluiden-, maksupalveluiden-, painopalveluiden- ja logistiikkapalveluiden palveluntarjoajat). He auttavat meitä tarjoamaan sekä parantamaan palveluitamme ja saattavat tarvita tiettyjä henkilötietoja voidakseen tarjota meille palveluita.",
              "Käyttämällä maksutapana PayPal:ia asiakas hyväksyy tietojensa siirtämisen PayPal:ilta PIIIA.com:ille. Ostosopimuksen ja tilauksen toteuttamiseksi PayPal siirtää henkilötietoja PIIIAshop-verkkokaupalle. Tutustu PayPal:in tietosuojaselosteeseen heidän Internet-sivuillaan osoitteessa: https://www.paypal.com/fi/webapps/mpp/ua/privacy-full. Tilauksen toimittamisen yhteydessä asiakkaan henkilötietoja välitetään kuljetusliikkeelle (Posti tai Matkahuolto).",
              "Käytössämme on myös kolmansien osapuolten tarjoamia työkaluja, kuten Google Analytics. Tällaisissa tapauksissa sovelletaan kolmannen osapuolen ehtoja ja tietosuoja-asetuksia.",
              "Mainoskumppanit: Voimme luovuttaa tietoja mainoskumppaneille, raportoidessamme millaiset käyttäjät heidän mainoksiaan näkevät ja kuinka hyvin mainokset menestyvät, mutta emme jaa tietoja, joista sinut voi tunnistaa henkilökohtaisesti (kuten nimesi tai sähköpostiosoitteesi), ellet anna meille siihen lupaa.",
              "Lainvalvonta ja Oikeudelliset pyynnöt: Tietojasi voidaan luovuttaa myös lainvalvontaan liittyen lakia valvoville viranomaisille tai vastauksina oikeudellisiin pyyntöihin liittyen.",
              "Yritysjärjestelyt: Mikäli palvelumme ovat osapuolena sulautumisessa, liiketoimintakaupassa tai muussa yrityskaupassa, voimme luovuttaa henkilötietoja kyseisessä yrityskaupassa mukana olevalle kolmannelle osapuolelle. Varmistamme tällöin kuitenkin kaikkien henkilötietojen pysymisen luottamuksellisina. Tässä tapauksessa kerromme siirrosta niin pian kuin on kohtuullisesti katsoen mahdollista käyttäjille, joiden henkilötietoja siirto koskee tai joiden henkilötietojen käsittelyyn soveltuu eri tietosuojaseloste."
            ]
          },

          {
            title: "Tietojen siirto EU:n tai ETA:n ulkopuolelle:",
            nested_paragraphs: [
              "Toteutamme palvelut, käsittelemme ja säilytämme henkilötietoja ensisijaisesti EU- tai ETA-maissa sijaitsevia toimijoita ja palveluja hyödyntäen. Käyttämämme palveluntarjoajat toimivat kuitenkin useilla maantieteellisillä alueilla ja saattavat sijaita EU- tai ETA-alueen ulkopuolella. Mahdollisesta tietojen siirrosta EU- tai ETA-alueen ulkopuolelle huolehditaan asianmukaisin suojakeinoin."
            ]
          },

          {
            title: "Tietojen säilyttäminen:",
            nested_paragraphs: [
              "Säilytämme henkilötietoja niin kauan, kun se on tarpeen tässä tietosuojaselosteessa määriteltyjen henkilötietojen käsittelytarkoitusten toteuttamiseksi, ellei lainsäädäntö edellytä pidempää säilytysaikaa. Kirjanpitolain tai muun soveltuvan lain velvoittamana tietoja voidaan joutua säilyttämään pidempään ja muun kirjanpitoaineiston tapaan. Säilytysajan päätyttyä poistamme tiedot tai muutamme ne sellaiseen anonyymiin muotoon, josta yksittäinen henkilö ei ole enää tunnistettavissa.",
              "Asiakkaan tuotteiden ja palveluiden toteuttamista varten toimittama aineisto hävitetään viimeistään 45 päivän kuluttua tilauksen toimittamisesta. Jossakin tapauksissa, kuten esimerkiksi kuvitettujen custom-tuotteiden osalta, säilytämme digitaalisen kopion tilatuista tuotteista 24 kuukauden ajan. Tämän tarkoituksena on tarjota mahdollisuus tilata lisää kopioita samasta tuotteesta.",
              "Esim. maksamattomien maksujen vuoksi keskeneräiseksi jääneitä tilauksia säilytetään 6 kuukauden ajan, jonka jälkeen pidätämme oikeuden poistaa sekä toimitetut aineistot että keskeneräiseksi jääneet digitaaliset tuotteet ja/tai toimittamista vaille valmiit fyysiset tuotteet."
            ]
          },

          {
            title: "Rekisterin suojauksen periaatteet:",
            nested_paragraphs: [
              "Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Rekisteritietoja säilytetään Internet-palvelimilla. Laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu."
            ]
          },

          {
            title: "Tarkastusoikeus & Oikeus vaatia tiedon korjaamista:",
            nested_paragraphs: [
              "Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti (sähköpostitse) rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa)."
            ]
          },

          {
            title: "Muut henkilötietojen käsittelyyn liittyvät oikeudet:",
            nested_paragraphs: [
              "Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä (”oikeus tulla unohdetuksi”). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa.",
              "Pyynnöt tulee lähettää kirjallisesti (sähköpostitse) rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).",
              "Huomioithan, että sinulla on oikeus pyytää meitä poistamaan sinua koskevat henkilötiedot (”oikeus tulla unohdetuksi”) vain, jos niiden säilytys ei ole välttämätöntä esimerkiksi kirjanpitolain tai muiden lakisääteisten velvotteiden mukaan.",
              "Sinulla on oikeus milloin tahansa kieltää suoramarkkinointi lähettämällä sähköpostia osoitteeseen info@piiia.com."
            ]
          },

          {
            title: "Linkit kolmansien osapuolien sivustoihin:",
            nested_paragraphs: [
              "PIIIAshop-verkkokauppa, sivustomme, sisältömme, tuotteet ja palvelumme (myös maksuttomat), sosiaalisen median viestintämme sekä uutiskirjeemme voivat sisältää linkkejä kolmansien osapuolten verkkosivustoille ja/tai kolmansien osapuolien mainontaa. Linkitetyt verkkosivustot ja toimijat eivät ole yrityksen hallinnassa, emmekä vastaa niiden sisällöistä tai sivustojen sisältämistä linkeistä. Yritys ei vastaa myöskään kolmannen osapuolen tarjoamista tuotteista, palveluista eikä heidän tuottamastaan aineistosta, tiedoista tai muusta sisällöstä.",
              "Jos käyttäjä siirtyy linkitetylle sivustolle, hän poistuu palveluistamme ja hänen tulee tutustua itse kyseisen sivuston tietosuojaperiaatteisiin, evästeisiin ja muihin käytäntöihin. Sivustoillemme upotettujen sisältöjen (esimerkiksi videon) avaaminen on verrattavissa siihen, että käyttäjä kävisi itse kolmannen osapuolen sivustolla."
            ]
          },

          {
            title: "Alaikäisten turvallisuus:",
            nested_paragraphs: [
              "Palvelumme eivät ole tarkoitettu alaikäisille. Alaikäisellä tarkoitetaan alle 18-vuotiasta henkilöä.",
              "Emme tietoisesti kerää alaikäisen henkilötietoja. Alaikäisellä on kuitenkin lupa käyttää palveluitamme, mikäli huoltaja hyväksyy palvelun käytön."
            ]
          },

          {
            title: "Tietosuojaselosteen muuttaminen:",
            nested_paragraphs: [
              "Rekisterinpitäjä pyrkii jatkuvasti kehittämään palveluitaan ja pidättää siksi oikeuden muuttaa tätä tietosuojaselostetta ajoittain ja milloin tahansa. Ajantasainen versio on aina julkaistu sivustoillamme, joten ole hyvä ja tarkista se säännöllisesti. Jatkamalla Palveluidemme käyttöä, hyväksyt automaattissesti uuden tietosuojan ja rekisteriselosteen."
            ]
          }
        ]
      },
      {
        paragraphs: [
          {
            title: "Evästeet:",
            nested_paragraphs: [
              "Käytämme evästeitä ja muita vastaavia menetelmiä ja teknologioita tuotteidemme ja palveluidemme toimittamiseen, käyttökokemuksen parantamiseen, tietojen keräämiseen palveluita kehittääksemme sekä mainoksia kohdentaaksemme. Evästeet voivat olla esimerkiksi kirjautumis- ja toimintaevästeitä, analyysievästeitä ja kohdistus- tai mainosevästeitä.",
              "Osa evästeistä on kolmansien osapuolten, kuten teknisten-, markkinointi- ja maksupalveluiden palveluntarjojiemme omia evästeitä. Löydät palveluntarjoajien tietosuojaselosteet heidän omilta Internet-sivuiltaan. Käytämme muun muassa Google Analytics (https://support.google.com/analytics/answer/6004245), Google Ads (https://policies.google.com/privacy?hl=fi) ja Facebook (https://fi-fi.facebook.com/about/privacy/).",
              "Muut verkkosivustot, joille sivustoiltamme on mahdollisia linkkejä, keräävät ja hyödyntävät evästeitä heidän tarpeidensa mukaan. Sivuston käyttäjän tulee itse hyväksyä jokaisen käyttämänsä sivuston evästeet. Mikäli käyttäjä ei hyväksy, että hänestä kerätään tietoja, kyseisen sivuston käyttö tulee lopettaa.",
              "Voit rajoittaa tai estää evästeiden käyttöä. Huomioithan, että näin tehdessäsi et voi ehkä käyttää tiettyjä palveluidemme osia ja muut palveluidemme osat eivät ehkä toimi oikein."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Mainonta Palveluissamme:",
            nested_paragraphs: [
              "Mainos- / Affiliate-linkit:",
              "Sivustoidemme sisällöissä ja digitaalisissa tuotteissamme voi esiintyä ns. mainos- / affiliate-linkkejä, joiden kautta Yritys voi saada komissioita tai muuta etua.",
              "Mainos- / Affiliate-linkit on merkitty selkeästi linkin edessä *-merkillä ja/tai sitä koskevan sisällön yhteydessä olevalla maininnalla '*Sisältää mainos- / affiliate-linkin' tai 'Kaupallinen yhteistyö: X'.",
              "Sivustojen sisällöissä, sosiaalisen median kanavissamme tai uutiskirjeissämme voidaan suositella tuotteita, kohteita ja palveluita, sekä tarjota etuja tai alennuksia yhteistyössä valittujen kumppaneiden kanssa.",
              "Kaupallinen yhteistyö & Muut saadut etuudet:",
              "Yritys tekee kaupallisia yhteistöitä ja solmii kumppanuuksia, jotka sopivat sen arvomaailmaan, kanavien teemaan ja yleisöihin.",
              "Kaupallinen yhteistyö tarkoittaa sisältöä, jonka Yritys tuottaa mainostajan kanssa tehdyn sopimuksen pohjalta. Yritys saa rahallisen korvauksen tai palkkaa vastaavaa taloudellista hyötyä. Kaupalliseksi yhteistyöksi tulkitaan myös tapaukset, joissa sisällöntuotannosta on sovittu Yrityksen ja yrityksen tai muun tahon välillä ilman palkkiota tai siihen rinnastettavaa taloudellista hyötyä. Tuotettava sisältö voi olla esimerkiksi blogipostaus, artikkeli, Instagram-postaus, YouTube-video tai muu vastaava, jossa voidaan tarjota tietoa, jakaa kokemuksia, arvioida tuote, kohde tai palvelu tai muuta vastaavaa. Osana kaupallista yhteistyötä voidaan tarjota myös etuja tai alennuksia.",
              "Muut saadut etuudet voivat olla esimerkiksi tuotenäytteitä, sponsoroituja tavaroita ja palveluita, kutsuvierastilaisuuksia, pressimatkoja sekä komissioita mainos-/affiliatelinkeistä. Erona kaupalliseen yhteistyöhön on se, ettei Yrityksen ja yrityksen tai muun tahon välillä ole sovittu sisällöntuotannosta vaan Yritys on saanut edut käyttöönsä ilman velvoitteita. Jos Yritys kuitenkin tuottaa sisältöä näistä eduista omaehtoisesti kanaviinsa, saadut edut täytyy tuoda esiin.",
              "Kerromme kaupallisesta yhteistyöstä ja muista saamistamme eduista avoimesti kaikissa kanavissa, joihin tuotamme yhteistyöhön tai etuun liittyvää sisältöä, sitä koskevan sisällön yhteydessä. Kaupalliset yhteistyöt merkitsemme selkeästi sisällön alussa käyttämällä sanamuotoa 'Kaupallinen yhteistyö' sekä tuomalla esiin yhteistyöbrändin.",
              "Muista saamistamme eduista kerromme selkeästi käyttämällä esimerkiksi merkintöjä '*Tuote/Palvelu saatu/sponsoroitu' tai '*Sisältää mainos-/affiliatelinkin'.",
              "Sivustomme, sisältö, tuotteet ja palvelumme (myös maksuttomat), sosiaalisen median viestintämme sekä uutiskirjeemme voivat suositella tai esitellä tuotteita, kohteita ja palveluita myös ilman että on kyse Kaupallisesta yhteistyöstä tai Muusta saadusta etuudesta.",
              "Kolmansien osapuolien mainonta:",
              "Palveluissa voi lisäksi näkyä kolmansien osapuolien mainontaa. Yritys ei vastaa kolmannen osapuolen tarjoamista tuotteista, palveluista eikä kolmannen osapuolen tuottamasta aineistosta, tiedoista tai muusta sisällöstä."
            ]
          }
        ]
      }
    ]
  },

  terms: {
    /* => Terms.vue */
    title: "Ehdot",
    subtitle: "Verkkokaupan Käyttö- ja Sopimusehdot",
    created_at: "Ehdot voimassa 1.11.2019 alkaen. Päivitetty 23.11.2021.",
    paragraphs: [
      "Käyttämällä PIIIA.com:in, 2950462-4 (jäljempänä 'Yritys'), sivustoja, sivustojen sisältöjä ja aineistoja, lataamalla sivustoilta materiaalia, tilaamalla tai ostamalla tuotteita tai palveluita, tai mitä tahansa PIIIA.com:in tuotetta tai palvelua (jäljempänä 'Palvelut') hyväksyt nämä käyttö- ja sopimusehdot sekä tietosuojaehdot, joihin löydät linkit sivustojen alalaidasta.",
      "Pidätämme oikeuden muokata ehtoja ja muuttaa niiden sisältöä päivittämällä käyttö- ja sopimusehtoja ilman erillistä ennakkovaroitusta. Mikäli et hyväksy käyttö- ja sopimusehtoihin kulloinkin tehtäviä päivityksiä, sinun tulee pidättyä Palveluiden käytöstä vastaisuudessa. 'Sinulla', 'asiakkaalla' tai 'käyttäjällä' viitataan Palveluidemme käyttäjään tai asiakkaaseemme.",
      "Sitoudut käyttämään Palveluita ja niiden sisältöjä vain ei-­­kaupallisiin tarkoituksiin näiden käyttöehtojen ja soveltuvien lakien ja määräysten mukaisesti tavalla, joka ei loukkaa muiden oikeuksia tai rajoita tai estä ketään muuta käyttämästä palveluja.",
      "Näitä käyttö- ja sopimusehtoja sovelletaan Yrityksen seuraavilla domainella ja alidomaineilla toimiviin sivustoihin ja Palveluihin sekä myytävien tuotteiden ja palveluiden ostamiseen ja toimittamiseen, ellei Yritys ja asiakas ole kirjallisesti toisin sopineet:",
      "https://shop.piiia.com"
    ],
    article: [
      {
        paragraphs: [
          {
            title: "Palveluiden sisältö & käyttö:",
            nested_paragraphs: [
              "Palvelut on tarkoitettu täysi-ikäisille henkilöille. Täysi-ikäisellä tarkoitetaan 18 vuotta täyttänyttä henkilöä. Sitä nuoremmat henkilöt saavat käyttää Palveluita, mikäli heidän vanhempi tai huoltaja hyväksyy palvelun käytön.",
              "Yritys ei takaa Palveluissa olevien tietojen oikeellisuutta tai luotettavuutta. Mitään Yrityksen Palveluissa esiintyvää tai niissä annettavia tietoja, ohjeita tai neuvoja ei tule käsittää juridisena, lääketieteellisenä, teknisenä tai muuna vastaavana neuvontana, tietoina tai ohjeina. Niiden perusteella ei voi esittää Yritystä kohtaan vaatimuksia. Kaikki Palveluissa esiintyvät tiedot on tarkoitettu ainoastaan informativiisiin tarkoituksiin.",
              "Yritys ei vastaa Palveluissa annettujen tietojen soveltuvuudesta käyttäjän tarpeisiin tai tietojen perusteella tehtyjen toimien tuloksesta tai tuloksellisuudesta. Yritys ei ole vastuussa mistään välittömistä tai välillisistä vahingoista, jotka saattavat aiheutua Palveluihin ja niiden käyttöön liittyen.",
              "Palvelut saattavat sisältää kolmansien osapuolten mainoksia ja/tai linkkejä kolmansien osapuolten sivustoille. Yritys ei ole vastuussa Palveluihin lisättyjen linkkien kautta saatavana olevien kolmansien osapuolien sivustojen sisällöistä tai niiden käytöstä. Yritys ei myöskään vastaa kolmannen osapuolen tarjouksista tai niiden sisällöstä. Linkitetyt sivustot eivät ole millään lailla Yrityksen hallinnassa eikä Yritys valvo tai tarkista linkitettyjen sivustojen sisältöä. Linkkien toimivuutta tai ajantasaisuutta ei voida taata, eikä Yritys vastaa niiden oikeellisuudesta, luotettavuudesta tai tietoturvasta.",
              "Ulkoisen linkin Palveluihimme tulee viitata suoraan Palveluiden pääsivuille. Linkin ulkoasu, sijoitus ja muut ominaisuudet eivät saa vaikuttaa haitallisesti Yrityksen maineeseen. Lainattaessa Palveluiden sisältöä lain niin salliessa, on käyttäjä velvollinen mainitsemaan lähteen hyvän tavan edellyttämällä tavalla.",
              "Yrityksellä on oikeus vaihtaa, muuttaa, poistaa tai lisätä Palveluita ja niiden sisältöä ilman ennakkoilmoitusta. Yrityksellä on milloin tahansa oikeus lopettaa Palveluiden tai niiden osien tuottaminen ja tarjoaminen käyttäjälle ilman ennakkovaroitusta tai erillistä ilmoitusta.",
              "Käyttäjä vastaa itse tietojensa oikeellisuudesta ja ajantasaisuudesta. Yritys ei vastaa vahingoista, jotka saattavat aiheutua, mikäli käyttäjä ilmoittaa vääriä tietoja Yritykselle.",
              "Käyttäjä ei saa väärinkäyttää Palveluita. Käyttäjä ei saa syyllistyä tai yllyttää rikolliseen tekoon, levittää tai jakaa virusta tai mitään muuta materiaalia, joka on tahallista, teknisesti vahingollista, rikkomistarkoituksessa tai millään lailla hyökkäävänä tai hävyttömänä. Käyttäjä ei saa hakkeroida palveluita, korruptoida dataa, aiheuttaa harmia muille käyttäjille, lähettää roskapostia tai yrittää vaikuttaa mihinkään teknisiin fasiliteetteihin eikä sivustoiden kautta saavutettujen asioiden toimivuuteen ja suorituskykyyn.",
              "Yrityksellä on oikeus rajoittaa pääsy Palveluihin sekä oikeus lopettaa Palveluiden tai sen osien tuottaminen ja tarjoaminen käyttäjälle, mikäli käyttäjä rikkoo näitä ehtoja tai muita Palveluissa erikseen annettuja ehtoja ja sääntöjä.",
              "Yritys pyrkii pitämään Palvelut käyttäjän käytettävissä jatkuvasti ja häiriöittä. Yritys ei kuitenkaan takaa, että Palvelut ovat käyttäjän käytettävissä keskeytyksettömästi, oikea-aikaisesti tai virheettömästi. Yritys ei vastaa Palveluiden teknisistä ongelmista tai Palveluihin pääsystä."
            ]
          },

          {
            title: "Rekisteröityminen Palveluihin:",
            nested_paragraphs: [
              "Osa Palveluista saattaa edellyttää rekisteröitymistä.",

              "Käyttäjän on rekisteröitymisen yhteydessä annettava kaikki pakolliseksi merkityt tiedot. Rekisteröityminen edellyttää toimivaa sähköpostiosoitetta, sillä rekisteröinnin loppuun saattaminen edellyttää käyttäjän sähköpostiin lähetettävän vahvistusviestin käsittelyä. Yrityksellä on oikeus syytä ilmoittamatta päättää, hyväksyykö se rekisteröitymisen vai ei. Käyttäjäoikeus on henkilökohtainen, eikä sitä voi luovuttaa edelleen.",
              "Käyttäjä luo itselleen rekisteröitymisen yhteydessä käyttäjätunnuksen (= tilauksessa käytetty sähköpostiosoite) ja salasanan, jotka käyttäjä sitoutuu pitämään salassa ja olemaan luovuttamatta kolmansien käyttöön. Käyttäjä on vastuussa kaikista tunnuksillaan tehdyistä toimenpiteistä. Käyttäjä sitoutuu ilmoittamaan Yritykselle välittömästi, mikäli epäilee tunnustensa paljastuneen kolmansille henkilöille.",
              "Yritys pidättää oikeuden sulkea käyttöoikeus milloin tahansa, mikäli Yritys epäilee tunnuksia käytettävän väärin."
            ]
          },

          {
            title: "Immateriaalioikeudet:",
            nested_paragraphs: [
              "Palvelut, niiden sisältö, aineisto ja materiaalit, kuten tekstit, kuvat, videot, äänet, tavaramerkit ja tunnukset sekä Palveluiden visuaalinen ilme, ovat Yrityksen tai sen yhteistyökumppaneiden omaisuutta, joka on suojattu tekijänoikeuslailla, tavaramerkkioikeudella ja/tai muilla immateriaalioikeuslainsäädännöillä ja -rekisteröinneillä.",
              "Käyttäjällä on oikeus käyttää Palveluita ja niiden sisältöä, materiaaleja ja aineistoja ainoastaan Käyttäjän omaan henkilökohtaiseen, ei-kaupalliseen käyttöön.",
              "Palveluita, niiden sisältöä tai mitään Palveluiden käytön kautta saatua materiaalia ei saa tallentaa, käyttää, esittää julkisesti, toimittaa tai edelleenvälittää kolmannelle osapuolelle paitsi niissä rajoissa, kun se on sallittu tekijänoikeuslainsäädännössä. Käyttäjä ei saa muokata tai jäljentää Palveluita tai niiden sisältöä tai osaa.",
              "Omaa yksityistä käyttöään varten tulostaessaan, kopioidessaan tai tallentaessaan kopiota Käyttäjän tulee säilyttää kaikki tällaisissa tulosteissa tai tallenteissa Palveluiden sisältämät tekijänoikeuksiin tai muihin oikeuksiin liittyvät ilmoitukset.",
              "E-ostoslistojen sisältämä luottamuksellinen tieto sekä tietotaito ovat PIIIA.com:in yksinomaista omaisuutta. E-ostoslista ja sen sisältö annetaan palvelun käyttäjälle ainoastaan vastaanottajan henkilökohtaiseen käyttöön ja ei-kaupallisiin tarkoituksiin. Aineiston luovuttaminen muille tai sen julkaiseminen ja jakaminen missä tahansa sähköisessä tai muussa mediassa on ehdottomasti kielletty.",
              "Mikäli materiaalia edelleenjulkaistaan tai jaellaan laittomasti, sen alkuperäiset ostostiedot voidaan selvittää.",
              "Sivustolla käytetyt ikonit ovat lisensoitu Creative Commons Attribution 4.0 Kansainvälinen -käyttöluvalla. Perustuu teoksiin osoitteessa https://fontawesome.com/license. Tarkastele käyttölupaa osoitteessa http://creativecommons.org/licenses/by/4.0/."
            ]
          },

          {
            title: "Oikeudet toimeksiannon kohteena olevaan aineistoon:",
            nested_paragraphs: [
              "Toimeksiannosta sovittaessa osapuolet sopivat aineiston, teoksen, tuotteen tai muun vastaavan (jäljempänä 'aineisto') käyttöoikeuksien laajuudesta. Se määritellään osana erillistä kirjallista sopimusta, jossa määritellään myös muut toimeksiantoon sisältyvät ehdot.",
              "Yrityksen tuottamien valokuvien, videoiden, graafisten suunnittelujen, verkkosivustojen ja muiden vastaavien toimeksiannon kohteena syntyvien aineistojen omistusoikeus, tekijänoikeudet ja kaikki muut immateriaalioikeudet säilyvät Yrityksellä. Asiakas saa aineistoon erillisessä kirjallisessa sopimuksessa määritellyn mukaisen käyttöoikeuden. Käyttöoikeutta voidaan rajoittaa esimerkiksi käyttöaikaan, kanaviin tai maantieteelliseen sijaintiin perustuen.",
              "Lähtökohtaisesti asiakas saa toimeksiannon kohteena syntyvään aineistoon käyttöoikeuden vain henkilökohtaiseen, yksityiseen käyttöön. Kaupallinen käyttö ilman erillistä sopimusta on kielletty. Asiakkaalla ei ole oikeutta käyttää yksityishenkilönä hankkimaansa aineistoa yrityksensä, yhdistyksensä tai muun toimintansa markkinointiin, mainostamiseen tai esittämiseen. Asiakkaalla ei ole oikeutta hyötyä aineistosta millään tapaa taloudellisesti. Asiakkaalla ei ole oikeutta osallistua kilpailuihin aineistolla tai sen osalla, eikä luovuttaa aineistoa kolmansille osapuolille julkaistavaksi. Asiakas ei saa muunnella, muokata tai manipuloida aineistoa.",
              "Käyttöoikeus valmiiseen aineistoon siirtyy, kun Yritys on saanut asiakkaalta täyden korvauksen työstä tai tuotteesta.",
              "Jos asiakas haluaa käyttää aineistoa muuhun kuin alkuperäisen sopimuksen mukaiseen tarkoitukseen, siitä sekä maksettavasta lisäkorvauksesta on sovittava Yrityksen kanssa ennalta. Asiakkaalla ei ole oikeutta luovuttaa sovittua käyttöoikeutta eteenpäin kolmannelle, vaan siitä on sovittava erikseen.",
              "Kaikki alkuperäiskappaleet, mukaan lukien luonnokset, puhtaaksipiirrokset ja vedokset, ovat Yrityksen omaisuutta. Asiakkaalle esitettyjä luonnoksia ja vedoksia suunnittelutyöstä tai muusta toimeksiannon kohteena olevasta aineistosta ei saa ilman Yrityksen suostumusta käyttää, jäljentää, kopioida tai monistaa millään tavoin. Sähköisesti toimitetut luonnokset ja vedokset tulee hävittää, eikä niitä saa näyttää tai luovuttaa ulkopuolisille.",
              "Toimitettavien ja käytettävien aineistojen (erityisesti esimerkiksi kuvien ja videoiden) valinta on täysin Yrityksen harkinnassa. Asiakkaalla ei ole oikeutta nähdä esimerkiksi kaikkia otettuja kuvia, videoita tai tehtyjä luonnoksia ja vedoksia, jos ne eivät ole Yrityksen näkemyksen mukaan tarpeeksi hyviä teknisesti tai taiteellisesti.",
              "Yritys säilyttää joissakin tapauksissa digitaalisen kopion tilatuista tuotteista, tarjotakseen mahdollisuuden tilata lisää kopioita samasta tuotteesta. Tällöin valmista digitaalista tuotetta säilytetään 6 kuukautta tilauksen valmistumisen jälkeen, eikä Yrityksellä ole sen jälkeen mitään velvollisuutta säilyttää valmiita tuotteita tai materiaaleja. Yksilöllisten kuvitustuotteiden ja yksilöllisten kuvakirjojen osalta valmista digitaalista kopiota valmiista tilatusta tuotteesta säilytetään edellisestä poiketen 24 kuukauden ajan.",
              "Keskeneräiseksi jääneitä tilauksia, esimerkiksi maksamattomien maksujen vuoksi, säilytetään 6 kuukauden ajan, jonka jälkeen Yritys pidättää oikeuden poistaa sekä toimitetut aineistot että keskeneräiseksi jääneet digitaaliset tuotteet ja/tai toimittamista vaille valmiit fyysiset tuotteet.",
              "Yrityksellä ja sen palveluntarjoajilla ja alihankkijoilla on oikeus teettää ja säilyttää kopioita valmiista tuotteesta ja käyttää tuotteita, aineistoa tai sen luonnoksia ja vedoksia omassa markkinointikäytössään, portfoliokäytössään, alan ammattijulkaisuissa, messuilla ja kilpailuissa ilman lupaa."
            ]
          },

          {
            title:
              "Käyttäjän Palveluihin tuottama sisältö ja Asiakkaan lähettämä aineisto:",
            nested_paragraphs: [
              "Käyttäjä vastaa Palveluihin tuottamastansa ja toimittamastansa tai Palveluiden kautta toisille käyttäjille tai kolmansille osapuolille lähettämästänsä sisällöstä, aineistosta ja materiaalista (jäljempänä 'aineisto'). Erityisesti käyttäjä vastaa siitä, että käyttäjän toimittama aineisto ei loukkaa tekijänoikeuksia tai muita oikeuksia, hyvää tapaa, lain tai viranomaisten määräyksiä tai sitä, että se ei ole loukkaavaa tai sopimatonta.",
              "Jos käyttäjä on toimittanut tällaista aineistoa Yritykselle, toisille käyttäjille tai kolmansille osapuolille / palveluntarjoajille, Yrityksellä on oikeus mutta ei velvollisuutta poistaa tällainen loukkaava aineisto tai estää sen käyttö. Aineiston lähettäjä vastaa kaikista kustannuksista, joita Yritykselle sen lainvastaisuuden, loukkaavuuden tai muun vastaavan johdosta syntyy.",
              "Mainostaminen Palveluissa on kielletty ilman lupaa. Mainostamiskielto koskee erityisesti kaupallisessa tarkoituksessa lähetettyjä viestejä, mutta siksi luetaan myös niin kutsuttu spämmääminen.",
              "Yritys ei vastaa käyttäjien tai asiakkaiden toimittamista aineistoista, eikä ole vastuussa niiden mahdollisesta häviämisestä.",
              "Käyttäjä myöntää Yritykselle oikeuden vapaasti ja korvauksetta editoida, kopioida, julkaista tai olla julkaisematta, saattaa yleisön saataville tai olla saattamatta kokonaan tai osittain (mukaan lukien markkinointi ja muu liiketoiminta) sellaista aineistoa, jonka käyttäjä on lähettänyt ja toimittanut Palveluihin tai tuottanut Palveluihin. Yritys voi julkaista käyttäjän Palveluissa julkaisemaa aineistoa toisille verkkosivustoille ja sosiaalisen median palveluihin sekä eri kohtiin Palveluissa. Yrityksellä ei ole velvollisuutta säilyttää vastaanottamaansa aineistoa. Aineiston lähettäjä sitoutuu olemaan esittämättä vaatimuksia Yritystä kohtaan lähettämäänsä aineistoon liittyen. Käyttäjä vastaa kaikista lähettämänsä aineiston käyttöön liittyvistä kolmansien esittämistä korvaus- ja muista vaatimuksista.",
              "Käyttäjän ei tule välittää Yritykselle mitään luottamuksellisia tietoja Palveluiden välityksellä. Käyttäjä hyväksyy, ettei käyttäjän ja hänen puolestaan toimivien henkilöiden Yritykselle Palveluiden kautta välittämiä tietoja tai aineistoa pidetä luottamuksellisena.",
              "Käyttäjä on vastuussa erityisesti, jos aineisto sisältää alaikäisen (tai suojatun aikuisen) kuvia tai muuta aineistoa, ja takaa, että on saanut vanhempien tai laillisen huoltajan luvan näiden kuvien tai muiden aineistojen käyttämiseen ja jäljentämiseen.",
              "Toimeksiantojen osalta asiakkaan siihen lähettämää aineistoa käytetään yksinomaan toimeksiannon toteuttamiseen ja käsittelyyn. Asiakkaan lähettämän aineiston tekijänoikeus säilyy asiakkaalla. Lähettämällä mitä tahansa aineistoa, sitoudut antamaan Yritykselle täyden oikeuden käyttää kyseistä aineistoa. Yrityksellä on oikeus korvauksetta muunnella, käyttää ja uudelleen julkaista asiakkaan lähettämää aineistoa kyseisessä Palveluiden osassa.",
              "Jotta Yritys voi käyttää asiakkaan lähettämää aineistoa, asiakas vakuuttaa että lähetetty aineisto on hänen oman alkuperäisen työn tulosta, tai hänellä on oikeudenhaltijan nimenomainen suostumus aineiston käyttöön ja lisäämiseen Palveluiden osaan eikä hänen toimintansa riko mitään soveltuvaa lakia; ja että hänellä on oikeus antaa Yritykselle oikeus aineiston käyttämiseen yllä kuvatulla tavalla.",
              "Asiakas sitoutuu korvaamaan Yritykselle tai kolmannelle osapuolelle Palvelun tämän kohdan vastaisesta käytöstä mahdollisesti aiheutuvat kustannukset ja vahingot, sekä vastaa Palveluun toimittamansa aineiston oikeudenhaltijoiden tai kolmansien osapuolten esittämiin korvausvaatimuksiin vastaamisesta.",
              "Käyttäjän toimittaessa esimerkiksi valokuvaajan ottamia valokuvia, on hänen huomioitava tarkkaan ja tarvittaessa tarkistettava oman käyttöoikeudensa laajuus niihin. Mikäli aineistosta teetettävien kopioiden käyttöoikeus rajoittuu vain henkilökohtaiseen ja yksityiseen käyttöön, tulee tästä ilmoittaa Yritykselle jo etukäteen ennen tilauksen vahvistamista. Tällöin aineiston käyttöoikeus saattaa vaikuttaa ja rajoittaa mahdollisuuttamme käyttää valmista Palveluidemme osaa esimerkiksi markkinointikäytössämme.",
              "Asiakkaan lähettämän ja Yritykselle toimittaman aineiston tulisi olla alkuperäisiä versioitaan. Kuvat voivat olla niin puhelimella otettuja, kameralla otettuja kuin skannattuja paperikuviakin. Kuvien laadun tulisi kuitenkin olla riittävä painatukseen tai julkaisuun. Sosiaalisen median kanavista jälkikäteen ladatut/tallennetut tai pikaviestisovellusten kautta lähetetyt/vastaanotetut kuvat ovat usein liian huono resoluutioisia. Tarvittaessa Yritys voi kuitenkin koostaa esimerkiksi Piiiabooks -yksilöllisen kuvakirjan myös tällaisista kuvista, mutta silloin asiakkaan tulee tietää, että lopputulos valmiissa kuvakirjassa voi näyttää rakeiselta tai epäselvältä.",
              "Lopullisessa toimeksiannon kohteena olevassa aineistossa ja Palveluiden osassa käytettävien aineistojen valinta on täysin Yrityksen harkinnassa. Asiakas voi aineiston toimituksen yhteydessä informoida Yritystä, mikäli hän toivoo tietyn ainestonsa osan sisältyvän mielellään valmiiseen tuotteeseen tai Palveluiden osaan. Tämä tulee kuitenkin saattaa Yrityksen tiedoksi jo ennen kuin Yritys on käynnistänyt toimeksiannon suunnittelutyön. Yritys voi tarjota asiakkaalle myös laajemman aineiston valikointi- ja kuratointiapua lisämaksua vastaan.",
              "Toimeksiantoa varten toimitettu aineisto tallennetaan tuotantoprosessin ajaksi ja hävitetään viimeistään 45 päivän kuluttua tilauksen täyttämisestä. Tästä huolimatta Yrityksellä on kuitenkin oikeus hyödyntää valmista asiakkaalle tuotettua Palveluiden osaa (esimerkiksi yksilölliset kuvitustuotteet) omassa markkinointikäytössään, portfoliokäytössään, alan ammattijulkaisuissa, messuilla ja kilpailuissa.",
              "Yritys on oikeutettu käyttämään kolmansia osapuolia ja alihankkijoita Palveluidensa tai niiden osien sekä asiakkaiden toimeksiantojen toteuttamisessa. Asiakkaan Yritykselle toimittamaa aineistoa välitetään kolmansille osapuolille ja palveluntarjoajille vain siinä määrin kuin se on tarpeen tilauksen käsittelemiseksi ja viimeistelemiseksi.",
              "Yritys pidättää oikeuden kieltäytyä tuottamasta tai tarjoamasta Palveluita tai Palveluiden osaa asiakkaan lähettämästä aineistosta, joka Yrityksen mukaan ei kunnioita kaikkea edellä mainittua tai on muuten loukkaavaa tai sopimatonta.",

              "<u>Aineiston lähettäminen ja toimitus:</u>",

              "Mikäli asiakas lähettää tai toimittaa Yritykselle aineistoa toimeksiannossaan tai tilauksessaan käytettäväksi (esimerkiksi paperiset valokuvat yksilöllisiin kuvitustuotteisiin) postitse tai muuta lähetystapaa käyttäen, Yritys ei ole vastuussa sen vahingoittumisesta tai katoamisesta. Yritys ei ole vastuussa heille välitettyjen aineistojen tai tietojen vahingoittumisesta, eikä mistään tähän liittyvästä vahingosta, kuten materiaalikuluista, tiedonsiirtomaksuista, valokuvien ottamiseen tai muuhun aineiston tuottamiseen liittyneistä kuluista eikä kadotetun muistojen arvosta. Sama pätee myös Internetin välityksellä, datasiirtoa, USB-muistia, CD-ROM:ia tai muuta tietolevykettä käyttäen toimitettavien digitaalisten kuvien tai muiden aineistojen vahingoittumiseen tai katoamiseen. Suosittelemme aina varmuuskopion ottamista. Yritystä ei voida pitää vastuussa asiakkaan lähettämien aineistojen häviämisestä.",
              "Vastaanotettuaan asiakkaan toimittaman aineiston, Yritys säilyttää aineistoa väliaikaisesti. Yritys ei ole vastuussa aineiston säilytyksestä, varastoinnista eikä mahdollisesta häviämisestä, menettämisestä tai vahingoittumisesta.",
              "Yrityksellä ei ole velvollisuutta palauttaa lähettäjälle vastaanottamaansa aineistoa. Jos asiakas toivoo postitse tai muuta lähetystapaa käyttäen toimittamiensa aineistojen palauttamista takaisin, siitä veloitetaan lisämaksu. Yritys ei vastaa aineiston vahingoittumisesta tai katoamisesta palauttamisenkaan osalta. Aineisto palautetaan mahdollisesti erillään valmiista Palveluiden osasta. Mikäli asiakas ei halua aineistojaan palautettavan takaisin, Yritys joko kierrättää ne tai tuhoaa ne, täysin oman valintansa mukaan, ilman vastuuta aineistoista.",
              "Asiakas voi toimittaa aineistoa halutessaan myös Internet-palveluiden, kuten Dropboxin, Google Driven tai WeTransferin kautta. Yrityksellä on tällöin pääsy vain asiakkaan jakamaan aineistoon ja/tai julkiseen profiiliin. Tutustuthan palveluiden tietosuojaselosteisiin ennen niiden käyttöä. Yritys ei ole vastuussa asiakkaan valitseman tiedonsiirtotavan avulla välitettyjen tietojen vahingoittumisesta tai katoamisesta."
            ]
          },

          {
            title: "Kolmansien oikeudet:",
            nested_paragraphs: [
              "Asiakas on yksinomaisesti vastuussa sen varmistamisesta, että asiakkaalla on kaikki vaadittavat tekijänoikeudet, tavaramerkit ja muut oikeudet lähettämäänsä aineistoon, ja että aineisto ei loukkaa kenenkään yksityisyyttä.",
              "Mikäli jokin muu taho esittää vaatimuksia Yritystä kohtaan liittyen asiakkaan toimittamaan aineistoon ja tilaukseen, tällaisten oikeuksien loukkauksen perusteella, asiakas on vastuussa näiden vaatimusten torjumisesta omalla kustannuksellaan ja velvollinen korvaamaan Yritykselle aiheutuneet vahingot."
            ]
          },

          {
            title: "Vastuunrajoitus:",
            nested_paragraphs: [
              "Palvelut sisältöineen tarjotaan käyttäjälle 'sellaisena kuin se on'. Yritys ei takaa, että Palvelut ovat käyttäjän käytettävissä keskeytyksettömästi, oikea-aikaisesti tai virheettömästi. Yritys voi muuttaa Palveluita ja niiden sisältöjä ilman ilmoitusta. Yritys ei ole vastuussa Palveluissa välitettävien tietojen tai sisältöjen oikeellisuudesta, luotettavuudesta, virheistä, puutteista, epätarkkuuksista, tulkinnanvaraisuudesta tai muista vioista. Yritys ei vastaa niiden käyttämisestä asiakkaalle mahdollisesti aiheutuvista vahingoista.",

              "Yritys ei voi taata Palveluiden, niiden sisältöjen tai Palveluiden kautta välitettävien tietojen tietoturvaa, eikä vastaa siitä. Yritys ei vastaa käyttäjän Palveluihin tai niiden välityksellä lähettämistä tai vastaanottamatta jääneistä aineistoista.",

              "Soveltuvan lain sallimissa rajoissa Yritys ei vastaa asiakkaalle mahdollisesti aiheutuvista välittömistä, välillisistä, suorista tai epäsuorista vahingoista, mukaan luettuina, mutta ei rajoittuen tiedon tai datan menetykseen tai turmeltumiseen, viivästymiseen, voittojen tai tulojen menetykseen tai liiketoiminnan keskeytymiseen, vaikka Yritys olisi saanut tiedon kyseisten vahinkojen mahdollisuudesta. Kaikissa tapauksissa Yrityksen vastuun enimmäismäärä on asiakkaan kyseisestä maksullisesta palvelusta maksama hinta.",

              "Ennen kuin ostat digitaalisia palveluja, kuten e-ostoslistan, luethan huolella myös kohdan 'Vastuuvapauslauseke'.",

              "Yritys ei vastaa siitä, että Palvelut esittäisivät Palveluiden ja tuotteiden värit, mittasuhteet ja yksityiskohdat 100%:in tarkkuudella. Valmiissa Palveluiden osassa ja tuotteissa värit saattavat poiketa alkuperäisestä asiakkaan toimittamasta aineistosta ja sen väreistä. Mahdolliset sävyerot saattavat johtua näyttöjen väri- ja kirkkaussäädöistä sekä painoteknisistä rajoituksista.",

              "Huomioithan että osa Palveluista ja tuotteista valmistetaan käsityönä yksitellen, joten ulkonäössä esiintyy vaihtelevuutta. Tällöin jokainen kappale on uniikki ja yksilöllinen.  Tuotteiden koot ja muodot voivat vaihdella, eikä kahta keskenään, täysin tarkalleen, samanlaista käsityötuotetta olekaan.",

              "Asiakkaan tulee toimeksiantoa tehdessään olla tutustunut Yrityksen aiempiin töihin. Asiakas hyväksyy, että toteutusten tyyli (mukaan luettuna, mutta ei rajoittuen kuvaustyyli ja graafisen suunnittelun tyyli) voi muuttua ajan myötä tai olosuhteiden pakosta. Yritys ei takaa, että Palveluista ja toimeksiannoista syntyvä aineisto ja materiaali olisi tyyliltään samanlaista kuin aiemmin tuotettu aineisto tai materiaali.",

              "Asiakas vastaa Yritykselle toimittamastaan aineistosta, mukaan lukien, mutta ei rajoittuen, sen oikeellisuudesta, käyttö- ja tekijänoikeuksista sekä lainmukaisuudesta. Yritys ei vastaa edes oikeinkirjoitusvirheistä, jotka ovat olleet asiakkaan toimittamassa aineistossa. Yritys vastaa sisällöistä vain jos se kuuluu toimeksiantoon. Silloinkin asiakas on velvollinen tarkistamaan sisällön oikeellisuuden. Asiakkaan hyväksyttyä luonnoksen ja/tai vedoksen ja/tai suoritettuaan tarkistuskierroksen, esikatseluvaiheen, Yritys ei enää vastaa sisällöstä, eikä tämän jälkeen siinä huomattavista virheistä.",

              "Yritys ei ole vastuussa toimittamasi aineiston eikä valmiiden Palveluiden tai niiden osien digitaalisten versioiden mahdollisesta katoamisesta. Yritys ei ole velvoitettu korvaamaan myöskään keskeneräiseksi jäänyttä Palvelua tai sen osaa. Toimitetun aineiston ja keskeneräisten Palveluiden tai niiden osien säilyttäminen voi käydä mahdottomaksi suorittaa esimerkiksi yrityksestä riippumattomasta, ennalta-arvaamattomasta ylivoimaisesta esteestä johtuvasta tilanteesta, kuten onnettomuus tai kovalevyn yllättävä hajoaminen.",

              "Palveluiden ja toimeksiantojen suorittamisen jälkeen Palveluiden tai niiden osan huolehtiminen, käyttäminen ja säilyttäminen on asiakkaan vastuulla, ellei ylläpidosta ole erikseen sovittu."
            ]
          },

          {
            title: "Vastuuvapauslauseke:",
            nested_paragraphs: [
              "Mitään Yrityksen Palveluissa, mukaan lukien, mutta ei rajaten, digitaalisissa palveluissa, esiintyvää tai niissä annettavia tietoja, ohjeita tai neuvoja ei tule käsittää juridisena, lääketieteellisenä, teknisenä tai muuna vastaavana neuvontana, tietoina tai ohjeina. Niiden perusteella ei voi esittää Yritystä kohtaan vaatimuksia. <b>Kaikki Palveluissa esiintyvät tiedot on tarkoitettu ainoastaan informativiisiin tarkoituksiin.</b> Tietoja ei ole tarkoitettu, eikä niitä pidä missään olosuhteissa käsittää ammattimaisena neuvontana, väitteinä, ehdotuksina, diagnooseina, ohjeina tai hoitoina.",

              "<b>Palveluissa tarjottavien ja esiintyvien tietojen tai materiaalien käyttö on täysin omalla vastuullasi.</b> On täysin sinun itsesi vastuulla varmistaa, että kaikki Palveluiden kautta saatavat tuotteet, palvelut tai tiedot täyttävät juuri sinun erityisvaatimuksesi. Yritys ei ole, eikä väitä olevansa minkään alan tai aiheen asiantuntija.",

              "Palveluiden kautta saatavaa tietoa ei saa käyttää ainoana perustana päätöksenteolle. Ohjeistamme sinua ottamaan yhteyttä ammattilaiseen saadaksesi ohjeita ja neuvoja, sekä kuulemaan siten ensisijaisesti tarkempia, täydellisempiä ja ajankohtaisempia tietolähteitä. <b>Ole aina yhteydessä ammattilaiseen ennen kuin käytät Palveluissa esiintyviä ja tarjottavia sisältöä, tietoja, tuotteita, tarvikkeita, työkaluja, materiaaleja tai muuta, ja/tai kun suunnittelet vesi- tai sähköasennuksia.</b> Kaikki tieto ja informaatio tulee myös tarkistaa suoraan tuotteiden ja palveluiden valmistajilta tai tarjoajilta.",

              "Yritys ei ole vastuussa siitä, että Palveluissa tarjottava tieto tai muu materiaali olisi tarkkaa, täydellistä, luotettavaa, soveltuvaa, suorituskykyistä, saatavilla olevaa tai ajantasaista yleisesti eikä tiettyyn tarkoitukseesi. Yritys ei vastaa Palveluissa annettujen tietojen soveltuvuudesta käyttäjän tarpeisiin tai tietojen perusteella tehtyjen toimien tuloksesta tai tuloksellisuudesta.",

              "Yritys ei ole vastuussa mistään välittömistä tai välillisistä vahingoista, jotka saattavat aiheutua Palveluihin ja niiden käyttöön liittyen. Käytät Palveluita omalla vastuulla. Yritys ei ole vastuussa mistään mahdollisista vahingoista, mukaan lukien, mutta ei rajaten, esimerkiksi niistä, joita sinulle tai ajoneuvollesi tapahtuu pakettiautosi muutostöiden aikana.",

              "Verkkokauppa ja sen tuotteet ja palvelut voivat sisältää linkkejä kolmansien osapuolten verkkosivustoille tai palveluihin, jotka eivät ole yhtiön omistuksessa tai hallinnassa. Yritys ei vastaa kolmansien osapuolten verkkosivustoista, verkkosivustojen tai palveluiden sisällöstä, tietosuojakäytännöistä tai muista käytännöistä. Linkkien toimivuutta tai ajantasaisuutta ei voida taata. Yritys ei ole, suoraan tai epäsuorasti, vastuussa mistään vahingoista tai menetyksistä, jotka ovat aiheutuneet tai joiden oletetaan aiheutuneen tällaisen Palveluissa esiintyvän sisällön, tuotteiden tai palveluiden käytöstä tai siihen luottamisesta.",

              "Tällaiset palveluissa tarjottavat linkit ovat tarkoitettu vain avuksi, lisätietojen antamiseksi. Ne eivät tarkoita, että Yritys kannattaa kyseisiä verkkosivustoja tai tuotteita.",

              "<u>E-ostoslistat:</u>",

              'SHOP OUR VAN, - PLUMBING ja -SOLAR -e-ostoslistoihin on listattu PAKUPROJEKTI:ssamme ostamia rakennusmateriaaleja ja tuotteita. E-ostoslistoilta saattaa puuttua joitakin tuotteita, ja ne ovat jaettu kolmeen eri e-ostoslistaan. Jonkin tietyn tuotteen sisältymistä e-ostoslistoihin voi tiedustella ennen ostopäätöstä sähköpostitse: info@piiia.com. E-ostoslistat eivät sisällä joitakin tuotteita tai materiaaleja, jotka olemme saaneet käyttöömme esimerkiksi kierrätettyinä. E-ostoslistat perustuvat <a href="https://youtu.be/v4FShP6USpM">Pakun esittelyvideon / Van tour:in</a> mukaiseen videolla nähtyyn kokonaisuuteen.',

              "SHOP OUR VAN DIMENSIONS -listaukseen on koottu PAKUPROJEKTI:ssamme itse rakentamiemme osasten tarkat mitat, mahdolliset asennuskorkeudet sekä yleismitat pakumme pohjaratkaisusta. Listaus ei sisällä Ford Transit -pakettiauton mittoja, eikä rakennusohjeita. Mitat vaihtelevat sen mukaan, miten ja millä materiaaleilla ajoneuvon sisusta ja sen osaset valitaan rakentaa. Myös ajoneuvon koko sekä sen katon ja sivuseinien kaarevuudet saattavat vaikuttaa lopulliseen kokonaisuuteen ja sen mittoihin poikkeavasti.",

              "Bonus-tuotteina e-ostoslistojen mukana saatavat pakun layout-pohjaratkaisu (ei sis. mittoja), vesikartta ja sähkökartta eivät ole ammattilaisten tekemiä, vaan pohjautuvat täysin omaan tietotaitoomme ja kokemukseemme. <b>Niitä ei tule käyttää milloinkaan ilman ammattilaisen konsultointia ja ohjeistusta! E-ostoslistat ja niiden sisältö tarjoavat vain yleistä tietoa, jota ei ole tarkoitettu asiantuntijaohjeiksi. Emme ole, emmekä väitä olevamme minkään alan tai aiheen asiantuntija.</b>",

              "<b>Kaikki PIIIAshop:issa jaettu tieto on vain informatiiviseen käyttöön tarkoitettu, eikä niitä pidä ottaa ammatillisina neuvoina. Konsultoi ja ota yhteyttä ammattilaiseen aina ennen kuin käytät sisältöä, tietoja, tuotteita, tarvikkeita, työkaluja, materiaaleja tai muuta, tai suunnittelet vesi- tai sähköasennuksia. Vastauksia, sisältöä ja tietoja ei tule käyttää ilman ammattilaisen konsultointia ja ohjeistusta ja ohjeistamme sinua käyttämään ammattilaista asennuksiin.</b>",

              "Yritys ei vastaa tuotteista, tarvikkeista, työkaluista, materiaaleista tai muistakaan, joita ostat e-ostoslistojen listaamina. Käytät tuotteita, tarvikkeita, työkaluja, materiaaleja ja muuta tietoa ja informaatiota omalla vastuulla, emmekä ole vastuussa mahdollisista vahingoista koskien sinua tai kohteena olevaa kohdetta, kuten ajoneuvoasi.",

              "Emme vastaa palveluiden, sisällön, tietojen tai tuotteiden ajantasaisuudesta tai sopivuudesta juuri sinun tarpeisiisi, vaatimuksiisi ja käyttökohteisiisi, tai palveluiden, vastausten, sisällön tai tietojen perusteella tehtyjen toimien tuloksesta tai tuloksellisuudesta. Käytät palveluita, sisältöä, tietoja ja tuotteita omalla vastuulla.",

              "<b>Kaikki tieto ja informaatio tulee tarkistaa suoraan tuotteiden ja palveluiden valmistajilta tai tarjoajilta.</b>",

              "<b>!! Osa SHOP OUR SOLAR -e-ostoslistan ja sähkökartan sähkötarvikkeista saattaa edellyttää rekisteröidyn sähköalan ammattilaisen tekemiä asennuksia! Perehdy siis tarkasti sähköasennusten turvallisuudesta annettuihin lakeihin ja määräyksiin.</b>",

              "<u>Konsultaatiot:</u>",

              "KYSY PAKUPROJEKTISTA, KYSY VESIJÄRJESTELMISTÄ ja KYSY AURINKOSÄHKÖJÄRJESTELMISTÄ -konsultaatiot mahdollistavat henkilökohtaisen, lyhyen konsultaation, jossa keskitytään asiakkaan tarpeidensa mukaan valitsemaan yhteen, tiettyyn asiaan tai kysymykseen konsultaatioiden tuotesivuilla lueteltuihin aihealueisiin liittyen.",

              "Lisänä ylempänä mainittuihin vastuunrajoituksiin ja vastuuvapauslausekkeeseen; Konsultaatioissa tehtyjen kysymysten perusteella voidaan toimitettavissa vastauksissa antaa suuntaa antavia ohjeistuksia, joiden toteutus on kuitenkin aina varmistettava kohteeseen ammattilaisten kanssa. Asiakkaan tulee konsultoida ja ottaa yhteyttä ammattilaiseen aina ennen vastausten, sisällön tai tietojen käyttämistä."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Tilausehdot & Tilauksen tekeminen:",
            nested_paragraphs: [
              "Yritys myy PIIIAshop-verkkokaupassaan Yrityksen tuotteita ja palveluita täysi-ikäisille yksityishenkilöille. Näitä tilaus-, maksu-, toimitus-, peruuttamis- ja palautusehtoja sovelletaan seuraavilla domaineilla ja alidomaineilla myytävien tuotteiden ja palveluiden ostamiseen ja toimittamiseen, ellei Yritys ja asiakas ole kirjallisesti toisin sopineet: https://shop.piiia.com.",

              "Asiakas tekee tilauksen Yritykseltä joko suullisesti, Palveluista, Palveluiden yhteydenottolomakkeilla, sähköpostilla, sosiaalisen median viestimillä tai kirjallisesti. Tilauksen tekemällä asiakas hyväksyy nämä ehdot. Tilaus katsotaan syntyneen, kun Yritys vastaanottaa tilauksen. Yritys vahvistaa vielä asiakkaan tekemän tilauksen sähköpostitse.",

              "Osassa Palveluita Yritys tekee asiakkaalle ensin yhteydenoton pohjalta sähköpostitse lähetettävän tarjouksen. Silloin sitova tilaus syntyy ja astuu voimaan, kun asiakas hyväksyy saamansa tarjouksen ja ehdot. Yritys vahvistaa asiakkaan tekemän tilauksen mahdollisimman pikaisesti sähköpostitse.",

              "Mikäli asiakas ei ole saanut tilausvahvistusta sähköpostitse kolmen (3) arkipäivän sisällä tilauksen tekemisestä, tulee hänen ottaa yhteyttä Yritykseen sähköpostitse: info@piiia.com.",

              "Asiakas on velvoitettu luovuttamaan tilausta tehdessään täydelliset yhteystietonsa, jotka sisältävät yksityisasiakkaalta vähintään nimen, osoitteen, puhelinnumeron ja sähköpostiosoitteen.",

              "Yrityksen ja asiakkaan tekemä sopimus voi olla voimassa sovitun määräajan (esimerkiksi tilauksen ja tarjouksen hyväksymisestä viimeiseen eräpäivään) tai toistaiseksi. Toistaiseksi voimassa olevien sopimusten osalta sovitaan erikseen tehtävässä kirjallisessa sopimuksessa ja sen liitteissä mahdollisista yhden (1) - kolmen (3) kuukauden irtisanomiskuukausista. Toistaiseksi voimassa oleva sopimus on irtisanottava kirjallisesti.",

              "Sopimus on Yrityksen ja asiakkaan välinen. Molemmat osapuolet sitoutuvat pitämään luottamuksellisena kaikki sopimukseen, palveluun ja/tai sen hinnoitteluun liittyvät tiedot.",

              "Yritys varaa oikeuden kieltäytyä tilauksista, esimerkiksi erikoistilanteissa, eikä Yrityksellä ole velvollisuutta ilmoittaa syytä tällaiselle kieltäytymiselle. Yrityksellä on myös oikeus valita asiakkaansa ilman velvollisuutta perustella päätöksiään asiakasvalinnassaan. Mikäli Yritys toimeksiantoja toteuttaessaan havaitsee mahdollisesti lainvastaista aineistoa, toimeksianto keskeytetään.",

              "Yritys on oikeutettu käyttämään kolmansia osapuolia ja alihankkijoita Palveluidensa tai niiden osien sekä asiakkaiden toimeksiantojen toteuttamisessa. Asiakkaan Yritykselle toimittamaa aineistoa välitetään kolmansille osapuolille ja palveluntarjoajille vain siinä määrin kuin se on tarpeen tilauksen käsittelemiseksi ja viimeistelemiseksi.",

              "<u>Asiakkaan toimeksiantoa varten toimittama aineisto:</u>",

              "Osa Palveluista edellyttää myös asiakkaan aineiston toimittamista Yritykselle. Tällöin toimeksiantoa ei voida alkaa täyttämään ja toteuttamaan ennen asiakkaan toimittaman aineiston vastaanottamista. Osa Palveluista edellyttää myös yksityiskohtaisempaa ja laajempaa tausta-aineistoa ja Yrityksen perehdyttämistä. Jos asiakas toimittaa vain epämääräistä ja suppeaa tietoa sekä aineistoa, hänen tulee ymmärtää, että se voi heijastua toimeksiannon lopputulokseen ja valmiiseen tuotteeseen tai Palvelun osaan.",

              "KYSY -konsultaatioiden osalta tilauksen toimittaminen edellyttää asiakkaan Yritykselle toimittamaa selkeää kysymystä, johon asiakas toivoo vastausta. Konsultaatio sisältää mahdollisuuden esittää Yritykselle yhden (1) kysymyksen sen tuotesivulla lueteltuihin aihealueisiin liittyen. Jos Yritykselle toimitetaan useampi kuin yksi kysymys, Yritys valitsee täysin oman valintansa mukaisesti niistä yhden (1), johon vastaa.",

              "Jos Yritys kokee, ettei se kykene vastaamaan konsultaatiossa esitettyyn kysymykseen (esimerkiksi mielestään riittävällä tavalla tai sen laajuuden vuoksi), sillä on oikeus perua palvelu. Tällöin Yritys ilmoittaa palvelun peruuttamisesta asiakkaalle sähköpostitse niin pian kuin mahdollista ja palauttaa asiakkaalle asiakkaan siihen mennessä peruutettavasta palvelusta maksamat maksut.",

              "Asiakas on velvollinen toimittamaan toimeksiantoon tarvittavan tausta-aineiston ja muun materiaalin niiltä osin kuin sopimuksessa tai tilausta vahvistaessa sovittu. Mikäli asiakas ei toimita toimeksiannossa tarvittavia tietoja, tausta-aineistoa tai muuta materiaalia Yritykselle sovitun aikataulun mukaisesti tai toimeksianto viivästyy asiakkaan viaksi luettavasta muusta syystä, on Yrityksellä oikeus lykätä toimeksiantoa ja valmiin Palveluiden osan toimitusta.",

              "Yritys voi erillisestä lisämaksusta tarjota asiakkaalle apua myös tämän aineiston tuottamiseen, muokkaamiseen, valikoimiseen tai kuratoimiseen.",

              "<u>Luonnos, Koevedos & Tarkistuskierros:</u>",

              "Osassa Palveluista asiakkaalle annetaan mahdollisuus niin sanottuun tarkistuskierrokseen, esikatseluvaiheeseen. Siinä Yritys saattaa asiakkaan nähtäväksi luonnoksen tai koevedoksen asiakkaan Yritykselle antamasta toimeksiannosta. Asiakas voi hyväksyä luonnoksen tai koevedoksen sellaisenaan, muutostoivein tai hylätä sen.",

              "Tarkistuskierrosten määrä on sovittu tilausta vahvistaessa, erillisessä kirjallisessa sopimuksessa tai tarjouksen yhteydessä. Ellei toisin ole sovittu, Yrityksen suunnittelupalveluihin sisältyy yleensä yksi (1) tarkistuskierros. Sen jälkeen tehtävistä uusista tarkistuskierroksista laskutetaan jokaisesta erillinen lisämaksu. Näiden lisämaksujen tulee olla kokonaisuudessaan suoritettuna ennen kuin asiakas voi vastaanottaa toimeksiannon osana kohteena olevan aineiston ja valmiin tuotteen.",

              "Asiakas on vastuussa koevedoksen tarkistamisesta ja esikatselusta. Asiakkaan tulee tarkistaa mahdolliset oikeinkirjoitus-, päivämäärä-, tasaus- tai muut asiaan liittyvät seikat. Asiakkaan hyväksyttyä luonnoksen tai vedoksen, ei vaatimuksia tai muutostarpeita sen suhteen voi enää osoittaa. Valmistuvaa tuotetta tai Palvelun osaa ei voi enää muokata, kun luonnos tai vedos on hyväksytty.",

              "<b>Hyväksyminen sellaisenaan:</b>",

              "Asiakas voi hyväksyä luonnoksen tai vedoksen sellaisenaan, ilman muutostoiveita. Halutessaan asiakas voi myös jättää tarkistuskierroksen, esikatseluvaiheen, välistä, jolloin luonnos tai vedos katsotaan suoraan hyväksytyksi sellaisenaan, ilman muutostoiveita.",

              "Mikäli asiakasta on informoitu tarkistuskierroksesta, mutta hän ei palaa Yritykselle asiaan (14 päivän kuluessa), luonnos tai vedos katsotaan suoraan hyväksytyksi, ilman muutostoiveita. Toimeksianto suoritetaan loppuun.",

              "<b>Hyväksyminen muutostoivein:</b>",

              "Ellei toisin ole sovittu, tarkistuskierroksen yhteydessä asiakas voi esittää muutaman pienen muutostoiveen. Muutostoiveella tarkoitetaan olemassa olevan luonnoksen tai vedoksen tietyn kohdan, kuvan tai tekstin sijoittelua, muuttamista, korvaamista tai koon muuttamista tarkasti annetuin erityisin ohjein. Muutostoiveet eivät voi kuitenkaan vaatia tehtäväksi liian suuria suunnittelumuutoksia, mukaan lukien, mutta ei rajoittuen, kokonaan uuden luonnoksen tai vedoksen luominen, monen kohdan täydellinen uudelleen suunnittelu, epämääräiset pyynnöt ulkoasun muuttamiseksi, uuden aineiston toimittaminen tai vanhan korvaaminen ja mallin, mittojen tai suunnan muuttaminen.",

              "Asiakas voi hyväksyä luonnoksen tai vedoksen määritteleminsä muutostoivein. Tällöin Yritys toteuttaa toimeksiannon valmiiksi muutostoiveet huomioiden. Jos ehdotetut muutokset ovat laajempia kuin tehty tarjous, suunnitelma ja sopimus kattaa, Yritys ilmoittaa niiden vaatimien lisätöiden arvioidut kokonais- tai tuntikustannukset asiakkaalle. Asiakas päättää toteutetaanko lisätyöt.",

              "<b>Hylkääminen:</b>",

              "Jos asiakas ei halua toteuttaa lisätöitä tai ei muuten hyväksy luonnosta tai vedosta, voidaan toimeksianto purkaa. Jos toimeksianto puretaan ja Yritys on ehtinyt tehdä työsuoritteita, on Yrityksellä oikeus veloittaa siihen asti tekemästään työstä."
            ]
          },

          {
            title: "Toimitusehdot:",
            nested_paragraphs: [
              "Yritys toimittaa Palvelut asiakkaalle sovitun mukaisesti, kun asiakas on suorittanut maksut maksuehtojen mukaisesti.",

              "Asiakkaan tulee aina varmistaa, että hän luovuttaa tilausta tehdessään täydelliset ja ajantasaiset yhteystietonsa. Yritys ei tee muutoksia toimitusosoitteeseen sen jälkeen, kun tilaus on vahvistettu.",

              "Mikäli asiakkaan tilaamien Palveluiden toimitus viivästyy asiakkaasta johtuvasta syystä, kuten virheelliset tilaus- tai toimitustiedot, Yritys ei vastaa viivästyksestä mahdollisesti aiheutuvista vahingoista.",

              "Mikäli Palveluita on tilattu tarkoituksenmukaisesti ja tahallisin tarkoitusperin väärä laskutus- ja toimitusosoite ja/tai virheellinen sijoittumismaa valittuina, esimerkiksi rahallisen hyödyn vuoksi, Yrityksellä on oikeus peruuttaa tilaus ja olla täyttämättä sitä. Oikean maan ja sijaintisi pääset valitsemaan <i>Osta nyt</i> -näkymän vasemmassa yläreunassa, tai verkkokaupan maapalloikonia napsauttamalla.",

              "Palvelut toimitetaan asiakkaalle joko postitse, digitaalisessa muodossa tai digitaalisina palveluina;",

              "<b>Postitse:</b>",

              "Yritys toimittaa postitse toimitettavia Palveluita ensisijaisesti Suomeen ja muihin EU-maihin. Toimituksia EU:n ulkopuolelle voi tiedustella Yritykseltä. Huomaathan, että Yritys ei välttämättä pysty toimittamaan kaikkiin paikkoihin. Asiakas vastaa kohdemaan tulli- ja verokäytännöistä. Kyseisten maksujen maksamista saatetaan edellyttää, jotta saat tuotteesi tulliviranomaiselta.",

              "Postitse toimitettavien Palveluiden hintaan lisätään toimituskulut, ellei toisin ole sovittu. <b>Toimituskulut määräytyvät lopullisen painon, tilavuuden sekä kohdemaan mukaan.</b> Toimitamme tilaukset kirjeenä tai pakettina Postin (tai Matkahuollon) kautta. Asiakas saa sähköpostitse toimitusvahvistuksen, kun toimitus on matkalla. Kirjeenä lähettäessä toimitus ei välttämättä sisällä lähetystunnusta, eikä sitä siten pystytä jäljittämään seurantakoodin avulla.",

              "Veloitamme ylimääräiset toimituskulut tilauksesta, joka on palautunut meille takaisin virheellisen osoitteen vuoksi tai mikäli sitä ei ole noudettu ajoissa. Tällöin myös tilaus veloitetaan täydestä arvostaan. Jos tilauksesi palautetaan meille, sen säilytysaika on 6 kuukautta. Tämän jälkeen, pidätämme oikeuden poistaa, hävittää ja tuhota tilauksesi, toimitetut aineistot, keskeneräiseksi jääneet digitaaliset tuotteet ja/tai toimittamista vaille valmiit fyysiset tuotteet.",

              "<b>Digitaalisessa muodossa:</b>",

              "Digitaalisessa muodossa toimitettavat tilaukset toimitetaan sähköpostitse asiakkaan tilauksessaan ilmoittamaan sähköpostiosoitteeseen.",

              "Erikseen lähetettävä sähköpostiviesti sisältää tilatut tuotteet tai palvelut, mahdolliset tiedostot, mahdollisen linkin ja muun ohjeistuksen tilanteen mukaan. Asiakas on vastuussa mahdollisesta linkistä, jonka kautta Yritys hänelle tiedostoja jakaa. Asiakkaan tulee ladata linkin kautta jaettava aineisto omalle koneelleen, ja mielellään myös varmuuskopioida se. Asiakkaan tulee ladata aineisto viimeistään 2 viikon kuluessa. Sen jälkeen linkin ei vaadita enää olevan voimassa.",

              "Mikäli digitaalinen aineisto toivotaan toimitettavan muulla tavoin, on siitä sovittava Yrityksen kanssa erikseen. Tällöin asiakas on velvollinen korvaamaan toisenlaisesta toimitustavasta aiheutuneet kulut, kuten muistitikun ja toimituskulut.",

              "<b>Digitaalisina palveluina:</b>",

              "Verkkokaupasta ostetut digitaaliset palvelut, kuten e-ostoslistat, toimivat sähköisesti ja onnistuneen maksutapahtuman jälkeen niihin on pääsy PIIIAshop-verkkokauppaan sisäänkirjautumalla.",

              "Ensimmäisen oston yhteydessä asiakkaan tulee aktivoida PIIIAshop-käyttäjätilinsä. Asiakas saa onnistuneen maksusuorituksen jälkeen sähköpostiinsa tilausvahvistuksen lisäksi, Aktivoi käyttäjätilisi -viestin. Viestin ohjeita seuraamalla aktivoidaan PIIIAshop-käyttäjätili ja luodaan sille salasana.",

              "Kirjautuminen PIIIAshop:iin tapahtuu yläreunassa näkyvää <i>Kirjaudu</i>-nappulaa käyttäen. Salasana on asiakkaan ensimmäisen oston yhteydessä luoma, ja sähköpostiosoite on se, johon viestit PIIIAshop:ilta ovat saapuneet. Onnistuneen sisäänkirjautumisen jälkeen, ostetut digitaaliset palvelut, kuten e-ostoslistat, löytyvät <i>Ostot</i>-osiosta - josta niitä pääsee käyttämään, tai kunkin digitaalisen palvelun omalta tuotesivulta <i>Avaa</i>-nappulaa klikkaamalla.",

              "Ostettujen digitaalisten palveluiden pariin voi palata aina halutessaan samalla tavalla kirjautumalla PIIIAshop-verkkokauppaan sisään. Asiakkaan ostaessa myöhemmin lisää digitaalisia palveluita samaa sähköpostiosoitetta käyttäen, uudet ostot näkyvät suoraan samassa <i>Ostot</i>-osiossa ja ovat heti käytettävissä ilman tarvetta tunnuksen aktivointivaiheen suorittamiselle.",

              "<u>Toimitusaika:</u>",

              "Arvioitu toimitusaika määritellään tilaus- ja toimeksiantokohtaisesti. Osa tuotteista ja palveluista on tilauksesta valmistettavia: toimeksiannot toteutetaan tilauksesta ja mittatilaustuotteet valmistetaan tilauksesta. Palvelut pyritään valmistamaan ja toimittamaan Palveluissa mahdollisesti ilmoitettujen toimitusaika-arvioiden mukaisesti. Toimitusaika on kuitenkin vain arvio ja vaihtelee kunkin hetkisen tilaustilanteen mukaan. Ajoittain valmistusaika voi myös venyä yli sovitun ruuhkan vuoksi.",

              "Yritys ilmoittaa asiakkaalle mahdollisista toimitusaikaa koskevista viivästyksistä.",

              "Osaan Palveluista vaikuttaa suuresti myös asiakkaan ja Yrityksen välinen tiedonvaihto. Toimitus voi viivästyä, mikäli asiakas toimittaa puutteellista aineistoa Yritykselle tai sovitun aineiston toimitus Yritykselle myöhästyy."
            ]
          },

          {
            title: "Hinnat ja Maksuehdot:",
            nested_paragraphs: [
              "Tuotteiden ja palveluiden hinnat määräytyvät kulloinkin voimassaolevan hinnaston tai annetun tarjouksen mukaan. Kaikki hinnat sisältävät arvonlisäveron, paitsi EU:n ulkopuolelta ostettaessa. Yritys pidättää oikeuden hintojen ja toimituskulujen muutoksiin milloin tahansa antamatta etukäteisilmoitusta siitä.",

              "Tuotteet tai palvelut voivat olla ajoittain alennuksessa. Alennus on ilmoitettu ilmoittamalla alennettu hinta punaisella värillä merkittynä. Alkuperäinen hinta on tällöin yliviivattu.",

              "Verkkokaupassa voi maksaa eri valuutoilla, joista käytössä ovat euro (€, EUR), Englannin punta (£, GBP) ja Yhdysvaltain dollari ($, USD).",

              "Osan Palveluista osalta hinnasto ei ole täysin julkaistavissa verkkosivustoilla, koska yksilöityjen, asiakkaiden vaatimusten ja tarpeiden mukaan valmistettujen ja muunneltujen Palveluiden osalta Palvelun tai sen osan lopullinen hinta määräytyy sen lopullisen kokonaisuuden ja vaatimusten perusteella. Asiakkailla on mahdollisuus pyytää Yritykseltä tarjous eri yhteydenottokeinoja käyttäen.",

              "Yritys pidättää oikeuden tilauksen perumiseen selvien hintavirheiden kohdalla. Selvällä hintavirheellä tarkoitetaan Palvelun mahdollista hinnoitteluvirhettä siten, että tuotteen hinta poikkeaa merkittävästi ja selvästi tuotteen oikeasta hinnasta, ja on päivittynyt esimerkiksi teknisestä tai inhimillisestä syystä olennaisesti väärin.",

              "Postitse toimitettavien Palveluiden hintaan lisätään toimituskulut, ellei toisin ole sovittu. Kansainväliset asiakkaat ovat itse vastuussa muista mahdollisista maksuista, kuten tulli- ja veromaksuista. Kyseisten maksujen maksamista saatetaan edellyttää, jotta tilauksen saa tulliviranomaiselta.",

              "Asiakkaan tulee suorittaa Palveluiden maksu Palveluissa ilmoitettuja maksutapoja käyttäen.",

              "Ellei tilausvahvistuksesta tai sopimuksesta muuta ilmene, erääntyy tilauksen kokonaiskauppahinta maksettavaksi heti sopimuksen syntymisen jälkeen.",

              "Aineiston käyttö- ja julkaisuoikeus alkaa, kun Yritys on saanut asiakkaalta kaikki maksusuoritukset, ellei kirjallisesti ole toisin etukäteen sovittu.",

              "Painotyötä vaativia toimeksiantoja ja tilauksia ei lähetetä painoon ennenkuin koko maksu on suoritettu Yritykselle ja Yritys on sen vastaanottanut.",

              "Jos asiakas on hyväksynyt koevedoksen, mutta ei pystykään toimittamaan toimeksiannon loppuunviemiseksi tarvittavia aineistoja tai muuta materiaalia kohtuullisen (14 vuorokautta) ajan sisällä, on Yrityksellä oikeus laskuttaa siihen asti kertyneistä tunneista tai suoritteista, vaikka toimeksiantoa ei olisikaan saatu vielä valmiiksi tai loppuun.",

              "<u>Maksutavat:</u>",

              "Maksutapoina käytössä ovat Palveluista riippuen PayPal-maksu, pankkisiirto (maksuohjeet sähköpostitse pankkisiirtoa varten) tai sähköpostitse vastaanotettava pdf-lasku. Maksutavat voivat vaihdella aika ajoin ja eri maiden välillä. Mahdollisista pankkikuluista vastaa asiakas.",

              "<b>PayPal-maksu:</b>",

              "Voit suorittaa maksun PayPal-saldoltasi tai PayPal-tilillesi liitetyltä maksu- tai luottokortilta. PayPal saattaa edellyttää PayPal-tilille kirjautumista tai PayPal-tilin luontia. Mahdollisesti voit myös suorittaa maksun PayPal-tiliä luomatta - valitse silloin <i>Maksa pankkitilillä tai luottokortilla</i> -kohta. Tilatessa olemassa olevaa PayPal-tiliä käyttäen asiakkaan tulee aina varmistaa, että osoite- ja henkilötiedot ovat päivitetty, jotta tuotteet toimitetaan oikeaan osoitteeseen.",

              "<b>Pankkisiirto:</b>",

              "Valittuasi maksutavaksesi pankkisiirron, saat sähköpostiisi maksuohjeet pankkisiirtoa varten.",

              "<b>Sähköpostitse vastaanotettava pdf-lasku:</b>",

              "Laskulla maksaessa asiakas sitoutuu maksamaan kaikki osamaksuerät tilauksen yhteydessä ilmoitetun tuotteen kokonaishinnan ja maksuaikataulun mukaisesti. Mikäli suoritus ei saavu eräpäivään mennessä, ei tilauksia toimiteta.",

              "Asiakas tulee velvolliseksi maksamaan laskun, kun hän on vahvistanut tilauksen. Yrityksellä on oikeus periä viivästyneille maksusuorituksille korkolain mukaista (sellaisena kuin se kulloinkin on voimassa) viivästyskorkoa. Viivästyskoron lisäksi Yrityksellä on oikeus periä asiakkaalta kohtuulliset, saatavan perimisestä Yritykselle aiheutuneet perintä- ja muistutuskulut. Mahdolliset laskua koskevat huomautukset on esitettävä kirjallisesti ennen laskun eräpäivää.",

              "Mikäli asiakas ei ole maksanut aiempia laskuja ja asiakkaalle on lähetetty yksi tai useampi maksumuistutus, Yritys varaa oikeuden pidättäytyä uusien tilauksien lähettämisestä tai toteuttamisesta, kunnes on saanut täyden maksun.",

              "<b>( Aloitusmaksu: )</b>",

              "Osassa Palveluista saatetaan soveltaa niin sanottua aloitusmaksua. Tällöin asiakas maksaa tarjouksessa esitetystä hinnasta toimeksiannon ja tilauksen vahvistamisen yhteydessä 50%. Laskun eräpäiväksi määritellään 'Heti' ja Yritys aloittaa toimeksiannon tuotantoprosessin ja täyttämisen kyseisen maksusuorituksen vastaanotettuaan. Kokonaislaskun loppuosa laskutetaan Yrityksen itse ilmoittamalla hetkellä, yleensä silloin kun Yritys on suorittanut oman osuutensa prosessista ja Palvelu tai sen osa on valmis lähetettäväksi painoon, tai muuten toimeksiannon etenemisen mukaan. Viimeisen erän yhteydessä laskutetaan myös mahdolliset asiakkaan tilaamat lisätyöt.",

              "<u>Lisätyöt:</u>",

              "Tarjouksen ulkopuolelle jäävistä Palveluista on sovittava etukäteen Yrityksen kanssa. Mikäli asiakas vaatii tarjouksesta, sopimuksesta tai toimeksiannosta poikkeamista tai lisätöitä, laskutetaan ne Yrityksen voimassaolevan hinnaston mukaisesti. Mahdolliset lisätyöt laskutetaan viimeisen laskutettavan erän yhteydessä.",

              "<u>Lahjakortit Palveluihin:</u>",

              "Yrityksen myöntämillä lahjakorteilla voi lunastaa Palveluissa saatavilla olevia Palveluita, jollei lahjakortissa tai erityisissä lahjakortin ehdoissa toisin mainita. Piiiabooks -yksilöllisten kuvakirjojen suunnittelupalvelun lahjakortit voi käyttää vain Piiiabooks -yksilöllisten kuvakirjojen suunnittelupalveluun.",

              "Lahjakortti voidaan lunastaa osissa, jolloin lahjakortille mahdollisesti ylijääneen summan voi käyttää vielä myöhemmin seuraavassa tilauksessa. Jos tilauksen summa on suurempi kuin lahjakortin summa, laskutamme yli menevän osan lahjakortin käyttäjältä Palvelussa käytössä olevia maksutapoja käyttäen. Lahjakortin voi käyttää vain ennen tilausprosessin loppuun saattamista. Lahjakorttia ei voi vaihtaa rahaksi.",

              "Kun lahjakortin voimassaoloaika on kulunut umpeen, lahjakorttia ei voida enää käyttää maksamiseen Palveluissa, sitä ei voi aktivoida uudelleen, eikä jäljellä olevaa käyttämätöntä arvoa voida palauttaa.",

              "Asiakkaan tulee pitää lahjakortti tallessa, kadonneita tai varastettuja lahjakortteja ei voida korvata. Huom! Lahjakorteilla ei ole palautusoikeutta."
            ]
          },

          {
            title: "Peruuttamis-, Keskeyttämis- ja Palautusoikeus:",
            nested_paragraphs: [
              "<u>Toimeksiannot, Palvelusopimukset:</u>",

              "14 päivän peruuttamisaika lasketaan toimeksiannon, palvelusopimuksen tekemisestä.",

              "Peruuttamisoikeus ei koske palvelua, joka on sovittu tietyksi ajankohdaksi. Näiden palveluiden peruuttamisaika voi olla kuitenkin kirjattuna erillisissä sopimusehdoissa.",

              "Kuluttajalla ei ole kaupan peruuttamisoikeutta, jos palvelu on kokonaan suoritettu (esim. tilaus on edennyt painoon) kuluttajan pyynnöstä ennen peruuttamisajan päättymistä ja kuluttajalle on ilmoitettu peruuttamisoikeuden puuttumisesta.",

              "Tällaiseksi edellä mainituksi pyynnöksi voidaan katsoa se, kun asiakas ostaa ja tilaa palvelun verkkokaupasta, hyväksyen samalla ehdot ostopäätöksellään. Kuluttajalle tulee olla ilmoitettu peruuttamisoikeuden käyttämistä koskevista ehdoista tai peruuttamiseen liittyvästä korvausvelvollisuudesta.",

              "Jos palvelun toimittaminen on kuluttajan pyynnöstä aloitettu ennen peruuttamisajan päättymistä - ja se sittemmin peruutetaan osittain suoritettuna, kuluttajan tulee maksaa yritykselle kohtuullinen korvaus, joka lasketaan suhteellisena osuutena sopimuksen mukaisesta kokonaishinnasta.",

              "Yritykselle maksettavassa kohtuullisessa korvauksessa tulee ottaa huomioon jo tehty työ, työn keskeyttämisestä aiheutuvat toimenpiteet, mahdolliset materiaalihankinnat ja korvaus saamatta jääneestä voitosta.",

              "Jos suoritettu ja toimitettava työ ei tyydytä asiakasta, esimerkiksi puutteellisen tai taitamattoman työn vuoksi, asiakas tai Yritys voi peruuttaa toimeksiannon, jolloin asiakas sitoutuu maksamaan Yritykselle siihen asti tehdystä työstä.",

              "Jos toimeksianto peruutetaan ennen sen valmistumista, asiakas ei ole oikeutettu vastaanottamaan toimeksiannon kohteena olevaa aineistoa, vaan kaikki oikeudet säilyvät Yrityksellä.",

              "Yritys voi olettaa alkaneen toimeksiannon peruutetuksi, mikäli asiakas ei ole yhteydessä tai toimita pyydettyjä aineistoja toimeksiannon etenemistä varten 14 vuorokauden kuluttua sovitusta ajankohdasta. Asiakkaan maksamia rahoja ei tässä tapauksessa palauteta.",

              "Tilatusta ja toimitetusta, hyväksytyn koevedoksen mukaisesta, valmiista aineistosta on maksettava täysi palkkio riippumatta siitä, käytetäänkö sitä vai ei. Asiakkaalla on oikeus vastaanottaa toimeksiannon kohteena oleva aineisto ja saada siihen sovitut oikeudet maksettuaan tilauksen.",

              "Mikäli Yritys joutuu perumaan Palvelun sairastumisen tai muun vastaavan esteen vuoksi, se on velvollinen ilmoittamaan asiakkaalle niin pian kun vain on inhimillisesti mahdollista. Tällöin Yritys palauttaa asiakkaalle asiakkaan siihen mennessä Yritykselle maksamat peruutettavan Palvelun maksut. Yritys ei ole velvollinen maksamaan muita korvauksia.",

              "Yrityksellä on oikeus keskeyttää toimeksiannon suorittaminen ennen sovitun ajan loppumista tilanteissa, joissa joku käyttäytyy uhkaavasti tai väkivaltaisesti Yritystä kohtaan, tai käyttäytyy muuten sopimattomasti.",

              "Yrityksellä on oikeus kieltäytyä tilauksien hyväksymisestä ja oikeus tehtyjen sopimusten purkamiseen, jos asiakkaan toimittamassa aineistossa ilmenee lainvastainen, epäsopiva, epäeettinen tai muutoin epäilyttävä sisältö.",

              "<u>Digitaaliset palvelut:</u>",

              "14 päivän peruuttamisaika lasketaan digitaalista sisältöä koskevan sopimuksen tekemisestä.",

              "Kuluttajalla ei ole kaupan peruuttamisoikeutta, jos digitaalisen sisällön toimittaminen on sähköisesti aloitettu kuluttajan suostumuksella ennen peruuttamisajan päättymistä ja kuluttajalle on ilmoitettu peruttamisoikeuden puuttumisesta.",

              "Tällainen edellä mainittu suostumus voidaan katsoa saaduksi, kun asiakas aktivoi palvelun aktivointisähköpostissa esitetyn linkin kautta ja/tai asiakkaan käyttäjätunnukselle luodaan käyttöoikeudet palveluun tai sisältöön.",

              "<u>Tavarat:</u>",

              "14 päivän peruuttamisaika lasketaan tilatun tavaran (tai viimeisen tavaraerän) vastaanottamisesta.",

              "— <b>Mittatilaustuotteet:</b>",

              "Peruuttamisoikeus ei koske tavaraa, joka valmistetaan tai muunnellaan kuluttajan vaatimusten mukaisesti, tai joka tehdään selvästi kuluttajan henkilökohtaisia tarpeita vastaavaksi.",

              "Tilauksen peruuttaminen ei ole mahdollista tilauksen vahvistamisen jälkeen. Tällaista tavaraa ei voi palauttaa, sillä niitä ei voida palauttaa luonteensa vuoksi edelleen myytäväksi, muun muassa siitä syystä, että valmiissa tuotteissa on asiakkaan henkilökohtaista tietoa.",

              "Yrityksellä on oikeus kieltäytyä tilauksien hyväksymisestä ja oikeus tehtyjen sopimusten purkamiseen, jos asiakkaan toimittamassa aineistossa ilmenee lainvastainen, epäsopiva, epäeettinen tai muutoin epäilyttävä sisältö.",

              "— <b>Muut tavarat:</b>",

              "Kuluttaja-asiakkailla on oikeus perua tekemänsä kauppa 14 päivän kuluessa tilattujen tavaroiden vastaanottamisesta. Mikäli asiakas käyttää peruuttamisoikeuttaan, hänen tulee ilmoittaa kaupan peruuttamisesta Yritykselle sähköpostitse osoitteeseen info@piiia.com, aina ennen tavaran palautusta.",

              "Palautusoikeus koskee vain alkuperäispakkauksessa olevia käyttämättömiä ja myyntikelpoisia tuotteita. Huomioithan, että tavara on palautettava viimeistään 14 päivän kuluttua peruuttamisilmoituksen tekemisestä. Yritys ei vastaanota palautuksia, jotka on lähetetty yli 14 vuorokauden kuluttua tilattujen tavaroiden vastaanottamisesta.",

              "Huom! Yritys ei vastaa palauttamisesta aiheutuvista kustannuksista. Asiakas vastaa palautuskustannusten maksamisesta itse.",

              "Kun Yritys on vastaanottanut palautetut tavarat ja tarkistanut, että ne täyttävät kaikki vaatimukset, Yritys palauttaa tavaroiden hinnan asiakkaalle. Yrityksellä on oikeus saada hyvitys mahdollisista maksunpalautukseen liittyvistä kuluista ja pankkimaksuista."
            ]
          },

          {
            title: "Virheet ja Reklamaatiot:",
            nested_paragraphs: [
              "Jos jokin tilattu tuote on loppunut tai palvelua ei voida toimittaa, Yritys ilmoittaa siitä mahdollisimman pian asiakkaalle, aina sähköpostitse.",

              "Asiakkaan tulee tarkistaa saapunut toimitus mahdollisten virheiden havaitsemiseksi. Mikäli toimituksesta puuttuu tilattuja tuotteita tai toimitus on muuten virheellinen, asiakkaan tulee ilmoittaa asiasta Yritykselle sähköpostitse osoitteeseen info@piiia.com ilman aiheetonta viivytystä, kuitenkin viimeistään 14 vuorokauden sisällä tilattujen tuotteiden vastaanottamisesta.",

              "Yritys varaa oikeuden ensisijaisesti korjata virheen tai toimittaa uuden tuotteen. Mikäli tuote on osittain viallinen, voidaan tästä reklamoida vain viallisilta osin.",

              "Mikäli tilattu tuote vaurioitunut kuljetuksen aikana, tee välittömästi reklamaatio kuljetusyhtiöön, tässä tapauksessa Postiin (tai Matkahuoltoon).",

              "Yritys ei anna mitään takuuta siitä, että toimeksiannon kohteena olevat aineistot ja Palvelut tai niiden osat, eivät repeytyisi tai haalistuisi, tai että ne kestäisivät nesteitä, kuten vettä.",

              "Yritys ei vastaa oikeinkirjoitusvirheistä, jotka ovat olleet asiakkaan toimittamassa aineistossa. Painotuotteen ollessa kyseessä, vähäistä väripoikkeamaa ei lueta virheeksi. Asiakkaan toimittaman aineiston alkuperäistä huonoa kuvanlaatua (muun muuassa kuvien liian alhainen resoluutio) tai epäsopivaa / väärää formaattia tai määritelmää ei katsota virheeksi. Asiakas on velvollinen huolellisesti tarkastamaan, soveltuvatko hänen antamansa tiedot tuotteen painattamiseen, ennen kuin hän toimittaa tietoja Yritykselle. Virheeksi ei katsota myöskään virheellistä tuotevalintaa, tilausmäärää tai inhimillisestä virheestä johtuvaa kirjoitusvirhettä.",

              "Luonnoksen tai koevedoksen tarkistuskierroksen sisältävät toimeksiannot ja mittatilaustyöt on hyväksytetty asiakkaalla erikseen, eikä hyväksymisen jälkeen huomattavia virheitä katsota virheiksi.",

              "Mahdolliset teknisessä toteutusvaiheessa vastaan tulleet rajoitteet, jotka ovat ristiriidassa suunnitellun ilmeen, luonnoksen tai vedoksen kanssa ratkaistaan erikseen sovitulla kompromissilla kirjallisesti."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Ylivoimainen este:",
            nested_paragraphs: [
              "Yritys ei vastaa siitä, että Palvelun toimitus estyy, vaikeutuu tai myöhästyy vaikutusmahdollisuuksiemme ulkopuolella olevasta seikasta, kuten sodasta, luonnonkatastrofeista, lakosta tai muusta vastaavasta toimintaamme vaikeuttavasta tai estävästä toiminnasta. Yritys vapautuu velvotteistaan velvoitteen täyttämisen estävien force majeure -olosuhteiden keston ajaksi. Tällöin asiakkaalla ei ole oikeutta saada korvausta Yritykseltä sopimuksen rikkomisesta."
            ]
          },

          {
            title: "Yksityisyys:",
            nested_paragraphs: [
              'Tutustuthan huolella erilliseen <a href="/fi/privacy">rekisteriselosteeseen, tietosuojaamme ja evästekäytäntöömme</a>. Löydät linkin sivuston alalaidasta.'
            ]
          },

          {
            title: "Sovellettava lainsäädäntö ja erimielisyydet:",
            nested_paragraphs: [
              "Näihin Käyttöehtoihin sovelletaan Suomen lakia. Jos Yritys ja käyttäjä eivät pääse neuvotteluteitse yhteisymmärrykseen mahdollisessa riitatilanteessa, ratkaistaan riita ensiasteena Kymenlaakson käräjäoikeudessa."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Yhteystiedot:",
            nested_paragraphs: [
              "PIIIAshop / PIIIA.com",
              '<a href="mailto:info@piiia.com">info@piiia.com</a> <span class="f7 i">(Huomaathan piiia:n kolme i:tä!)</span>'
            ]
          }
        ]
      }
    ]
  },

  faq: {
    /* => faq.vue */
    title: "UKK",
    subtitle: "Usein kysytyt kysymykset & Ohjeita",
    paragraph:
      'Tutustu huolellisesti sekä PIIIAshop verkkokaupan <a href="/fi/terms/">ehtoihin</a> että <a href="/fi/privacy/">rekisteriselosteeseen ja tietosuojaan</a>. Alta löydät usein kysyttyjä kysymyksiä, ohjeita sekä yhteystiedot verkkokauppaan mahdollisia ongelmatilanteita varten.',
    article: [
      {
        paragraphs: [
          {
            title: "Ota yhteyttä:",
            nested_paragraphs: [
              "Kaikissa ongelmatilanteissa tai kysymyksissä koskien verkkokauppaa ja sen tuotteita, ota yhteyttä meihin sähköpostitse:",
              '<a href="mailto:info@piiia.com">info@piiia.com</a> <span class="f7 i">(Huomaathan piiia:n kolme i:tä!)</span>'
            ]
          },

          {
            title: "Verkkokaupan tekniset vaatimukset:",
            nested_paragraphs: [
              "Verkkokauppa on testattu seuraavilla selaimilla: Google Chrome, Apple Safari ja Mozilla Firefox. Suosittelemme päivittämään selaimista viimeisimmät versiot. Emme vastaa verkkokaupan toimivuudesta tai tuesta muiden selaimien osalta. Suosittelemme käyttämään edellä mainittuja selaimia.",
              "Päivitämme säännöllisesti verkkokauppaa. Jos huomaat puutteita tai virheitä, ilmoitathan ystävällisesti meille asiasta."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Toimitustavat & Toimituskulut:",
            nested_paragraphs: [
              "Tilaukset toimitetaan asiakkaalle joko postitse, digitaalisessa muodossa tai digitaalisena palveluna;",

              "<u>Postitse:</u>",

              "Postitse toimitettavat tilaukset toimitetaan asiakkaan tilauksessaan ilmoittamaan osoitteeseen. Asiakkaan tulee aina varmistaa, että hän luovuttaa tilausta tehdessään täydelliset ja ajantasaiset yhteystietonsa. Emme tee muutoksia toimitusosoitteeseen sen jälkeen, kun tilaus on vahvistettu.",

              "Postitse toimitettavien tilausten hintaan lisätään toimituskulut, ellei toisin ole sovittu. Toimituskulut sisältävät posti- ja pakkauskulut. Toimituskulut määräytyvät lopullisen painon, tilavuuden ja kohdemaan mukaan.",

              "— <i>tarrat, avaimenperät, yksilölliset kuvitustuotteet, yksilölliset kuvakirjat:</i> Suomi <b>3,00 euroa</b>, muut EU-maat <b>6,00 euroa</b>",

              "Toimitamme tilaukset kirjeenä tai pakettina Postin (tai Matkahuollon) kautta. Saat sähköpostitse toimitusvahvistuksen, kun toimitus on matkalla. Kirjeenä lähettäessä toimitus ei välttämättä sisällä lähetystunnusta, eikä sitä siten pystytä jäljittämään seurantakoodin avulla.",

              "Toimitamme postitse toimitettavia tilauksia ensisijaisesti Suomeen ja muihin EU-maihin. Toimituksia EU:n ulkopuolelle voi kuitenkin tiedustella. Huomaathan, ettemme välttämättä pysty toimittamaan kaikkiin paikkoihin. Asiakkaana vastaat kohdemaan tulli- ja verokäytännöistä. Kyseisten maksujen maksamista saatetaan edellyttää, jotta saat tuotteesi tulliviranomaiselta.",

              "Huomioithan, että noutamatta jätetyistä tai virheellisen osoitteen vuoksi meille palautuneista tilauksista joudumme veloittamaan ylimääräiset toimituskulut, sekä myös tilauksen täydestä arvostaan.",

              "<u>Digitaalisessa muodossa:</u>",

              "Digitaalisessa muodossa toimitettavat tilaukset toimitetaan sähköpostitse asiakkaan tilauksessaan ilmoittamaan sähköpostiosoitteeseen.",

              "— <i>lahjakortit, konsultaatiot:</i>",

              "Erikseen lähetettävä sähköpostiviesti sisältää tilatut tuotteet tai palvelut, kuten lahjansaajalle tulostettavan / sähköpostitse välitettävän lahjakortin, tai vastauksemme konsultaatiossa keskityttyyn asiaan / esitettyyn kysymykseen.",

              "— <i>muut palvelut:</i>",

              "Mahdolliset muut palvelut, jotka toimitetaan digitaalisessa muodossa, voivat sisältää tietoja toimitetuista palveluista, mahdollisia tiedostoja, mahdollisen linkin ja/tai muun ohjeistuksen tilanteen mukaan. Asiakas on vastuussa mahdollisesta linkistä, jonka kautta hänelle tiedostoja jaetaan. Asiakkaan tulee ladata linkin kautta jaettava aineisto omalle koneelleen, ja mielellään myös varmuuskopioida se. Asiakkaan tulee ladata aineisto viimeistään 2 viikon kuluessa. Sen jälkeen linkin ei vaadita enää olevan voimassa.",

              "* Mikäli digitaalinen aineisto toivotaan toimitettavan muulla tavoin, on siitä sovittava kanssamme erikseen. Tällöin asiakas on velvollinen korvaamaan toisenlaisesta toimitustavasta aiheutuneet kulut, kuten muistitikun ja toimituskulut.",

              "<small><i>Mikäli digitaalisessa muodossa toimitettavia tuotteita tai palveluita on tilattu tarkoituksenmukaisesti ja tahallisin tarkoitusperin väärä laskutus- ja toimitusosoite ja/tai virheellinen sijoittumismaa valittuina, esimerkiksi rahallisen hyödyn vuoksi, Yrityksellä on oikeus peruuttaa tilaus ja olla täyttämättä sitä. Oikean maan ja sijaintisi pääset valitsemaan <i>Osta nyt</i> -näkymän vasemmassa yläreunassa, tai verkkokaupan maapalloikonia napsauttamalla.</i></small>",

              "<u>Digitaalisena palveluna:</u>",

              "Verkkokaupasta ostetut digitaaliset palvelut, kuten e-ostoslistat, toimivat sähköisesti ja onnistuneen maksutapahtuman jälkeen niihin on pääsy PIIIAshop-verkkokauppaan sisäänkirjautumalla.",

              "— <i>e-ostoslistat:</i>",

              "Kirjauduttuasi onnistuneesti verkkokauppaan sisään, näet ostamasi e-ostoslistat <i>Ostot</i>-osiossa - ja pääset käyttämään niitä sieltä, tai kunkin e-ostoslistan omalta tuotesivulta <i>Avaa</i>-nappulaa klikkaamalla.",

              "Ensimmäisen oston yhteydessä sinun tulee aktivoida PIIIAshop-käyttäjätilisi. Saat siihen liittyen meiltä sähköpostiisi (tilausvahvistuksen lisäksi) <i>Aktivoi käyttäjätilisi</i> -viestin. Seuraa viestin ohjeita PIIIAshop-käyttäjätilisi aktivoimiseksi ja salasanan luomiseksi.",

              "<small><i>Mikäli digitaalisia palveluita on tilattu tarkoituksenmukaisesti ja tahallisin tarkoitusperin väärä laskutus- ja toimitusosoite ja/tai virheellinen sijoittumismaa valittuina, esimerkiksi rahallisen hyödyn vuoksi, Yrityksellä on oikeus peruuttaa tilaus ja olla täyttämättä sitä. Oikean maan ja sijaintisi pääset valitsemaan <i>Osta nyt</i> -näkymän vasemmassa yläreunassa, tai verkkokaupan maapalloikonia napsauttamalla.</i></small>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Toimitusaika:",
            nested_paragraphs: [
              "Teemme parhaamme, jotta saisimme toimitettua tilaukset mahdollisimman nopeasti. Ilmoitamme asiakkaalle mahdollisista toimitusaikaa koskevista viivästyksistä.",

              "— <i>tarrat:</i>",

              "Pyrimme postittamaan tilatut tarrat vähintään viiden (5) arkipäivän sisällä tilauksen saapumisesta, jonka jälkeen tilaus siirtyy kuljetusliikkeelle toimitettavaksi.",

              "Toimitukset tulevat perille Suomeen pääsääntöisesti neljässä (4) arkipäivässä kirjeen tai paketin lähettämisestä, ja muihin EU-maihin pääsääntöisesti kahdeksassa (8) työpäivässä kirjeen tai paketin lähettämisestä. Toimitusaika on kuitenkin vain arvio.",

              "— <i>avaimenperät, yksilölliset kuvitustuotteet, yksilölliset kuvakirjat:</i>",

              "Avaimenperät, yksilölliset kuvitustuotteet ja yksilölliset kuvakirjat valmistetaan tilauksesta. Avaimenperien valmistus vie noin yhden (1) viikon, yksilöllisten kuvitustuotteiden valmistus vie noin kolme (3) viikkoa ja yksilöllisten kuvakirjojen valmistus vie noin kolme-neljä viikkoa (3-4). Valmistusaika on kuitenkin vain arvio ja vaihtelee kunkin hetkisen tilaustilanteen mukaan.",

              "Kun tilaus on valmis, se siirtyy kuljetusliikkeelle toimitettavaksi. Toimitukset tulevat perille Suomeen pääsääntöisesti neljässä (4) arkipäivässä kirjeen tai paketin lähettämisestä, ja muihin EU-maihin pääsääntöisesti kahdeksassa (8) työpäivässä kirjeen tai paketin lähettämisestä. Toimitusaika on kuitenkin vain arvio.",

              "— <i>lahjakortit:</i>",

              "Lahjakortit toimitetaan viimeistään 48 tunnin sisällä tilauksen saapumisesta. Lahjakortit ovat kuitenkin heti onnistuneen maksutapahtuman jälkeen voimassa - ja myös pelkkä tilausvahvistus toimii tarvittaessa lahjakorttina (esim. jos lahjalla on kiire!).",

              "— <i>konsultaatiot:</i>",

              "Konsultaatiot toteutetaan tilauksesta. Pyrimme toimittamaan konsultaation vähintään viiden (5) arkipäivän sisällä tilauksen saapumisesta. Toimitusaika on kuitenkin vain arvio ja vaihtelee kunkin hetkisen tilaustilanteen mukaan.",

              "— <i>digitaaliset palvelut:</i>",

              "Digitaaliset palvelut, kuten e-ostoslistat, toimivat sähköisesti ja onnistuneen maksutapahtuman jälkeen niihin on heti pääsy PIIIAshop-verkkokauppaan sisäänkirjautumalla."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Maksutavat:",
            nested_paragraphs: [
              "Pääset ostamaan verkkokaupan tuotteita ja palveluita niiden omilta tuotesivuilta, klikkaamalla siellä näkyvää <i>Osta nyt</i> -nappulaa.",

              "Tuotteet ostetaan siis ns. yksitellen, montaa tuotetta ei valitettavasti pysty keräämään yhteiseen maksuun. Tarratuotteiden, avaimenperien, yksilöllisten kuvitustuotetilausten ja yksilöllisten kuvakirjatilausten osalta voit valita ostomäärän 1-5 väliltä.",

              "Tilaukset ja niiden toimituskulut maksetaan tilauksen yhteydessä. Maksutapoina käytössä ovat PayPal-maksu tai pankkisiirto;",

              "<u>PayPal-maksu:</u>",

              "PayPal on nopea ja turvallinen tapa maksaa ostoksia verkossa. Valitse <i>Osta nyt</i> -nappulaa klikattuasi maksutavaksesi <b>PayPal-maksu</b>, ja PayPal ohjaa sinut eteenpäin.",

              "PayPal vaatii sisäänkirjautumisen PayPal:iin, jonka jälkeen pääset tekemään turvallisen korttimaksun. Huom! Ellei sinulla ole vielä PayPal-tunnusta, pystyt luomaan sen PayPal-sisäänkirjautumisikkunassa alhaalla näkyvää <i>Luo tunnus / Create an account</i> -kohtaa klikkaamalla. Mahdollisesti voit myös suorittaa maksun PayPal-tiliä luomatta - valitse silloin <i>Maksa pankkitilillä tai luottokortilla</i> -kohta.",

              "Maksun onnistuttua PayPal palauttaa sinut takaisin verkkokauppaan. Vahvista ja hyväksy vielä PayPal:ista saadut tuotteen toimittamiseen liittyvät tietosi. Toimitustietojesi vahvistamisen jälkeen saat tilausvahvistuksen sähköpostiisi.",

              "Kuulostiko ohje hankalalta - voit katsoa ohjeen myös videomuodossa alta!",

              "<u>Pankkisiirto:</u>",

              "Voit maksaa myös pankkisiirrolla. Valitse silloin <i>Osta nyt</i> -nappulaa klikattuasi maksutavaksesi <b>Pankkisiirto</b>. Verkkokauppa ohjaa sinut syöttämään tilaukseen liittyvät maksu- ja toimitustietosi. Sen jälkeen saat pankkisiirron maksuohjeet sähköpostiisi. Maksusi saavuttua pankkiimme, saat tilausvahvistuksen (huomaa mahdollinen viive)."
            ]
          },

          {
            title: "Video-ohje PayPal-maksamiseen:",
            nested_paragraphs: [
              '<div class="w-100"><iframe src="https://www.youtube.com/embed/JkX2rce99GQ" frameborder="0" allowfullscreen></iframe></div>'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Lahjakortit:",
            nested_paragraphs: [
              "<u>Osta lahjakortti PIIIAshop:iin:</u>",

              "PIIIAshop-verkkokaupassa myydään lahjakortteja PIIIAshop-verkkokauppaan. Lahjakortin voi ostaa haluamalleen summalle ja se on voimassa 6 kuukautta ostohetkestä.",

              "PIIIAshop-lahjakorttia käy maksuvälineenä PIIIAshop-verkkokaupassa. Lahjakortin voi käyttää kerralla tai osissa. Lahjakorttia ei voi vaihtaa rahaksi, eikä sitä voi käyttää lahjakorttien ostamiseen.",

              "Lahjakortti on sähköinen, ja voit joko tulostaa sen - tai lähettää sen suoraan lahjansaajan sähköpostiin!",

              "<u>Käytä saamaasi PIIIAshop-lahjakorttia:</u>",

              "Ihana kuulla, että olet saanut lahjakortin PIIIAshop-verkkokauppaamme! Odotamme innolla mihin tuotteisiin ja palveluihin aiot sen käyttää.",

              "PIIIAshop-lahjakortti käy maksuvälineenä PIIIAshop-verkkokaupassa. Lahjakortin voi käyttää kerralla tai osissa - eli jos lahjakortille jää tilauksesi jälkeen vielä arvoa jäljelle, voit käyttää ylijääneen summan seuraavassa tilauksessasi. Jos tilauksesi summa on suurempi kuin lahjakortin summa, laskutamme yli menevän osan sinulta käytössä olevia maksutapoja käyttäen. Lahjakorttia ei voi vaihtaa rahaksi, eikä sitä voi käyttää lahjakorttien ostamiseen.",

              "Kun haluat käyttää lahjakorttiasi, ota meihin yhteyttä sähköpostitse: info@piiia.com. Otsikoi viestisi 'Lahjakortti PIIIAshop' ja mainitse viestissäsi lahjakortistasi, sen koodista ja siitä mihin haluaisit sen käyttää.",

              "PIIIAshop-lahjakortit ovat voimassa 6 kuukautta ostohetkestä.",

              "<u>Osta piiiabooks-lahjakortti:</u>",

              'PIIIAshop-verkkokaupassa myydään myös lahjakortteja <a href="https://kuvakirjat.piiia.com">piiiabooks -yksilöllisten kuvakirjojen suunnittelupalveluun</a>. Lahjakortin voi ostaa haluamalleen summalle ja se on voimassa 6 kuukautta ostohetkestä.',

              'piiiabooks-lahjakorttia voi käyttää vain piiiabooks -yksilöllisten kuvakirjojen suunnittelupalveluun, jonka löydät osoitteesta: <a href="https://kuvakirjat.piiia.com">https://kuvakirjat.piiia.com</a>.',

              "piiiabooks-lahjakortti käy maksuvälineenä vain ja ainoastaan piiiabooks -yksilöllisen kuvakirjan teettämiseen. Lahjakortin voi käyttää kerralla tai osissa. Lahjakorttia ei voi vaihtaa rahaksi.",

              "Lahjakortti on sähköinen, ja voit joko tulostaa sen - tai lähettää sen suoraan lahjansaajan sähköpostiin!"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Peruuttamis- ja Palautusoikeus:",
            nested_paragraphs: [
              "<u>Digitaaliset palvelut:</u>",
              "14 päivän peruuttamisaika lasketaan digitaalista sisältöä koskevan sopimuksen tekemisestä.",
              "Kuluttajalla <b>ei ole kaupan peruuttamisoikeutta, jos digitaalisen sisällön toimittaminen on sähköisesti aloitettu</b> kuluttajan suostumuksella ennen peruuttamisajan päättymistä ja kuluttajalle on ilmoitettu peruttamisoikeuden puuttumisesta.",
              "Tällainen edellä mainittu suostumus voidaan katsoa saaduksi, kun asiakas aktivoi palvelun aktivointisähköpostissa esitetyn linkin kautta ja/tai asiakkaan käyttäjätunnukselle luodaan käyttöoikeudet palveluun tai sisältöön.",
              "<u>Tavarat:</u>",
              "14 päivän peruuttamisaika lasketaan tilatun tavaran (tai viimeisen tavaraerän) vastaanottamisesta.",
              "<b>— <i>Mittatilaustuotteet:</i><b>",
              "Peruuttamisoikeus <b>ei koske tavaraa, joka valmistetaan tai muunnellaan kuluttajan vaatimusten mukaisesti, tai joka tehdään selvästi kuluttajan henkilökohtaisia tarpeita vastaavaksi</b>.",
              "Tilauksen peruuttaminen ei ole mahdollista tilauksen vahvistamisen jälkeen. Tällaista tavaraa ei voi palauttaa, sillä niitä ei voida palauttaa luonteensa vuoksi edelleen myytäväksi.",
              "<small><i>Yrityksellä on oikeus kieltäytyä tilauksien hyväksymisestä ja oikeus tehtyjen sopimusten purkamiseen, jos asiakkaan toimittamassa aineistossa ilmenee lainvastainen, epäsopiva, epäeettinen tai muutoin epäilyttävä sisältö.</i></small>",
              "<b>— <i>Muut tavarat:</i></b>",
              "Kuluttaja-asiakkailla <b>on oikeus perua tekemänsä kauppa 14 päivän kuluessa tilattujen tavaroiden vastaanottamisesta</b>. Mikäli asiakas käyttää peruuttamisoikeuttaan, hänen tulee ilmoittaa kaupan peruuttamisesta Yritykselle sähköpostitse osoitteeseen info@piiia.com, aina ennen tavaran palautusta.",
              "Palautusoikeus koskee vain alkuperäispakkauksessa olevia käyttämättömiä ja myyntikelpoisia tuotteita. Huomioithan, että tavara on palautettava viimeistään 14 päivän kuluttua peruuttamisilmoituksen tekemisestä. Yritys ei vastaanota palautuksia, jotka on lähetetty yli 14 vuorokauden kuluttua tilattujen tavaroiden vastaanottamisesta.",
              "<b>Huom! Yritys ei vastaa palauttamisesta aiheutuvista kustannuksista.</b> Asiakas vastaa palautuskustannusten maksamisesta itse.",
              "Kun Yritys on vastaanottanut palautetut tavarat ja tarkistanut, että ne täyttävät kaikki vaatimukset, Yritys palauttaa tavaroiden hinnan asiakkaalle. Yrityksellä on oikeus saada hyvitys mahdollisista maksunpalautukseen liittyvistä kuluista ja pankkimaksuista.",
              "<u>Toimeksiannot, Palvelusopimukset:</u>",
              "14 päivän peruuttamisaika lasketaan toimeksiannon, palvelusopimuksen tekemisestä.",
              "Kuluttajalla <b>ei ole kaupan peruuttamisoikeutta, jos palvelu on kokonaan suoritettu</b> (esim. tilaus on edennyt painoon) kuluttajan pyynnöstä ennen peruuttamisajan päättymistä ja kuluttajalle on ilmoitettu peruuttamisoikeuden puuttumisesta.",
              "Tällaiseksi edellä mainituksi pyynnöksi voidaan katsoa se, kun asiakas ostaa ja tilaa palvelun verkkokaupasta, hyväksyen samalla ehdot ostopäätöksellään. Kuluttajalle tulee olla ilmoitettu peruuttamisoikeuden käyttämistä koskevista ehdoista tai peruuttamiseen liittyvästä korvausvelvollisuudesta.",
              "Jos palvelun toimittaminen on kuluttajan pyynnöstä aloitettu ennen peruuttamisajan päättymistä - <b>ja se sittemmin peruutetaan osittain suoritettuna, kuluttajan tulee maksaa yritykselle kohtuullinen korvaus</b>, joka lasketaan suhteellisena osuutena sopimuksen mukaisesta kokonaishinnasta.",

              "Yritykselle maksettavassa kohtuullisessa korvauksessa tulee ottaa huomioon jo tehty työ, työn keskeyttämisestä aiheutuvat toimenpiteet, mahdolliset materiaalihankinnat ja korvaus saamatta jääneestä voitosta.",
              "Yritys voi olettaa alkaneen toimeksiannon peruutetuksi, mikäli asiakas ei ole yhteydessä tai toimita pyydettyjä aineistoja toimeksiannon etenemistä varten 14 vuorokauden kuluttua sovitusta ajankohdasta. Asiakkaan maksamia rahoja ei tässä tapauksessa palauteta.",
              "<small><i>Yrityksellä on oikeus kieltäytyä tilauksien hyväksymisestä ja oikeus tehtyjen sopimusten purkamiseen, jos asiakkaan toimittamassa aineistossa ilmenee lainvastainen, epäsopiva, epäeettinen tai muutoin epäilyttävä sisältö.</i></small>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Virheet ja Reklamaatiot:",
            nested_paragraphs: [
              "Jos jokin tilattu tuote on loppunut tai palvelua ei voida toimittaa, ilmoitamme siitä mahdollisimman pian asiakkaalle, aina sähköpostitse.",
              "Asiakkaan tulee tarkistaa saapunut toimitus mahdollisten virheiden havaitsemiseksi. Mikäli toimituksesta puuttuu tilattuja tuotteita tai toimitus on muuten virheellinen, asiakkaan tulee ilmoittaa asiasta Yritykselle sähköpostitse osoitteeseen info@piiia.com ilman aiheetonta viivytystä, kuitenkin viimeistään 14 vuorokauden sisällä tilattujen tuotteiden vastaanottamisesta.",
              "Yritys varaa oikeuden ensisijaisesti korjata virheen tai toimittaa uuden tuotteen. Mikäli tuote on osittain viallinen, voidaan tästä reklamoida vain viallisilta osin.",
              "Mikäli tilattu tuote vaurioitunut kuljetuksen aikana, tee välittömästi reklamaatio kuljetusyhtiöön, tässä tapauksessa Postiin (tai Matkahuoltoon)."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "Yksilölliset kuvitukset / Onko tarra, magneetti, avaimenperä tai printti siis omasta ajoneuvostani tai rakennuksestani?:",
            nested_paragraphs: [
              'Kyllä! Yksilöllinen kuvitus – <b><a href="/fi/product/CSTM-LLSTRTN-1">Tarra</a></b>, <b><a href="/fi/product/CSTM-LLSTRTN-2">Magneetti</a></b>, <b><a href="/fi/product/CSTM-LLSTRTN-3">Avaimenperä</a></b> ja <b><a href="/fi/product/CSTM-LLSTRTN-4">Printti</a></b> -tilaukset sisältävät sinun seikkailuajoneuvosi tai rakennuksesi piirtämisen ja kuvittamisen suoraan 5 kappaleeksi valmiita tarroja / magneetteja / avaimenperiä, tai 1 valmiiksi printiksi! Voit siis ikuistaa oman menopelisi tai vaikka mökkisi valintasi mukaan, vaikkapa tarraksi juomapulloon, magneetiksi jääkaapin oveen tai avaimenperäksi avainnippuusi.',

              "Ajoneuvosi olla paku, retkeilyauto, matkailuauto, henkilöauto, matkailuvaunu tai vaikkapa bussi. Ja jos seikkailet menopelillä jota ei edellä vielä mainittu — voit tiedustella mahdollisuutta meiltä sähköpostitse! Rakennus voi olla vaikkapa rakas kotitalosi tai kesämökkisi.",

              "Ajoneuvo/rakennus on tarrassa/magneetissa/avaimenperässä/printissä kuvattuna sivulta. Käytämme suunnittelun pohjana ajoneuvosta/rakennuksesta meille toimittamaasi valokuvaa (tai valokuvia). Tilauksen valmistamisen aloittaminen edellyttääkin siis, että toimitat meille onnistuneen maksutapahtuman jälkeen valokuvan (tai valokuvat) ajoneuvostasi/rakennuksestasi.",

              "Ainakin yhden valokuvista tulee olla suoraan ajoneuvon/rakennuksen sivulta otettu sivukuva — seisothan tällöin tarpeeksi kaukana ja tähtäät otoksen suoraan ajoneuvon/rakennuksen keskelle. Otathan valokuvan päivänvalossa. Voit halutessasi liittää mukaan myös muutaman lisäkuvan esim. näkymän suoraan ajoneuvon edestä tai ajoneuvon/rakennuksen ominaisuuksia ja yksityiskohtia vielä paremmin ja tarkemmin hahmottavia lähikuvia.",

              "Toimita valokuva(t) sähköpostitse osoitteeseen: info@piiia.com (huomaathan piiia:n kolme i:tä!). Otsikoi viestisi 'Yksilöllinen kuvitus' ja mainitse viestissäsi myös mahdollinen ajoneuvosi merkki ja malli, tekstitoiveesi sekä tilauksesi tilausnumero, jonka löydät sähköpostiisi saapuneesta tilausvahvistuksesta."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Yksilölliset kuvakirjat / Mikä on yksilöllinen kuvakirja?:",
            nested_paragraphs: [
              'PIIIAshop-verkkokaupassa myydään myös <b>piiiabooks -yksilöllisten kuvakirjojen suunnittelupalvelun</b> tuotteita. Myynnissä ovat <b><a href="/fi/product/CSTM-PHTBK-1">S-koon</a></b>, <b><a href="/fi/product/CSTM-PHTBK-2">M-koon</a></b> ja <b><a href="/fi/product/CSTM-PHTBK-3">L-koon</a></b> kuvakirjapaketit, sekä <b><a href="/fi/product/GFT-CRD-4">lahjakortteja palveluun</a></b>, yksilöllisen kuvakirjan teettämiseen.',

              "YKSILÖLLINEN KUVAKIRJA -tilaus sisältää suunnittelutyön, uniikin kuvituksen valokuvista poimitusta aiheesta ja valmiin Yksilöllisen kuvakirjan (S-, M- tai L-koko, 1 kpl, A4 pysty/vaaka). Kuvakirjapakettien kokovaihtoehdot määräytyvät seuraavasti: S-koko — kuvia enintään: 100 kpl, M-koko — kuvia enintään: 200 kpl ja L-koko — kuvia enintään: 300 kpl.",

              'Tarjoamme myös Custom-räätälöityjä kuvakirjakokonaisuuksia rajattomin kuvamäärin - <a href="https://kuvakirjat.piiia.com/tilaa">lue lisää</a>.',

              'Kuvakirjatilaus aloitetaan ostamalla halutun kokoinen kuvakirjapaketti (S-, M- tai L-koko). Custom-koon tilauksissa täytä <a href="https://kuvakirjat.piiia.com/tilaa">erillinen tilauslomakkeemme</a>, jonka pohjalta lähetämme sinulle tarjouksen.',

              "Onnistuneen maksutapahtuman jälkeen, saat sähköpostiisi ohjeet kuvien lähettämistä varten. Kuvat toimitettuasi, käynnistämme kuvakirjasi suunnittelun ja myöhemmin painatettu kuvakirja saapuu postitse luoksesi!",

              'Kerrotaan yhdessä tärkeimmät tarinasi! Tutustu tarkemmin palveluun ja sen ehtoihin osoitteessa: <a href="https://kuvakirjat.piiia.com">https://kuvakirjat.piiia.com</a>.',

              "Tilauksen valmistamisen aloittaminen edellyttää, että toimitat meille onnistuneen maksutapahtuman jälkeen kuvakirjaasi tulevat kuvat. Saat tarkat ohjeet sähköpostiisi.",

              'Onko sinulla tuhansia valokuvia? Ei hätää, tarjoamme myös <a href="https://kuvakirjat.piiia.com/tilaa">valokuvien valikointiapua</a>.'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "Konsultaatiot / Mikä on konsultaatio? Mitä konsultaatio tarkoittaa?:",
            nested_paragraphs: [
              '<b><a href="/fi/product/VN-CNSLTTN-1">KYSY PAKUPROJEKTISTA</a></b>, <b><a href="/fi/product/VN-CNSLTTN-2">KYSY VESIJÄRJESTELMISTÄ</a></b> ja <b><a href="/fi/product/VN-CNSLTTN-3">KYSY AURINKOSÄHKÖJÄRJESTELMISTÄ</a></b> -palvelut ovat henkilökohtaisia, lyhyitä ja nopeita (15 minuutin) konsultaatioita, joissa keskitymme tarpeidesi mukaan valitsemaasi yhteen, tiettyyn asiaan tai kysymykseen konsultaatioiden tuotesivuilla lueteltuihin aihealueisiin liittyen.',
              "Konsultaatio tapahtuu kokonaan sähköpostitse. Voit esittää meille kerrallaan yhden (1) kysymyksen. Toimitamme vastauksemme esitettyyn kysymykseen / keskityttyyn asiaan sähköpostiviestillä, joka lähetetään asiakkaan tilauksessaan ilmoittamaan sähköpostiosoitteeseen.",
              "Jos kysymyksiä toimitetaan kerrallaan enemmän kuin yksi (1), valitsemme täysin oman valintamme mukaisesti niistä yhden (1), johon vastaamme.",
              "Jos koemme, ettemme kykene vastaamaan esitettyyn kysymykseen (esimerkiksi mielestämme riittävällä tavalla tai sen laajuuden vuoksi), meillä on oikeus perua palvelu. Ilmoitamme palvelun peruuttamisesta asiakkaalle sähköpostitse niin pian kuin mahdollista ja palautamme asiakkaalle asiakkaan siihen mennessä peruutettavasta palvelusta maksamat maksut.",
              "Huomioithan, että palvelu on vain informatiiviseen käyttöön tarkoitettu, eikä sitä tai sen kautta saatavia tietoja pidä ottaa ammatillisina neuvoina, ehdotuksina, ohjeina eikä muuna vastaavana. Konsultoi aina ammattilaista ennen mahdollisia toteutuksia."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "E-ostoslistat / Mitä hyötyä tuotelistauksen hankkimisesta minulle olisi?:",
            nested_paragraphs: [
              'Oletko suunnittelemassa <a href="https://pakuprojekti.piiia.com">pakuprojektia</a> - reissupakun, retkipakun tai vanin rakentamista? Aloittamassa matkailuauton tai -vaunun kunnostuksen? Haaveiletko liikkuvasta minikodista?',
              "E-ostoslistojen tarkoitus on auttaa sinua: helpottaa tuotehankinnoissa, säästää aikaasi ja auttaa välttymään virheostoksilta.",
              'Omaa <a href="https://www.instagram.com/pepethevan/">@pepethevan</a>-pakuprojektia tehdessä käytimme loputtoman määrän tunteja parhaiden ja kestävimpien tuotevalintojen tekoon sekä kaikista sopivimpien ostopaikkojen löytämiseen.'
            ]
          },

          {
            title: "E-ostoslistat / Mitä tuotelistaukset siis sisältävät?:",
            nested_paragraphs: [
              '<b>SHOP OUR VAN, - PLUMBING ja - SOLAR</b> -e-ostoslistoihin on koottu <a href="https://www.instagram.com/pepethevan/">@pepethevan</a> - Ford Transit -pakukotimme muutokseen käyttämämme tuotteet ja materiaalit ostopaikkatietoineen.',
              '<b><a href="/fi/product/E-SHPPNGLST-1">SHOP OUR VAN</a> -e-ostoslista</b> sisältää tuotetietoja osioista: <i>Varasto, Eteinen, Kylpyhuone, Toimistot, Oleskelutila, Makuuhuone, Keittiö, Ulkopuoli</i> ja <i>Sisäpuoli</i>. Se kattaa siis esimerkiksi käyttämämme eristys- ja pintamateriaalit sekä sisustustuotteet. <b>Bonuksena</b> SHOP OUR VAN -e-ostoslistan mukaan kuuluu myös pakun layout-pohjaratkaisu (ei tarkkoja mittoja)!',
              'Kaikki vesijärjestelmässämme käytetyt osat löytyvät <b><a href="/fi/product/E-SHPPNGLST-2">SHOP OUR PLUMBING</a> -e-ostoslistasta</b>. Se sisältää tuotetietoja vesijärjestelmämme eri osioista: <i>Laitteet, Tankit, Harmaavesiliittimet</i> ja <i>Puhdasvesiliittimet</i>. <b>Bonuksena</b> SHOP OUR PLUMBING -e-ostoslistan mukaan kuuluu myös vesiasennuksiemme runkona toiminut pakun vesikartta!',
              'Kaikki aurinkosähköjärjestelmämme osat löytyvät <b><a href="/fi/product/E-SHPPNGLST-3">SHOP OUR SOLAR</a> -e-ostoslistasta</b>, joka pitää sisällään tuotetiedot osioista: <i>Laitteet, Työkalut, Sulakkeet, Johdot</i> ja <i>Liittimet</i>. <b>Bonuksena</b> SHOP OUR SOLAR -e-ostoslistan mukana saa aurinkosähköjärjestelmämme pohjana toimineen sähkökartan!',
              'Jos et ole varma missä listassa jokin tietty etsimäsi tuotetieto on, voit tiedustella asiaa sähköpostitse: info@piiia.com <span class="f7 i">(Huomaathan piiia:n kolme i:tä!)</span>.',
              '— <u>Uutuutena</u> olemme lisänneet e-ostoslistojemme joukkoon <b><a href="/fi/product/E-SHPPNGLST-5">SHOP OUR VAN DIMENSIONS</a> -listauksen</b>! Saat listauksesta käyttöösi <a href="https://www.instagram.com/pepethevan/">@pepethevan</a>-pakuun itse rakennettujen osasten tarkat mitat, mahdolliset asennuskorkeudet sekä yleismitat pakumme pohjaratkaisusta. <b>Bonuksena</b> mukaan kuuluu myös pakun layout-pohjaratkaisu!'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Digitaaliset palvelut / Käyttöönotto ja käyttäminen:",
            nested_paragraphs: [
              "Verkkokaupasta ostetut digitaaliset palvelut, kuten e-ostoslistat, toimivat sähköisesti ja onnistuneen maksutapahtuman jälkeen niihin on heti pääsy PIIIAshop-verkkokauppaan sisäänkirjautumalla.",

              "Ensimmäisen oston yhteydessä sinun tulee aktivoida PIIIAshop-käyttäjätilisi. Saat onnistuneen maksusuorituksen jälkeen sähköpostiisi tilausvahvistuksen lisäksi, Aktivoi käyttäjätilisi -viestin. Viestin ohjeita seuraamalla aktivoit PIIIAshop-käyttäjätilisi ja luot sille salasanan. Sen tehtyäsi, pääset kirjautumaan PIIIAshop-verkkokauppaan ja käyttämään ostamiasi digitaalisia palveluita.",

              "Kirjautuminen PIIIAshop:iin tapahtuu yläreunassa näkyvää <i>Kirjaudu</i>-nappulaa käyttäen. Salasana on sinun itse ensimmäisen oston yhteydessä luoma, ja sähköpostiosoite on se, johon viestit PIIIAshop:ilta ovat saapuneet.",

              "Kirjauduttuasi onnistuneesti verkkokauppaan sisään, näet ostamasi digitaaliset palvelut, kuten e-ostoslistat, <i>Ostot</i>-osiossa - ja pääset käyttämään niitä sieltä, tai kunkin digitaalisen palvelun omalta tuotesivulta <i>Avaa</i>-nappulaa klikkaamalla.",

              "Pääset palaamaan ostamiesi digitaalisten palveluiden pariin kun haluat, aina samalla tavalla kirjautumalla PIIIAshop-verkkokauppaan sisään.",

              "Ostaessasi myöhemmin lisää digitaalisia palveluita samaa sähköpostiosoitetta käyttäen, uudet ostot näkyvät suoraan samassa <i>Ostot</i>-osiossa ja ovat heti käytettävissä ilman tunnuksen aktivointivaihetta.",

              "Voit kirjautua ulos verkkokaupasta <i>Kirjaudu ulos</i> -nappulaa käyttäen, jonka näet navigaatiopalkissa ollessasi sisäänkirjautuneena.",

              "Kuulostiko ohje hankalalta - voit katsoa ohjeen e-ostoslistojen käyttöönotosta myös videomuodossa alta!"
            ]
          },

          {
            title: "Video-ohje e-ostoslistojen käyttöönotosta:",
            nested_paragraphs: [
              '<div class="w-100"><iframe src="https://www.youtube.com/embed/pMZH7HZq5xU" frameborder="0" allowfullscreen></iframe></div>'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Rekisteröityminen PIIIAshop-verkkokauppaan:",
            nested_paragraphs: [
              "Digitaaliset palvelut edellyttävät rekisteröitymistä PIIIAshop-verkkokauppaan.",

              "Ensimmäisen digitaalisen palvelun oston yhteydessä asiakkaan tulee aktivoida PIIIAshop-käyttäjätili. Asiakas saa onnistuneen maksusuorituksen jälkeen sähköpostiinsa tilausvahvistuksen lisäksi, <i>Aktivoi käyttäjätilisi</i> -viestin. Viestin ohjeita seuraamalla aktivoidaan PIIIAshop-käyttäjätili ja luodaan sille salasana.",

              "Käyttäjän on rekisteröitymisen yhteydessä annettava kaikki pakolliseksi merkityt tiedot. Rekisteröityminen edellyttää toimivaa sähköpostiosoitetta, sillä rekisteröinnin loppuun saattaminen edellyttää käyttäjän sähköpostiin lähetettävän vahvistusviestin käsittelyä.",

              "Käyttäjä luo itselleen rekisteröitymisen yhteydessä käyttäjätunnuksen (= tilauksessa käytetty sähköpostiosoite) ja salasanan, jotka käyttäjä sitoutuu pitämään salassa ja olemaan luovuttamatta kolmansien käyttöön. Käyttäjäoikeus on henkilökohtainen, eikä sitä voi luovuttaa edelleen. Käyttäjä on vastuussa kaikista tunnuksillaan tehdyistä toimenpiteistä. Käyttäjä sitoutuu ilmoittamaan Yritykselle välittömästi, mikäli epäilee tunnustensa paljastuneen kolmansille henkilöille.",

              "Yritys pidättää oikeuden sulkea käyttöoikeus milloin tahansa, mikäli Yritys epäilee tunnuksia käytettävän väärin."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Kirjautumisongelmat:",
            nested_paragraphs: [
              "Jos kohtaat PIIIAshop-verkkokauppaan sisäänkirjautumisongelmia - tarkistathan, että:",
              "<ul><li>Sähköpostiosoitteesi on sama, oston yhteydessä (jos maksutapana PayPal, niin silloin PayPal-tunnuksessa) käyttämäsi sähköpostiosoite</li><li>Käytät salasanana aiemmin sähköpostilinkin kautta luomaasi PIIIAshop-salasanaasi</li></ul>",
              "Unohditko luomasi PIIIAshop-salasanan? Toimi seuraavasti:",
              "<ul><li>Klikkaa yläreunan <i>Kirjaudu</i>-nappulaa ja avautuvan sivun kirjautumisosion yhteydestä <i>Unohditko salasanasi?</i> -linkkiä.</li><li>Sen jälkeen, syötä ostosi yhteydessä (jos maksutapana PayPal, niin silloin PayPal-tunnuksessa) käyttämäsi sähköpostiosoite - ja saat sähköpostiisi verkkokaupaltamme viestin, jossa on uusi salasanan luontilinkki.</li><li>Uuden salasanan luotuasi, pääset kirjautumaan sillä heti PIIIAshop:iin.</li></ul>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Miten PIIIAshop-verkkokauppa sai alkunsa?:",
            nested_paragraphs: [
              'PIIIAshop on saanut alkunsa keväällä 2018 aloittamamme <a href="https://pakuprojekti.piiia.com/">PAKUPROJEKTI</a>:n myötä. Toteutimme #pakuprojekti\'mme myötä pitkäaikaisen haaveemme - rakensimme Ford Transit -pakettiautosta itsellemme liikkuvan kodin. Projektin lopputulos, Pepe-paku on mm. aurinkoenergialla varustettu ja aivan kuin pieni, liikkuva koti! 🚐💕',

              "Rakensimme pakun omin käsin, ilman aiempaa rakennuskokemusta. Teimme projektin materiaali- ja tuotevalinnat harkiten. Paku täytti kaikki odotuksemme, ja Pepestä tuli ihana, tunnelmallinen ja täysin uniikki kokonaisuus!",

              "Pakun rakennusprojektin jälkeen olemme saaneet viikoittain todella paljon kysymyksiä siitä mistä JA minkä merkkisiä tietyt pakuun hankitut tuotteet ja materiaalit olivatkaan. Halusimme löytää tavan ehtiä ja pystyä vastamaan kaikille, jakaen samalla eteenpäin sekä projektissa oppimaamme että sen myötä meille kertynyttä tietotaitoa.",

              "Ryhdyimmekin sitten heti uuteen projektiin — ja rakensimme tyhjästä PIIIAshop-verkkokaupan (kyllä, pakusta käsin!).",

              "Nyt omaa pakuprojektiasi auttavat ja sitä toivottavasti helpottavat tuotelistaukset ovat ostettavissa PIIIAshop-verkkokaupasta! Ostettuasi tuotelistauksen, se avautuu heti käyttöösi!",

              "Löydät SHOP OUR VAN, SHOP OUR PLUMBING, SHOP OUR SOLAR ja SHOP OUR VAN DIMENSIONS -tuotelistauksista suoraan vastaukset kysymyksiisi. Bonuksena saat mukana joko pakun layout-pohjaratkaisun, vesikartan tai sähkökartan tuotelistauksesta riippuen!"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Mikä on PIIIA.com?:",
            nested_paragraphs: [
              '<a href="https://piiia.com/">PIIIA.com</a> on nuoren yrittäjän vuonna 2017 perustama media — paikka jakaa innostusta ja rakkautta road trippailuun sekä elämänmakuisiin seikkailuihin. Piia Niemi on 29-vuotias kulttuurituottaja (AMK) ja luovan alan yrittäjä. Voit tutustua alta lisää:',
              '<ul><li><a href="https://piiia.com/vlog">PIIIA.com</a> - Oodi road tripeille ja automatkailulle — kaikille oman elämänsä tutkimusretkeilijöille. Lähimatkailulle ja mikroseikkailuille ✨🌙</li><li><a href="https://www.youtube.com/PIIIA">PIIIA</a> - YouTube-kanava van life -pakuelämästä & automatkailusta</li><li><a href="https://pakuprojekti.piiia.com">PAKUPROJEKTI</a> - keväällä 2018 aloittamamme pakettiauton rakennus- ja muutosprojekti</li><li><a href="https://www.instagram.com/pepethevan/">@pepethevan</a>-paku - oma #pakuprojekti \'mme, itse rakentamamme liikkuva minikoti</li><li>@piiiacom-tilit <a href="https://www.instagram.com/piiiacom/">Instagramissa</a>, <a href="https://www.facebook.com/piiiacom/">Facebookissa</a> & <a href="https://twitter.com/piiiacom">Twitterissä</a></li></ul>',

              'PIIIA.com on osa yritystä <a href="https://pepeunlimited.com/fi/">Pepe Unlimited</a> ja tarjoaa yrityksenä laajan skaalan erilaisia luovia palveluita:',
              '<ul><li>Sosiaalisen median konsultointi</li><li>Sisällöntuotanto (kuvat, videot, tekstit, grafiikat)</li><li>Sosiaalisen median managerointi</li><li>Yritysilmeet & Brändäys</li><li>Graafinen suunnittelu</li><li>Nettisivut</li><li>Videoeditointi</li><li>Sovelluskehitys</li><li>Yksilölliset kuvakirjat -> <a href="https://kuvakirjat.piiia.com">piiiabooks</a></li></ul>',
              "Pystymme olemaan apunasi varsin laajasti, monissa erilaisissa projekteissa! Kuulemme mielellään sinusta!",
              'Meihin voi olla työasioissa yhteydessä täyttämällä tämän <a href="https://pepeunlimited.com">yhteydenottolomakkeen</a> tai lähettämällä meille sähköpostia osoitteeseen <a href="mailto:info@pepeunlimited.com">info@pepeunlimited.com</a>.'
            ]
          }
        ]
      }
    ]
  },
  order_status: {
    approved: "Maksettu",
    dispatched: "Toimitettu",
    invoice_created: "Pankkisiirto tehty"
  },
  sign_in: {
    /* => SignIn.vue */
    title: "Kirjautuminen",
    subtitle: "Kirjaudu sisään PIIIAshop-verkkokauppaan",
    sign_in: "Kirjaudu",
    sign_out: "Kirjaudu ulos",
    password: "Salasana",
    forgot: "Unohditko salasanasi?",
    email: "Sähköpostiosoite",
    note:
      "• Pääset nyt kirjautumaan juuri luomallasi salasanalla PIIIAshop-verkkokauppaan ja ostamiisi digitaalisiin palveluihin, kuten e-ostoslistoihin."
  },
  error: {
    terms: "Ehdot hyväksyttävä"
  },
  cart: {
    /* => CartTotal.vue */
    shipping_cost: "+ toimituskulut:",
    total_amount: "Yhteensä:",
    vat: "(sis. alv {vat} % • {taxes_with_shipping} {currency})"
  },
  payment_method: {
    method: "Maksutapavaihtoehdot:",
    bank_transfer: "Pankkisiirto",
    pay: "Maksa suoraan tililtäsi"
  },
  purchase: {
    /* => Purchase.vue */
    status: "Tila:"
  }
};
