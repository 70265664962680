import axios from "axios";

const domain = process.env.VUE_APP_SHOPPIIIACOM_SERVER;
const baseURL = `${domain}/`;

export function createAxios() {
  return axios.create({
    baseURL: baseURL,
    timeout: 15000,
    headers: { "Content-Type": "application/json" }
  });
}
