import { RepoFactory } from "../../api/repofactory";
import order from "./order";
const orderpayment = RepoFactory.get("orderpayment");

// initial state
const state = {
  cart: [],
  loading: false,
  error: null,
  status: null,
  payment: null
};

// getters
const getters = {
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  prices: (state, getters, rootState) => {
    return rootState.pricing.prices;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  status: state => {
    return state.status;
  },
  payment: state => {
    return state.payment;
  }
};

// actions
const actions = {
  onPayment({ commit, state, getters }, { button }) {
    try {
      commit("setError", null);
      // convert the calculated amounts for the paypal
      const prices = getters.prices;
      commit("setPayPalCart", prices);
      commit("setStatus", "onPayment");
      return button.create(state.cart);
    } catch (error) {
      return new Promise(function(resolve, reject) {
        return reject(error);
      });
    }
  },
  onAuthorize({ commit, dispatch, getters }, { button, router }) {
    return button.execute().then(payment => {
      try {
        commit("setPayment", payment);
        commit("setStatus", "onAuthorize");
        dispatch(
          "user/setUserFromPayPal",
          { user: payment.payer.payer_info },
          { root: true }
        );
        router.push({ name: "order/save" });
      } catch (error) {
        return new Promise(function(resolve, reject) {
          return reject(error);
        });
      }
    });
  },
  // from PayPalButton
  onCancel({ commit }, { cancel }) {
    // Do something when user has cancled PayPal flow
    commit("setStatus", "onCancel");
  },
  reset({ commit }) {
    commit("reset");
  },
  // from PayPalButton
  onError({ commit }, { error, router }) {
    commit("setError", error);
    commit("setStatus", "onError");
    router.push({ name: "order/error" });
  },
  async created({ commit }) {
    commit("setError", null);
    commit("setLoading", false);
    commit("setStatus", null);
  }
};

// mutations
const mutations = {
  setStatus(state, status) {
    state.status = status;
  },
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPayment(state, payment) {
    state.payment = payment;
  },
  reset(state) {
    state.status = null;
    state.payment = null;
    state.cart = [];
  },
  setPayPalCart(state, prices) {
    // https://developer.paypal.com/docs/api/payments/v1/
    var transactions = {
      transactions: [
        {
          amount: {
            total: prices.amount.total,
            currency: prices.currency
            /*  details: {
              subtotal: prices.amount.sub_total_without_vat,
              tax: prices.amount.sub_total_taxes,
              shipping: prices.shipment.with_vat
            }*/
          },
          item_list: {
            items: []
          }
        }
      ]
    };
    /*var txs = transactions.transactions[0];
    prices.products.forEach(product => {
      var price
      if (product.is_discounted === 'true') {
        price = product.discount_without_vat
      } else {
        price = product.price_without_vat
      }
      txs.item_list.items.push({
        name: product.name,
        quantity: product.quantity,
        price: price,
        sku: product.sku,
        tax: product.taxes,
        currency: prices.currency
      });
    });*/
    state.cart = transactions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
