import { RepoFactory } from "../../api/repofactory";
const shoppinglist = RepoFactory.get("shoppinglist");
const orderpayment = RepoFactory.get("orderpayment");
import { is401 } from "../../util/error.js";
import { AUTHORIZATION } from "./cookie.type";

// initial state
const state = {
  loading: false,
  error: null,
  categories: [],
  sections: [],
  product: null,
  maxItems: 20,
  show: null,
  categoryId: 2 // note: starts from index 2
};

// getters
const getters = {
  categoryId(state) {
    return state.categoryId;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  product: state => {
    return state.product;
  },
  categories: state => {
    return state.categories;
  }
};

// actions
const actions = {
  async loadShoppingList(
    { commit, dispatch, getters },
    { sku, cookie, language }
  ) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      commit("clear");

      const resp0 = await orderpayment.getProduct(
        sku,
        0,
        language,
        getters.currency,
        getters.residentCode
      );

      const token = cookie.get(AUTHORIZATION);

      const resp1 = await shoppinglist.categories(sku, token, language);
      commit("setProduct", resp0.data.product);
      if (resp1.data == null) {
        commit("setCategories", []);
      } else {
        commit("setCategories", resp1.data);
      }
      commit("setLoading", false);
      dispatch("loadSections", {
        token: cookie.get(AUTHORIZATION),
        language: language,
        cookie: cookie
      });
    } catch (error) {
      is401(error, dispatch, cookie);
      commit("setError", error);
    }
  },
  async toCategory({ commit, dispatch }, { cookie, language }) {
    try {
      commit("setSections", []);
      dispatch("loadSections", {
        token: cookie.get(AUTHORIZATION),
        language: language,
        cookie: cookie
      });
    } catch (error) {
      is401(error, dispatch, cookie);
      commit("setError", error);
    }
  },
  async loadSections(
    { commit, rootState, dispatch },
    { token, language, cookie }
  ) {
    try {
      commit("setError", null);
      const sku = rootState.shoppinglist.product.sku;
      const categoryId = rootState.shoppinglist.categoryId;
      const maxItems = rootState.shoppinglist.maxItems;

      var pageToken = 0;
      var isNotEmpty = true;
      while (isNotEmpty) {
        // paginate until found empty response
        const sr = await shoppinglist.sections(
          sku,
          categoryId,
          pageToken,
          maxItems,
          token,
          language
        );
        if (!Array.isArray(sr.data.sections) || !sr.data.sections.length) {
          // array is empty or not array
          isNotEmpty = false;
        } else {
          commit("push", sr.data.sections);
          pageToken = sr.data.next_page_token;
        }
      }
    } catch (error) {
      is401(error, dispatch, cookie);
      commit("setError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    if (error == null) {
      state.error = error;
    } else if (error.response) {
      const resp = error.response.data;
      if (resp.hasOwnProperty("err")) {
        state.error = resp.err;
      } else {
        state.error = error;
      }
    }
  },
  clear(state) {
    state.categories = [];
    state.sections = [];
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setProduct(state, product) {
    state.product = product;
  },
  setSections(state, sections) {
    state.sections = sections;
  },
  push(state, sections) {
    state.sections.push(...sections);
  },
  setCategoryId(state, categoryId) {
    state.categoryId = categoryId;
  },
  setShow(state, show) {
    state.show = show;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
