import { RepoFactory } from "../../api/repofactory";
const client = RepoFactory.get("image");
import Vue from "vue";
// initial state
const state = {
  error: null,
  loading: false,
  images: {
    /* ImageId:Number => {src} */
  },
  shopOurImages: {
    /* ImageId:Number => {src} */
  }
};

// getters
const getters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  images(state) {
    return state.images;
  },
  search(state) {
    return keyword =>
      state.items.filter(item => {
        return item.name === keyword;
      });
  },
  //https://github.com/vuejs/vuex/issues/688
  imageIdsBySku(state) {
    return sku =>
      Object.keys(state.images).filter(key => {
        return state.images[key].sku == sku;
      });
  },
  shopOurImages: state => imageId => state.shopOurImages[imageId]
};

// actions
const actions = {
  async loadShopOurImg({ commit, getters }, { imageId }) {
    try {
      commit("setError", null);

      if (getters.shopOurImages(imageId) == null) {
        const resp0 = await client.image(imageId);
        const source = resp0.data.source;
        const mimeType = resp0.data.mime_type;
        commit("addShopOurImage", {
          src: "data:" + mimeType + ";base64," + source,
          id: imageId
        });
      }
    } catch (error) {
      commit("setError", error);
    }
  },
  async loadImages(
    { commit, getters },
    { productId, sku, pageSize, pageToken }
  ) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const resp0 = await client.imageIds(productId, sku, pageSize, pageToken);
      const ids = resp0.data.image_ids;
      if (!Array.isArray(ids) || !ids.length) {
        /* for some reason is forced to delete not existing images.. */
      } else {
        for (let imageId of ids) {
          if (getters.images[imageId] == null) {
            const resp1 = await client.image(imageId);
            const source = resp1.data.source;
            const mimeType = resp1.data.mime_type;
            const productId = resp1.data.product_id;
            const sku = resp1.data.sku;
            commit("addImage", {
              src: "data:" + mimeType + ";base64," + source,
              id: imageId,
              sku: sku,
              productId: productId
            });
          }
        }
      }
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setImageId(state, imageId) {
    state.imageIds.push(imageId);
  },
  addImage(state, image) {
    Vue.set(state.images, image.id, image);
  },
  addShopOurImage(state, image) {
    Vue.set(state.shopOurImages, image.id, image);
  },
  deleteImage(state, sku) {
    Vue.delete(state.images, sku);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
