// READ MORE: https://kazupon.github.io/vue-i18n/guide/formatting.html

export default {
  shopping_list: {
    attribute: {
      type: "Type:",
      used_for: "Used for:",
      piece: "Piece:",
      unit: "Unit:",
      name: "Name:",
      model: "Model:",
      color: "Colour:",
      size: "Size:",
      thickness: "Thickness:",
      angle: "Angle:",
      diameter: "Diameter:",
      voltage: "Voltage:",
      package_size: "Package size:",
      manufacturer: "Manufacturer:",
      place_of_purchase: "Place of purchase:",
      item_number: "Item number:",
      hyperlink: "Link"
    },
    open: "Open"
  },
  reset_password: {
    password: "Password:",
    re_enter_password: "Re-enter password:",
    activate: "Activate",
    reset_title: "Forgot password?",
    reset_article:
      "Type in your email address used at PIIIAshop -web shop below. We'll send you an email with instructions on how to create a new password.",
    email: "Email address",
    submit: "Reset password",
    reset_success_title: "Reset your password",
    reset_success_article:
      "Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.",
    return_to_sign_in: "Return to sign in",
    change_password_title: "Change password",
    change_password_article: "Enter a new password below.",
    button: "Save changes"
  },

  cookies: {
    text:
      "We use cookies. By continuing to use our website, we'll assume you're agreeing to our use of cookies. To find out more see our <a href=\"/en/privacy/\">Privacy Policy</a>.",
    accept: "Accept"
  },

  globe_view: {
    country_title: "You're browsing from {country}",
    country_text: "Change location:",
    currency_text: "Currency:",
    confirm: "Confirm"
  },

  order: {
    error_title: "Error",
    confirm_title: "Confirm your order information",
    confirm_article:
      "Please check and confirm that your order information is correct. After you have confirmed your information, you will receive an order confirmation to your email. Please don't refresh or leave this page without confirm or your order won't be completed.",
    activation_title: "Activate your account",
    activation_article:
      "In order to activate and use your PIIIAshop account, please create password for your PIIIAshop account.",
    success_title: "Thanks!",
    success_article:
      "Thank you for your order <b>{order_hash}</b>! We have now received your order. You will receive an order confirmation email shortly (if you selected bank transfer as your payment method, please make a payment based on the payment details you received first). <br/><br/> <b>For orders delivered by post</b>, such as stickers, keychains, custom illustrated products and bespoke photo books, we will send you a delivery confirmation when the delivery is on its way. <br/><br/> <b>For orders delivered in digital format</b>, such as consultations and gift cards, the order will be delivered by a separate email. <br/><br/> <b>Digital services</b>, such as e-shopping lists, work electronically and can be accessed by signing in to the PIIIAshop -web shop. When making your first purchase, you must activate your PIIIAshop user account. Follow the instructions you received in the email. <br/><br/> Thank you for shopping with us! We hope you enjoy your purchase! ♥",
    success_continue: "Go back to home page",
    purchased_products_title: "My orders:",
    purchased_products_article:
      "Below you can see your purchases from PIIIAshop -web shop and access the digital services you have purchased, such as e-shopping lists.",
    first_name: "First name:",
    last_name: "Last name:",
    line1: "Address:",
    line2: "",
    postal_code: "Postal code:",
    city: "City:",
    country: "Country:",
    country_select: "Select country",
    phone_number: "Phone number:",
    email: "Email address:",
    confirm: "Confirm"
  },
  navigation: {
    /* navigation => Header.vue */
    products: "Products",
    faq: "FAQ",
    hireUs: "hire us",
    pakuprojekti: "pakuprojekti",
    myOrders: "orders"
  },
  footer: {
    /* footer => Footer.vue */
    terms: "terms",
    privacy: "privacy",
    share1: "Share your van conversion photos with us by using the hashtag",
    share2: "#pakuprojekti",
    share3: "♥"
  },
  signInButton: {
    /* sign-in-button => SignInButton.vue */
    signIn: "sign in",
    signOut: "sign out"
  },
  product: {
    /* => ProductView.vue */
    country: "Country:",
    not_purchasable: "This item is not available in {country}",
    buy_now: "Buy now",
    terms1: "By purchasing I agree to",
    terms2: "Terms & Conditions",
    terms3: "of PIIIAshop",
    newsletter: "Subscribe to our mailing list",
    sign_in1: "Have you already purchased this e-shopping list?",
    sign_in2: "->",
    sign_in3: "Sign in",
    type_digital: "-digital product",
    type_sticker: "-product",
    type_shopping_list: "-e-shopping list",
    find_out_more: "Find out more ->"
  },

  privacy: {
    title: "Privacy Policy",
    subtitle: "Privacy Policy and Data Protection Policy & Cookies",
    created_at: "Updated on 25th March 2021",
    paragraph:
      "This is PIIIAshop -web shop's description of the data file and privacy policy as set out in the Finnish Personal Data Act (Sections 10 and 24) and the EU's General Data Protection Regulation (GDPR).  Please read this Privacy Policy carefully in advance before starting the use of service. In order to use our Service, the users must accept the practices described in this Privacy Policy. This Privacy Policy and Data Protection Policy often refers to 'customers' or 'users'. Customer or user means any individual using our websites, our customers, people who use or subscribe our free products or services and those individuals, companies and corporations who participate in different raffles and contests.",

    article: [
      {
        paragraphs: [
          {
            title: "Controller:",
            nested_paragraphs: [
              "PIIIA.com (2950462-4), Mannarintie 62, 45370 Valkeala, Finland"
            ]
          },

          {
            title: "Authorised representative:",
            nested_paragraphs: ["Data Protection Officer, info@piiia.com"]
          },

          {
            title: "Name of register:",
            nested_paragraphs: ["PIIIAshop's customer register"]
          },

          {
            title: "Related sites:",
            nested_paragraphs: ["https://shop.piiia.com"]
          },

          {
            title:
              "Purposes and legitimate grounds for the processing of personal data:",
            nested_paragraphs: [
              "Our legal grounds for processing personal data are:",
              "<ul><li>to perform our contractual obligations towards our customers (f.ex. to process the orders);</li> <li>with the consent of the individuals concerned;</li> <li>to pursue our legitimate interests; and/or</li> <li>to comply with legal obligations.</li></ul>",
              "The purposes of the processing of personal data by us are:",
              "<ul><li>customer relationship management and development;</li> <li>implementation of services;</li> <li>development of customer service, activity and websites;</li> <li>implementation of opinion polls and market research;</li> <li>after sales marketing using Facebook, Google and email marketing tools;</li> <li>marketing of products and services;</li> <li>data statistics and analysis; and/or</li> <li>providing of business partner and affiliate advertising.</li></ul>",
              "To enable us to administer and fulfil our obligations under law, we process the data we have. This includes data processed for complying with our bookkeeping obligations and providing information to relevant authorities.",
              "We may process the data for the prevention of fraud, misuse of our Services and terms, and for data, system and network security.",
              "The users joined the email list accept that the company may send newsletters or other direct marketing material by email.",
              "The users accept that our websites, social media pages and our other communication, such as newsletters, may include commercial content or advertising."
            ]
          },

          {
            title: "Personal data processed:",
            nested_paragraphs: [
              "We may collect and process the following types of personal data: User-Provided Data, Automatically Collected Information and Information from Other Sources.",
              "User-Provided Data:",
              "<ul><li>First name;</li> <li>Last name;</li> <li>Email address;</li> <li>Postal address, Postal code, Postal district and Country;</li> <li>Phone number;</li> <li>Date of birth;</li> <li>Gender;</li> <li>Social media profiles;</li> <li>Other personal data users provide themselves;</li> <li>Order information, such as Payment information, Order information, Changes of orders and Payment history;</li> <li>Business customers personal - and/or business data (Business ID or VAT number, Email address, Postal address, Postal code, Postal district and Country);</li> <li>Subscribing to a newsletter;</li> <li>Permits and consents;</li> <li>Registration information: Email address and Password; and/or</li> <li>Information that users provide when communicating with us: Contacts, Emails, Forms, Meetings, Feedback and ratings, Comments and Raffle and contest answers.</li></ul>",
              "We aim to inform which information is necessary for the use of our Service and which information is additional that you choose to give us. Without necessary personal information, we may not be able to provide you with all the requested services.",
              "Automatically Collected Information:",
              "<ul><li>Usage data, for example Clicked links, Websites and pages visited, and Timing of activities;</li> <li>Information relating to the technical device used, such as Device and device information, Country, IP address, Browser type and version and Operating system; and/or</li> <li>Other cookie information (you can learn more about our Cookies Policy below).</li></ul>",
              "We may also process personal data provided by third parties or that is available from public sources.",
              "Information from Other Sources:",
              "<ul><li>Information from our Technical partners, service providers and partners, who assist us to provide our service and features;</li> <li>Information from Advertisers or other Ad partners; and/or</li> <li>Information shared publicly, like on social media platforms and services and the information about the use of our own content on social media.</li></ul>",

              "We will process following categories of personal data: name, email address, billing address, delivery address, phone number, IP address, payment history and order information. If you have an PIIIAshop-account, we will also process your personal data submitted in relation to the account, such as account and its password.",
              "Information includes also the permission if we could send you emails or not."
            ]
          },

          {
            title: "Sources of data:",
            nested_paragraphs: [
              "The personal data is received from the individual, for example, in connection with registration, in contracts, use of services, sent through the websites, by email, by phone, through social media services, meetings and other situations where the individual discloses their information, and in connection with raffles, contests and campaigns."
            ]
          },

          {
            title: "Information Sharing and Disclosure:",
            nested_paragraphs: [
              "We may share personal data outside of our company in the following situations:",
              "<ul><li>To the general public;</li> <li>At the user’s request or consent;</li> <li>To service providers and partners;</li> <li>With advertisers or other ad partners;</li> <li>For legal reasons and To comply with the law; and/or</li> <li>To purchasers of our business.</li></ul>",
              "To the general public: Publicly available information can be seen by anyone, on or off our Services. This includes any information you share with a public audience and content you share on our Services. Questions or comments from users submitted through public forums such as our social media pages may be also shared publicly.",
              "At the user’s request or consent: Users' information can be shared at the user’s request or consent for example if a user requests a service through a promotional offering made by a Third party, we may share certain data with those third parties.",
              "To service providers and partners: We may use third party service providers (such as IT service -, payment processing service -, printing service - and delivery service providers) to enable us to provide our Services, to improve our Services and to administer related activities on our behalf. We may share your information with these third parties only for those limited purposes. The third parties we use, have their own privacy policies. Please read their privacy policies on their websites.",
              "All our payments are securely processed through PayPal. We may get your personal information from PayPal to fulfil your order and delivering it to you. We recommend that you read their privacy policy (https://www.paypal.com/webapps/mpp/ua/privacy-full) to understand how they handle privacy policies. We share your personal information also with distribution suppliers (Posti / Finnish Postal Service or Matkahuolto) in connection with the delivery of your order.",
              "We also use third-party tools such as Google Analytics. In such cases, applicable third party terms and conditions and privacy settings.",
              "With advertisers or other ad partners: We provide advertisers with reports about the kinds of people seeing their ads and how their ads are performing, but we don’t share information that personally identifies you unless you give us permission.",
              "For legal reasons and To comply with the law: We share information with law enforcement or in response to legal requests in that kind of circumstances.",
              "To purchasers of our business: Your information can be shared to purchasers of our business. We will share your personal data in those cases where we sell or negotiate to sell our business to a buyer or prospective buyer. In this situation, we will continue to ensure the confidentiality of your personal data and give you notice before your personal data is transferred to the buyer or becomes subject to a different privacy policy."
            ]
          },

          {
            title: "Transfer to countries outside Europe:",
            nested_paragraphs: [
              "We store your personal data primarily within the European Union and the European Economic Area. However, we have service providers and operations in several geographical locations. As such, we and our service providers may transfer your personal data to, or access it in, jurisdictions outside the European Economic Area or the User’s domicile. We ensure that possible transfers if the Users’ personal data receive an adequate level of protection."
            ]
          },

          {
            title: "Data retention:",
            nested_paragraphs: [
              "We don’t store your personal data longer than is legally permitted and necessary for the purposes of providing our Services or the relevant parts thereof. We may retain certain information for legitimate business purposes or as required by law. Once the data is no longer necessary, we delete or anonymise it as soon as reasonably possible.",
              "Image files and all the other materials customer provide to us to fulfil orders will be removed from our system within 45 days of order delivery. In some cases, such as illustrated custom products, we save digital copies of the orders for a period of 24 months. This is in order to be able to offer customer to order more copies of the same product.",
              "If the order has been uncompleted (for example if the order is unpaid) for 6 months, we reserve the right to permanently delete all the materials provided, all the unfinished digital products and/or all the products that have been waiting delivery."
            ]
          },

          {
            title: "Information security:",
            nested_paragraphs: [
              "We use reasonable administrative, technical and physical security measures to help safeguard your information. To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is protected from unauthorised, alteration, disclosure, or destruction of information we hold."
            ]
          },

          {
            title: "Right to access & Right to correct:",
            nested_paragraphs: [
              "Every person that has provided their information has the right to review the data concerning them and to demand the rectification of potentially erroneous information or completion of incomplete information. If an individual wishes to review the data concerning them or demand rectification, the request must be sent, in writing (by email), to the controller of the registry. If required, the data controller may ask the requester to provide proof of their identity. The data controller will respond to the customer within the timeframe set in the GDPR (generally within one month)."
            ]
          },

          {
            title: "Other privacy rights:",
            nested_paragraphs: [
              "Data subjects may also ask us to delete the users's personal data from our systems (”The right to the erasure of their data and to be forgotten”). According to the General Data Protection Regulation (GDPR), data subjects have also the right to object, the right to restrict the processing and the right to data portability.",
              "These rights may be used by sending a letter or an e-mail to us on the addresses set out above. We may request the provision of additional information necessary to confirm the identity of the data subject. The data controller will respond to the customer within the timeframe set in the GDPR (generally within one month).",
              "The Data Controller may refuse to realise the request on the basis of grounds set out in applicable legislation. Such as erasing all the information, if we have legal obligation or right to preserve.",
              "The user has the right to prohibit us from using the User’s personal data for direct marketing by contacting us at info@piiia.com."
            ]
          },

          {
            title: "Links to other websites:",
            nested_paragraphs: [
              "PIIIAshop -web shop, our websites, contents, products and services (free ones as well), social media communication and our newsletters may contain links to third-party sites and/or third-party advertising. We cannot control or be held responsible for third parties’ privacy practices and content. The Company is not responsible for the provision of third-party products, services, or material, data or other content generated by third-party.",
              "If the user moves to a linked site, they must understand they are leaving our Services and they must familiarise themselves with the other sites privacy principles, cookies policy and privacy policies to find out how they collect and process their personal data by themselves. Our websites may contain embedded content (such as videos) from other sites, and opening or using of those is comparable to users visiting third-party sites by themselves."
            ]
          },

          {
            title: "Disclosure:",
            nested_paragraphs: [
              "We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service."
            ]
          },

          {
            title: "Age of Consent:",
            nested_paragraphs: [
              "Our Services are not directed to minors. By using our site, You must be at least 18 years of age or older to use our Sites and Services. Minors may only use our services with the involvement, supervision, and approval of a parent or legal guardian.",
              "We do not knowingly collect personal data from minors or under the applicable age limit. If you are under the Age Limit, please do not provide any personal data to us."
            ]
          },

          {
            title: "Changes to Our Privacy Policy:",
            nested_paragraphs: [
              "We reserve the right to modify this privacy policy at any time, so please review it frequently. The most current version of the policy will govern our processing of your personal data and will always be at our website. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Cookies:",
            nested_paragraphs: [
              "We use various technologies to collect and store information when the users visit our Services, including cookies. Those allow us to provide our products and services, to enchance user experience, to analyse the use of our services to improve our Services and to target ads. Those can for example be functional and service cookies, analysis cookies and marketing targeting cookies.",
              "The third parties we use, might have their own cookies or other tracking technologies to deliver to your device. We recommend that you read their privacy policies to understand how they handle privacy policies. You can find those on their websites. We use third party services such as Google Analytics (https://support.google.com/analytics/answer/6004245), Google Ads (https://policies.google.com/privacy) and Facebook (https://facebook.com/about/privacy).",
              "Our Services may also contain third-party links that deliver third-party cookies or other tracking technologies to your device for their own needs. The user of the site has to personally approve every site’s cookies. In case the user doesn't approve, they have to stop using the site in question immediately.",
              "You can refuse cookies, but please note that then some parts of our Services may not function properly or it could affect the availability of our Services."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Advertising on our Services:",
            nested_paragraphs: [
              "Advertising - / Affiliate links:",
              "The content of our websites and digital products may contain so-called advertising - / affiliate links, through which the Company may receive commissions or other benefits.",
              "Advertising - / Affiliate links are clearly marked with a * in front of the link and/or the words '*Includes an advertising - / affiliate link' ['*Sisältää mainos- / affiliate-linkin'] or 'Commercial collaboration: X' ['Kaupallinen yhteistyö: X'] in connection with the relevant content.",
              "The content of our websites, our social media channels or our newsletters may recommend products, items and services, as well as offer benefits or discounts in cooperation with selected partners.",
              "Commercial cooperation & Other benefits received:",
              "The Company makes commercial cooperations and enters into partnerships that suit its values, channel themes and audiences.",
              "Commercial cooperation means the content that the Company produces based on an agreement with a advertiser. Company receives compensation in the form of money or a cash-equivalent benefit. Cases where content production has been agreed between the Company and another party without a fee or an equivalent economic benefit are also interpreted as Commercial cooperation. The content to be produced can be a blog post, an article, an Instagram post, a YouTube video, or the like, where information can be provided, experiences shared, a product, destination or service evaluated, or the like. Benefits or discounts may also be offered to the followers as part of commercial cooperation.",
              "Other benefits received can be for example product samples, sponsored goods and services, invitations, press trips and commissions from affiliate links. The difference with Commercial cooperation is that no content production has been agreed between the Company and another party. The Company has received the benefits without obligation. However, if the Company voluntarily produces content from these benefits into its channels, the benefits obtained must be disclosed.",
              "We label openly about Commercial cooperation and Other benefits we receive in all channels to which we produce content related to the cooperation or benefit, in connection with the relevant content. We clearly mark Commercial collaborations at the beginning of the content using wording 'Commercial collaboration' ['Kaupallinen yhteistyö'] and the name of the another party responsible for the marketing.",
              "We clearly state the Other benefits we have received, for example by using the words '*Product/Service gifted/sponsored' ['*Tuote/Palvelu saatu/sponsoroitu'] or '*Includes an advertising - / affiliate link' ['*Sisältää mainos-/affiliatelinkin'].",
              "Our websites, content, products and services (including free ones), social media communications and newsletters may recommend or promote products, items and services, even without Commercial cooperation or Other benefits received.",
              "Third-party Advertising:",
              "Third-party advertising may also appear on the Services. The Company is not responsible for the provision of third-party products, services, or material, data or other content generated by third-party."
            ]
          }
        ]
      }
    ]
  },

  terms: {
    /* => Terms.vue */
    title: "Terms",
    subtitle: "Terms and Conditions of PIIIAshop -web shop",
    created_at:
      "This Terms & Conditions was first published and made effective on 1st November 2019. Last updated on 23rd November 2021.",
    paragraphs: [
      "These PIIIA.com's, Business ID 2950462-4 (hereinafter 'Company'), terms and conditions shall apply between you ('customer', 'user, 'you') and Company when you are using our websites, materials or purchasing our products and services (hereinafter 'Services').",
      "Use of Services shall be subject to user's or customer’s acceptance of and compliance with these Terms, and the Privacy Policy of the Services, which the user or customer shall accept to use the Service in accordance with the Terms currently in force. Company reserves the right to modify or replace these terms and conditions at any time, without prior notice.",
      "You agree to use the Services only for non-commercial purposes and in accordance with these terms and applicable laws and regulations in a manner which does not violate the rights of others of or restrict or prevent anyone else from using the Services.",
      "These terms and conditions apply to the following domains and subdomains, our Services and between Company and customer for purchasing products and services and delivery thereof unless Company and customer have otherwise agreed upon in writing:",
      "https://shop.piiia.com"
    ],

    article: [
      {
        paragraphs: [
          {
            title: "The Content on the Services & Use of Services:",
            nested_paragraphs: [
              "You must be at least 18 years of age or at least the age of majority in your state or province of residence to use the Services. Minors may use the Services only if they are the age of majority in their state or province of residence, or if you have given us your consent as their parent or legal guardian to allow any of your minor dependents to use the Services.",
              "Company is not responsible if information made available on the Services is not accurate, complete or current. The material on the Services is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on the Services is at your own risk.",
              "The information on the Services should not be relied on for any specific purpose and no representation or warranty is given as regards its accuracy or completeness. Company shall not be liable for any loss, damage or expense arising out of any access to or use of this site or any site linked to it.",
              "The Services may contain advertising of Company or third-parties and/or links to sites owned and maintained by third-parties. Company does not control the linked sites in any way, nor does Company monitor or check the contents of the linked sites. Company shall not be responsible for the products, services or operations of third-parties or for any material, information or other content provided by them. Company shall not be responsible for the offers of third-parties or the content thereof. In no event will Company be liable for the contents, correctness, reliability or data security of the linked sites.",
              "Any external links to this Services must link to the main page of the Services. The appearance, positioning and other features of the link must not have a detrimental effect on Company's reputation. When quoting the content of the Services as permitted by law, user must provide proper attribution the source in a good manner.",
              "Company shall be entitled at any time to change, modify, remove or amend the Service or content thereof without advance notice. Company shall be entitled at any time to suspend or close the Services, completely cease offering its content or any section thereof, or limit access to the Services or sections thereof.",
              "User shall provide accurate details. User is responsible for the accuracy and timeliness of the information provided. Provision of false information is prohibited and may occasion consequences. Company is not responsible for any damage or other harm caused from any inaccurate user-entered information.",
              "User shall not misuse the Services. User shall not commit or encourage a criminal offense, transmit or distribute a virus or any other material, which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene. User shall not hack into service, corrupt data, cause annoyance to other users, send spam or try to affect the performance or functionality of any computer facilities of or accessed through websites.",
              "If the user has contravened the Terms, terms or instructions provided separately in the Services, Company shall be entitled to prevent the user's access to the Services and prohibit use of the Services in the future.",
              "Company strives to ensure continuous and undisrupted access to the Services. Company does not, however, guarantee the user uninterrupted, timely or error-free operation of the Services. Company shall not be responsible for interruptions in the Services due to technical defects, maintenance or installation work. Company can’t guarantee the accessibility of the Services."
            ]
          },

          {
            title: "Registration:",
            nested_paragraphs: [
              "Some of the Services may require registration. When registering as a user of the Services, the user shall provide accurate details. Registration for the services requires a valid email address, as the completion of the registration requires the processing of a confirmation message sent to the user's email. Company has the right to decide without giving a reason whether Company accepts the registration or not. User ID is personal. User may not disclose their User ID to a third party nor give any third party a permission to use their User ID.",
              "When registering, the user chooses an user ID (= email address used for order) and a password. The user is responsible for ensuring that their password is kept safe and secret and shall be liable for any transactions made with their user ID. If the user notices or suspects that their ID has become public or that their ID is being used in an unauthorised manner, the user must immediately notify Company.",
              "Company reserves the right to terminate the right to use the user's IDs at any time if Company suspects that the IDs are being misused."
            ]
          },

          {
            title: "Intellectual Property Rights:",
            nested_paragraphs: [
              "The Services and contents, such as texts, images, videos, sounds, trademarks and signs, and the visual appearance of the Services shall remain the property of Company or its co-operation partners and protected by copyright law and trademarks as well as other legislation on intellectual property rights and registrations.",
              "User is entitled to use the Services and content thereof only for their own, non-commercial purposes.",
              "User are not entitled to save, use, share, distribute, send, publish or otherwise make public the Services and content thereof as is or in a modified form except in the limits set forth in these terms or in copyright regulations. User may not modify or replicate the Services or its content or any part thereof.",
              "When user make a printout of or copy of the content of the Services or save a copy on a computer for personal use only, user shall retain in all such printouts and copies notices pertaining to copyright and other rights contained in the Services.",
              "The e-shopping lists include our confidential knowledge and know-how, which are the exclusive property of PIIIA.com. Company grants you the e-shopping list and all its content solely for your non-commercial, personal purposes. The customer shall not show in public, deliver or forward the contents to any third party.",
              "If any material is showed in public, delivered or forwarded, payment details could be found out.",
              "The icons used in this web shop are licensed under the Creative Commons Attribution 4.0 International license. The icons are by https://fontawesome.com/license. You can view the license at http://creativecommons.org/licenses/by/4.0/."
            ]
          },

          {
            title: "Rights to the Deliverables Prepared and Provided:",
            nested_paragraphs: [
              "In agreeing the commission, the parties agree on the scope of the rights to use the material, artwork, product or similar (hereinafter 'material'). It is defined as part of a separate written agreement / contract, which also defines the other terms and conditions included in the commission.",
              "Company retains ownership, copyright and all other intellectual property rights in the photographs, videos, graphic designs, websites and other similar materials produced by the Company. The customer receives access to the material as specified in a separate written agreement / contract. Access can be restricted based on, for example, usage time, channels or geographic location.",
              "In principle, the customer receives the right to use the material created as the subject of the commission only for personal, private use. Commercial use without a separate agreement is prohibited. The customer has no right to use the material acquired as an individual to market, advertise or present their company, corporate, association or other activities. The customer has no right to benefit from the material in any way financially. The customer is not entitled to participate in the competitions with the material or a part thereof, nor to transfer the material to third-parties for publication. The customer may not modify, adjust or manipulate the material.",
              "The right to use the finished material is transferred when Company has received full compensation from the customer for the work or product.",
              "If the customer wishes to use the material for a purpose other than in accordance with the original agreement / contract, this and the additional compensation to be paid must be agreed with Company in advance. The customer does not have the right to transfer the agreed right of use to a third-party, it must be agreed separately.",
              "All originals, including drafts, proofs, sketches and drawings, are the property of Company. Drafts of design work or other material submitted to the customer may not be used, reproduced, copied or reproduced in any way without the consent of Company. Electronically submitted drafts must be discarded and may not be displayed or passed on to third-parties.",
              "The choice of materials to be delivered and used (especially, for example photos and videos) is entirely at the discretion of Company. The customer does not have the right to see for example all the images, videos or drafts, if, in Company's view, they are not good enough technically or artistically.",
              "In some cases, Company retains a digital copy of the ordered products to provide the ability to order additional copies of the same product. In this case, the finished digital product will be stored for 6 months after the order is completed, and Company has no obligation to store the finished products or materials thereafter. In the case of custom illustrated products and bespoke photo books, the finished digital copy of the ordered product will be kept for 24 months.",
              "Pending orders, for example due to unpaid payments, will be retained for 6 months, after which Company reserves the right to delete both the Customer-provided materials and the unfinished digital products and/or the finished physical products that have not been delivered.",
              "Company and its service providers and subcontractors have the right to retain copies of the finished product and use the products, materials or drafts thereof for their own marketing promotional and advertising use, portfolio use, trade publications in the field, trade fairs and expos and competitions without permission. Company respects the customer's privacy and uses situational judgment in the presentation."
            ]
          },

          {
            title: "User-Provided Content and Customer-Provided Material:",
            nested_paragraphs: [
              "User is responsible for the content and materials (hereinafter 'material') that they produce and deliver to the Services or that they send to other users or third-parties through the Services. In particular, the user is responsible for ensuring that the material provided by the user does not infringe copyright or other rights, good manners, legal or regulatory requirements, or that it is not infringing or inappropriate.",
              "If user has provided such material to Company, other users or third-parties / service providers, Company has the right but not the obligation to remove or prevent the use of such infringing material. The submitter of the content is responsible for all costs incurred by Company due to its illegality, infringement or the like.",
              "Advertising on the Services is prohibited without permission. The ban on advertising applies in particular to messages sent for commercial purposes, but so-called spamming is also included.",
              "Company is not responsible for the materials provided by users or customers, and is not responsible for their possible loss.",
              "User grant to Company the right to freely and free of charge to edit, copy, publish or not to publish, make available to the public or not make available in whole or in part (including marketing and other business) material submitted by the user to the Services or provided by the user on the Services. Company may publish content and material provided by the user on the Services, to other websites and social media services, as well as to various sections of the Services. Company has no obligation to retain the material it receives. The sender of the material undertakes not to make any claims against Company in connection with the material sent by they. The user is responsible for all claims for compensation and other claims made by third-parties related to the use of the material submitted by them.",
              "User must not provide Company with any confidential information through the Services. User agrees that any information or material provided to Company by them and any person acting on their behalf through the Services will not be treated as confidential.",
              "In particular, user is responsible if the material contains images or other material of a minor (or a protected adult) and warrants that they have obtained permission from a parent or legal guardian to use and reproduce those images or other materials.",
              "In the case of commissions, the material sent to it by the customer is used exclusively for the execution and processing of the commission. The copyright of the material sent by the customer remains with the customer. By submitting any material, you agree to grant Company the full right to use that material. Company has the right to modify, use and republish the material sent by the customer in the relevant part of the Services free of charge.",
              "In order for Company to use the material submitted by the customer, the customer declares that the submitted material is the result of their own original work, or they have the express consent of the right holder to use and add the material to the Services, and their actions do not violate any applicable law; and they have the right to grant Company the right to use the material as described above.",
              "The Customer undertakes to indemnify Company or a third-party for any costs and damages that may arise from the use of the Service in violation of this clause, and is responsible for answering claims for compensation submitted by rightholders or third-parties of the material submitted to the Service.",
              "When submitting photographs taken by the photographer, for example, the user must pay close attention and, if necessary, check the scope of their own access to them. If the right to use copies of the material is limited to personal and private use, Company must be notified in advance before confirming the order. In this case, the right to use the material may affect and limit our ability to use the finished part of our Services, for example in our marketing use.",
              "The material sent by the customer and provided to Company should be in its original versions. Photos can be from your phone, camera or scanned in paper photos. Although photos need to be enough high quality for printing or publishing. Photos uploaded from social media channels afterwards or sent/received via messaging apps are usually too low resolution. If needed, Company is able to create for example a Piiiabooks -bespoke photo book using those low resolution photos though - but the customer must know that the best printing quality can't be guaranteed and photos could look grainy or blurry in the final product.",
              "The choice of the materials to be used in the final Deliverables subject to the commission and in the Services section is entirely at the discretion of Company. In connection with the delivery of the material, the customer may inform Company if they wish a certain part of their material to be included in the finished product or the Services section. However, this must be brought to the attention of Company before Company has started the desinging work of the commission. Company can also offer the customer the material selection and curation assistance at an additional fee.",
              "The material submitted for the commission will be stored during the production process and deleted within at most 45 days from the completion of the order. Company reserves the right to retain copies and utilize of the final product or the part of the Services produced for customer (for example custom illustrated products) for the purposes of Company's own marketing, promotional and advertising use, portfolio use, trade publications in the field, trade fairs and expos and competitions.",
              "Company is entitled to use third-parties and subcontractors to carry out its Services or parts thereof and customer commissions. The material provided by the customer to Company will be passed and delivered on to third-parties and service providers only to the extent necessary to process and complete the order.",
              "Company reserves the right to refuse to produce or provide the Services or any part of the Services from material submitted by the customer which, in the opinion of Company, does not respect all of the above or is otherwise offensive or inappropriate.",

              "<u>Submission and delivery of material:</u>",
              "If the customer sends or delivers material to Company for use in their commission or order (for example printed photos for their custom illustrated products) by post or other means of delivery, Company shall not be liable for any damage or loss thereof. Company shall not accept liability for damage or loss of Customer-provided material or other information, and Company decline any claims in this respect, such as compensation of material cost or cost of the data carriers, and the costs of taking the photographs or compensation for the value of the lost memory. Company shall not accept liability for damage or loss of your digital pictures sent in to Company via internet, mobile communication, or on USB storage devices, DVD or other data carriers. We recommend that you always back-up your digital data. Company cannot be held responsible for the loss of materials sent by the customer.",
              "Upon receiving of the material provided by the customer, Company will temporarily store the material. Company is not responsible for the storage, warehousing or possible loss or damage of the material.",
              "Company has no obligation to return the material received to the sender. If the customer wishes to return the materials they have submitted by post or other means of delivery, an additional fee will be charged. Company is not responsible for damage to or loss of material, even for return. The material may be returned separately from the completed part of the Services. If the customer does not want their materials returned, Company will either recycle them or destroy them, at its sole discretion, without any liability for the materials.",
              "If desired, the customer can also submit material via Internet services such as Dropbox, Google Drive or WeTransfer. In this case, Company only has access to the material and/or public profile shared by the customer. Please read the privacy policy of the services before using them. Company is not responsible for the damage or loss of data transmitted using the data transfer method chosen by the customer."
            ]
          },

          {
            title: "Third-party Rights:",
            nested_paragraphs: [
              "Customers are solely responsible for ensuring that they are entitled to the required copyright, trademark, privacy and other rights to the material they send in.",
              "Should any third parties assert any claims against Company in connection with customer's order on the grounds of an infringement of such rights, customer will be liable to ward off these claims at their expense and to indemnify Company for damages incurred."
            ]
          },

          {
            title: "Limitation of Liability:",
            nested_paragraphs: [
              "The Services, including content, is provided 'as is'. Company does not, however, guarantee the customer uninterrupted, timely or error-free operation of the Services. Company may, at its sole discretion and without notice amend, change or remove portions of the Services. Company is not liable for the correctness, reliability, errors, deficiencies, inaccuracies or other shortcomings of the information or content provided by the Services. Company shall not be responsible for damage possibly occurring to the customer from incorrect or deficient information or information open for interpretation.",

              "Company cannot guarantee the data security of the Services, the content thereof or any information distributed via the Services, nor is Company responsible for ensuring it. Company shall not be held liable either for any information the user sends or receives or fails to send or receive via the Services.",

              "To the extent permitted by applicable law, Company shall not be liable for any direct, indirect, incidental or indirect damages, including, but not limited to, the information or data or any damages whatsoever resulting from loss or corruption, or any damages whatsoever resulting to loss of profits or revenue or business interruption resulting from the use of the Services, even if Company had been informed in advance of the possibility of such damage. The maximum amount of the Company's liability is the price paid by customer for the paid service in question.",

              "Before purchasing digital services, such as an e-shopping list, please also read through the section 'Disclaimer'.",

              "Company is not responsible for the Services presenting and displaying the colors, proportions and details of the Services and products with 100% accuracy. In the completed part of the Services and in the products, the colors may differ from the original Customer-provided material and its colors. Any differences in tone may be due to color and brightness adjustments on the screens, as well as printing limitations.",

              "Please note that some of the Services and products are handcrafted individually, so there will be variations and variability in appearance. Handmade products are each one unique, and those will vary slightly in size and shape. No two handmade products are totally alike.",

              "When agreeing the commission, the customer must be familiar with the Company's previous work. The customer accepts that the style of implementations (including but not limited to photography and videography style and graphic design style) may change over time or due to circumstances. Company does not warrant that the Deliverables and the material arising from the Services and commissions will be of the same style as the material previously produced.",

              "Customer is responsible for the material provided to Company, including, but not limited to, its accuracy, usage and copyright rights, and legality. Company is not liable for spelling errors that have been in the material provided by the customer. Company is only responsible for the contents if it is part of the commission. Even then, the customer is obliged to check the correctness of the content. After the customer approves the draft or proof and/or after completing the revision round / the preview phase, Company will no longer be liable for the content, nor for any material errors thereafter.",

              "Company is not responsible for the possible loss of the material you provide or the digital versions of the completed Services or parts thereof. Company is also not obligated to replace the incomplete Service or a part thereof. It may not be possible to retain the provided material and incomplete Services or portions thereof, for example, as a result of an unforeseeable force majeure beyond the control of Company, such as an accident or unexpected hard disk failure.",

              "After the performance of the Services and commissions, it is the customer's responsibility to take care of, use and store the Services or part thereof, unless maintenance has been separately agreed."
            ]
          },

          {
            title: "Disclaimer:",
            nested_paragraphs: [
              "No information, instruction, or advice contained in or provided with the Company's Services, including, but not limited to, digital services, should be construed as legal, medical, technical, or other similar advice, information, or guidance. Based on them, no claims can be made against the Company. <b>All information contained in the Services is provided for general information only.</b> The information is not intended as, nor should it be considered a substitute for professional advice, claims, suggestions, diagnosis, counseling, or treatment of any kind under any circumstances.",

              "<b>Your use of any information or materials provided and displayed on the Services is entirely at your own risk and responsibility.</b> It shall be your own responsibility to ensure that any products, services or information available through the Services meet your specific requirements. The company is not and does not claim to be an expert in any field or topic.",

              "Information obtained through the Services may not be used as the sole basis for decision making. We instruct you to contact a professional for instructions and advice, and thus primarily to hear more accurate, complete and up-to-date sources of information. <b>Always consult a professional before using the content, information, products, supplies, tools, materials, or other items featured and provided on the Services, and/or when planning plumbing or electrical installations.</b> Any product claim, statistic, quote, information or other representation about a product or service should be verified with the manufacturer or provider.",

              "The Company is not responsible for the accuracy, completeness, reliability, suitability, performance, availability or timeliness of the information or other material provided on the Services in general or for your specific purpose. The Company is not responsible for the suitability of the information provided in the Services for the needs of the user or for the result or effectiveness of the actions taken on the basis of the information.",

              "The Company shall not be liable for any direct or indirect damages that may arise in connection with the Services and their use. You use the Services at your own risk. The company is not liable for any possible damages, including but not limited to, for example, those that occur to you or your vehicle during the modification of your van.",

              "The web shop and its products and services may contain links to third party websites or services that are not owned or controlled by the Company. The Company is not responsible for third party websites, the content of websites or services, privacy policies or other policies. The functionality or timeliness of the links cannot be guaranteed. The Company shall not be liable, directly or indirectly, for any damages or losses incurred or alleged to have been caused by the use of or reliance on such content, products or services on the Services.",

              "Such links provided in the Services are for convenience only, to provide further information. They do not imply that the Company endorses such websites or products.",

              "<u>The e-shopping lists:</u>",

              'The SHOP OUR VAN, - PLUMBING and - SOLAR -e-shopping lists include the products and the building materials used in our van conversion called PAKUPROJEKTI. There are three different e-shopping lists available. If you are looking for some specific product info and you aren’t sure in which e-shopping list it’s included - please contact us and ask about it by email: info@piiia.com. Notice that there might be some items that don\'t include to the e-shopping lists at all - f.ex. some of the products and materials that we have got as recycled. E-shopping lists are listed based on our <a href="https://youtu.be/ncLaCPf9W74">Van tour</a> -video and the combo seen on it.',

              "The SHOP OUR VAN DIMENSIONS -listing contains the exact dimensions of the self-built pieces, the possible indications of the installation heights and the overall dimensions of the final floor plan of our van conversion called PAKUPROJEKTI. The listing does not include the dimensions of the Ford Transit van, nor building instructions. The dimensions vary depending on how and with what materials the interior of the vehicle and its components are chosen to be built. The size of the vehicle and the curvatures of its roof and side walls may also have a different effect on the final assembly and its dimensions.",

              "The e-shopping lists include our van layout plan (without dimensions), our water diagram or our solar diagram as an bonus item. Those are not made or finished by a professional. The content and information are based entirely on our own know-how and experience. <b>You are not allowed to use those in practice without consulting a professional in any circumstances! E-shopping lists and information contained in the lists are for general information purposes only. We aren't or claim or appear to be experts on any topic or area.</b>",

              "<b>All information shared on PIIIAshop is for informational purposes only and should not be construed as professional advice. Always consult and contact a professional before using content, information, products, supplies, tools, materials, or anything else, or planning plumbing or electrical installations. Answers, content and information should not be used without professional consultation and guidance, and we will instruct you to use a professional for installations.</b>",

              "The Company is not responsible for the products, supplies, tools, materials or anything else you purchase listed on the e-shopping lists. You use products, supplies, tools, materials and other information and information at your own risk. The Company is not responsible for any damage to you or the subject, such as your vehicle.",

              "The Company is not responsible for the timeliness or suitability of the services, content, information or products for your particular needs, requirements and uses, or for the outcome or effectiveness of any action take on the information. You use the content, information and products at your own risk. It is the customer's responsibility to ensure and verify that the proposed products, accessories, tools, materials or others are appropriate for the customer's own needs and applications and are current.",

              "<b>All information, and any product claim, statistic, quote, information or other representation about a product or service, should be verified with the manufacturers or suppliers of the products and services.</b>",

              "<b>!! NB! Some of the electrical parts in the SHOP OUR SOLAR -e-shopping list and solar diagram may require installations by a registered and licensed professional electrician! Therefore, find out the local laws, orders and regulations regarding the safety of electrical installations.</b>",

              "<u>Consultations:</u>",

              "The VAN CONVERSION CONSULTATION, WATER SYSTEMS CONSULTATION and SOLAR SYSTEM CONSULTATION allow for a personal, short consultation that focuses on one, specific issue or question according to the customer's needs in relation to the topics listed on the product pages of the consultations.",

              "In addition to the Limitation of Liability and Disclaimer mentioned above; Based on the questions asked in the consultations, the answers can provide guidelines, the implementation of which must always be ensured with professionals. The customer should always consult and contact a professional before using the answers, content or information."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Terms of Order & Ordering:",
            nested_paragraphs: [
              "PIIIAshop -web shop sells Company's products and services for major individuals. These Terms of Order, - Payment and - Delivery and Cancellation and Return Policy apply to the purchase and delivery of products and services sold with the following domains and subdomains, unless Company and the customer have agreed otherwise in writing: https://shop.piiia.com.",

              "Customer will place an order with the Company either orally, on the Services, by using the inquiry forms on the Services, by email, on social media channels or in writing. By placing an order, the customer accepts these terms. An order is considered created when Company receives the order. Company will confirm the order placed by the customer by email.",

              "In some of the Services, Company sends first a quote for the customer by email based on their inquiry. Then a binding order is created and takes effect when the customer accepts the offer and terms and conditions received. Company confirms the customer's order by email as soon as possible.",

              "If customer has not received an order confirmation email within three (3) working days from the order, they are asked to contact Company by email at: info@piiia.com.",

              "When placing an order, the customer is obliged to provide their complete contact information, which includes at least the name, address, telephone number and email address of the private customer.",

              "The contract between Company and the customer can be valid for an agreed period of time (for example from the acceptance of the order and the quote to the last due date) or indefinitely. In the case of contracts valid until further notice (indefinitely), it is agreed in a separate written agreement / contract and its appendices that one (1) to three (3) months' notice may be given. An agreement / contract in force until further notice (indefinitely) must be terminated in writing.",

              "The agreement / contract is between Company and the customer. Both parties undertake to keep confidential all information related to the contract, service and/or its pricing.",

              "Company reserves the right to refuse orders, for example in special situations, and Company is not obliged to state the reason for such refusal. Company also has the right to choose its customers without the obligation to justify its decisions in its customer choice. If Company detects potentially illegal material when executing orders, the order will not be processed and it will be suspended.",

              "Company is entitled to use third-parties and subcontractors to carry out its Services or parts thereof and customer commissions. The material provided by the customer to Company will be passed on to third-parties and service providers only to the extent necessary to process and complete the order.",

              "<u>Customer-Provided Material for the Commission:</u>",

              "Some of the Services require the delivery of customer-provided material to Company. In this case, the commission can not be started and executed until the material provided by the customer is received. Some of the Services also require more detailed and extensive background material and briefing for our Company. If the customer provides only vague and concise information and material, they must understand that it may be reflected in the outcome of the commission and the finished product or part of the Services.",

              "For consultations, placing an order requires a clear question from the customer to the Company that the customer requests an answer to. The consultation includes the opportunity to ask the Company one (1) question on the topics listed on its product page. If more than one question is submitted to the Company, the Company shall, at its sole discretion, choose one (1) of them to answer.",

              "If the Company feels that it is unable to answer the question asked in the consultation (for example, in a manner that it considers adequate or because of its scope), it has the right to cancel the service. In this case, the Company will notify the customer of the cancellation of the service by email as soon as possible and refund to the customer the fees paid by the customer for the service by then.",

              "Customer is obliged to provide the background material and other material required for the order to the extent agreed in the contract or when confirming the order. If the customer does not provide the information, background material or other material required in the commission in accordance with the schedule agreed with the Company or the commission is delayed for any other reason attributable to the customer, Company has the right to postpone the commission and delivery of the completed part of the Services.",

              "Company may also offer the customer assistance in producing, editing, selecting or curating this material for a separate additional fee.",

              "<u>Draft, Proof & Revision Round:</u>",

              "In some of the Services, the customer is given opportunity for a so-called revision round, a preview phase. In it, Company makes available to the customer a draft or proof copy of the commission given by the customer to Company. The customer can accept the draft or proof as it is, with changes or reject it.",

              "The number of revision rounds is agreed during confirming the order, in a separate written agreement or in connection with the offer. Unless otherwise agreed, Company’s design services generally include one (1) revision round. Subsequent new revision rounds will be subject to a separate surcharge. These additional payments must be made in full before the customer can receive the deliverables and the finished product as part of the commission.",

              "The customer is responsible for reviewing, previewing and proofing the proof copy. The customer should perform a final proof of the draft or proof copy for any spelling, date, alignment or other related issues. Once the draft or proof has been accepted by the customer, no requirements or needs for change can be demonstrated. The completed product or part of the Service can no longer be modified or edited once the draft or proof has been approved.",

              "<b>Accepting:</b>",

              "The customer can accept the draft or proof as it is, without any changes. If desired, the customer can also omit the revision round, the preview phase, in which case the draft or proof is considered directly accepted as such, without any requests for changes.",

              "If the customer has been informed of the revision round but does not return the matter to the Company (within 14 days), the draft or proof will be deemed directly accepted, without any request for change. The execution of the commission will continue until it is completed.",

              "<b>Accepting with change requests:</b>",

              "Unless otherwise agreed, the customer may make a few small change requests during the revision round. A request for change means a request for alteration of an existing design with specific instructions for placing, replacing, resizing, or altering specific section, images or text on specific pages. However, change requests may not require excessive design changes, including, but not limited to, the creation of an entirely new draft or proof, a complete redesign of majority of sections or parts, vague (non-spesific) requests to alter the design, submission of new material or replacement of old, and alteration of the model, dimensions or orientation.",

              "The customer may also accept the draft or proof with the change requests they specifies. In this case, Company completes the commission, taking into account the change requests. If the requested changes go beyond the offer, plan and contract, Company will inform the customer of the estimated total or hourly cost of the additional work required. The customer decides whether additional work will be carried out and the work would be agreed in advance.",

              "<b>Rejecting:</b>",

              "If the customer does not want to carry out additional work or otherwise does not accept the draft or proof, the commission can be cancelled. If the commission is cancelled, the customer will be responsible to pay the Company for the work done up until then."
            ]
          },

          {
            title: "Terms of Delivery:",
            nested_paragraphs: [
              "Company shall deliver the Services to the customer after the customer has made the payment in accordance with the payment terms.",

              "The customer must always ensure that they provides them complete and up-to-date contact information when placing an order. Company will not make any changes to the delivery address after the order has been confirmed.",

              "In the event the delivery of the Services ordered by the customer is delayed due to the customer, for example incorrect order information, Company shall not be responsible for possible damage incurring due to the delay.",

              "If we the Services have been ordered intented and purposeful with wrong billing and delivery address and/or not to the country where the customer belongs, Company is allowed to cancel the order and have right to not to sell the Services. You can choose the right delivery location in the <i>Buy now</i> -view or by clicking globe icon, which you can find on the navigation bar above.",

              "The completed Services will be delivered to the customer either by post, in digital form or as digital services;",

              "<b>By post:</b>",

              "Company primarily delivers the Services (by post) to addresses in Finland and other EU countries. The customer can inquire from Company about possible deliveries to a country outside the European Union. Please note that it might not be possible for Company to deliver to some locations. International customers are responsible for their country’s customs, taxes, duties and/or VAT fees. Payment of those charges may be necessary to release your order from customs on arrival.",

              "Delivery charges will be added to the price of the Services delivered by post, unless otherwise specified. Delivery charges depend on the final weight, volume and destination country. Company deliver orders by letter or package via Posti / Finnish Postal Service (or Matkahuolto). The customer will receive a delivery confirmation by email when the delivery is in transit. If the delivery method is a letter, the delivery may not include the shipment ID and therefore the delivery cannot be tracked through a tracking code.",

              "If customer do not collect the order on time or if the order returns to us due to an incorrect address, Company will charge extra delivery charges and any other costs incurred by Company. In this case, the order will also be charged for its full value. If the order is returned to Company, it will be stored for 6 months. Thereafter, Company reserves the right to delete, lose and destroy the order, delivered materials, unfinished digital products and / or finished physical products that have not been delivered.",

              "<b>In digital form:</b>",

              "Orders delivered in digital form will be delivered by email to the email address provided by the customer in their order.",

              "The email sent separately contains the products or services ordered, possible files, a possible link and other guidance depending on the situation. The customer is responsible for the link through which Company distributes the files to them. The customer should download the material to be shared via the link to their own computer, and preferably also back it up. The customer must download the material within 2 weeks at the latest. After that, the link is no longer required to be valid.",

              "If the digital material is desired to be delivered in another way, it must be agreed with Company separately. In that case, the customer is obliged to reimburse the costs incurred for another method of delivery, such as memory stick and delivery charges.",

              "<b>As digital services:</b>",

              "Digital services purchased from the web shop, such as e-shopping lists, work electronically and after successful payment transaction, those can be accessed by signing in to the PIIIAshop -web shop.",

              "In connection with the first purchase, the customer must activate their PIIIAshop user account. After a successful payment, the customer will receive Activate your account -message in their email in addition to the order confirmation. Following the instructions in the message activates the PIIIAshop user account and creates a password for it.",

              "Signing in to PIIIAshop is done using the <i>Sign in</i> -button, which can be found on the navigation bar above. The password is created by the customer at the time of the first purchase, and the email address is the one to which the messages from PIIIAshop have arrived. After successfully sign in, purchased digital services, such as e-shopping lists, can be found on the <i>Orders</i>-view - and access them from there, or by clicking the <i>Open</i>-button on each digital service's own product page.",

              "You can return to the purchased digital services in the same way by signing in to the PIIIAshop -web shop. When a customer later purchases additional digital services using the same email address, new purchases will appear directly in the same <i>Orders</i>-view and will be immediately available without the need to complete the account activation step.",

              "<u>Delivery time:</u>",

              "The estimated delivery time is determined on an order-by-order basis and as per contract. Some of the products and services are made to order: commissions are made to order and other manufactured and modified goods are made to order. The Services are intended to be provided and delivered in accordance with any delivery time estimates announced in the Services. However, the delivery time is only an estimate and varies according to the current order situation. From time to time, the production time may also be extended due to the production queue.",

              "Possible delays in delivery times will be announced to the customer by email.",

              "Some of the Services are highly affected by the exchange of information between the customer and Company. Delivery may be delayed if the customer delivers incomplete material to Company or if the delivery of the agreed material to Company is delayed."
            ]
          },

          {
            title: "Pricing and Terms of Payment:",
            nested_paragraphs: [
              "The prices of the products and services shall be determined according to the price list applicable at each given time or according to the offer made. All the prices shall include VAT except when shipping to countries outside the European Union. Company reserves the right to change the prices and delivery charges without prior notice.",

              "The products or services may be offered occasionally for sale on the Services. The sale prices are marked in red, while the original prices are strikethrough.",

              "You can pay in euros (€, EUR), the British pounds (£, GBP) or U.S. dollars ($, USD) on the web shop.",

              "For some of the Services, the price list is not fully published on the websites. According to the customers requirements and needs, manufactured or modified Services' final price will be determined on the basis of final product and its requirements. Customers have opportunity to request a quote from Company using various ways of contact.",

              "Company reserves the right to cancel the order in case of obviously erroneous price. A clear price error means a possible pricing error of the Service in such a way that the price of the product differs significantly and clearly from the correct price of the product, and has been substantially incorrectly updated, for example for a technical or human reason.",

              "Delivery charges will be added to the price of the Services delivered, unless otherwise specified. International customers are responsible for their country’s customs, taxes, duties and/or VAT fees. Payment of those charges may be necessary to release your order from customs on arrival.",

              "Customer must pay for the Services using the payment methods stated in the Services.",

              "Unless otherwise stated in the order confirmation or the contract, the total purchase price of the order is due immediately after the contract is entered into.",

              "The right to use and publish the Deliverables begins when Company has received all payments from the customer, unless otherwise agreed in writing in advance.",

              "Commissions and orders that require printing will not be sent to the print provider until the full payment has been made to Company and received by Company.",

              "If customer have revised and approved the draft and/or the proof, but do not submit requested materials necessary to complete the commission or for the progress of the commission within 14 days, Company has the right to invoice the work hours done up until then, even if the commission has not yet been completed.",

              "<u>Payment methods:</u>",

              "Payment methods used are depending on the Services PayPal payment, bank transfer (receive the bank details to your email for payment) or a pdf-invoice received by email. The payment methods may vary from time to time, or from country to country. Customer is responsible for any bank charges.",

              "<b>PayPal payment:</b>",

              "Using PayPal you can pay for your purchase by credit card or using your PayPal account. To use PayPal, you may need to open a PayPal account - or sign in to your existing PayPal account. In some cases, you might also be able to pay for your purchase in PayPal by debit or credit card without opening a PayPal account. When using existing PayPal account, you are required to check that your delivery address and personal information are up to date.",

              "<b>Bank transfer:</b>",

              "After choosing paying by bank transfer as your payment method, you will receive the bank details to your email for payment.",

              "<b>A pdf-invoice received by email:</b>",

              "When paying by invoice, the customer undertakes to pay all instalments in accordance with the total price of the product and the payment schedule stated in connection with the order. If the payment is not made before due date, no orders will be delivered to the customer.",

              "Customer will be obliged to pay the invoice once they have confirmed the order. Company shall have the right to charge interest according to the Interest Rate Act (as amended from time to time) for late payments. In addition to interest, Company shall have the right to charge the customer standard recovery and notification fees resulting from the recovery. Any comments on the invoice must be submitted in writing before the invoice due date.",

              "If customer has not paid previous invoices and one or more payment reminders have been sent to the customer, Company reserves the right to refrain from sending or executing new orders until it has received full payment.",

              "<b>( Deposit / Upfront fee : )</b>",

              "Some of the Services may require a so-called deposit / upfront fee. In this case, customer pays 50% of the price presented in the offer when confirming the order and the commission. The due date of the deposit / upfront fee is defined as 'Immediately' and Company will not proceed with production of the order and commission until payment is completed and received. The final payment (50%) will be invoiced at the time specified by Company itself, usually when Company has completed its share of the process and the Service or part thereof is ready to be sent to the print provider, or otherwise as the commission progresses. Any additional work ordered by customer will be invoiced in connection with the final instalment.",

              "<u>Additional work:</u>",

              "The Services excluded from the offer must be agreed in advance with the Company. If the customer requests deviations from the offer, contract or commission or additional work, they will be invoiced in accordance with the Company's valid price list. Any additional work will be invoiced in connection with the final instalment.",

              "<u>Gift Cards for the Services:</u>",

              "Gift Cards can be redeemed through the Services, unless otherwise stated in the gift card or in the special terms of the gift card. Piiiabooks -bespoke photo book design service Gift cards may be used only on the Piiiabooks -bespoke photo book design service.",

              "Gift Card balances will be applied against the total amount of the order placed. Any unused Gift Card balance will remain on the Gift Card. If any purchase exceeds the Gift Card balance, the excess amount must be paid with another payment method accepted on the Services. The gift card can only be used before the ordering process is completed. The gift card cannot be exchanged for cash.",

              "After the expiry date, the gift card has lost its value and cannot be used as payment or reactivated, nor can any remaining value be reimbursed.",

              "Keep your gift card safe. Lost or stolen cards will not be replaced. Note! Gift cards have no right of return."
            ]
          },

          {
            title: "Cancellation and Return Policy:",
            nested_paragraphs: [
              "<u>Commissions, Service agreements:</u>",

              "The 14-day cancellation period begins from the time at which the commission, service agreement was finalized.",

              "The cancellation right does not apply to service agreed for a spesific time. A cancellation period for such services may, however, be specified in the separate terms and conditions of sale.",

              "The consumer does not have the right to cancel the purchase if the service has been completed entirely (f.ex. the order has sent to the print provider) upon the consumer's request before the cancellation period ended and the consumer has been informed of the lack of a cancellation right.",

              "Such a above-mentioned request can be considered when the customer purchases and orders the service from the web shop, while accepting the terms and conditions with their purchase decision. The consumer must be informed of the terms of the cancellation right or of the compensation liabilities related to cancellation.",

              "If service provision has begun upon the consumer's request before the cancellation period ended - and the service is subsequently, partially completed, cancelled, the consumer must pay the Company reasonable compensation, calculated in proportion to the overall price of the agreement.",

              "Reasonable compensation to be paid to the Company must take into account the work already done, the measures resulting from the suspension of work, possible purchases of materials and compensation for lost profits.",

              "If the work delivered is not satisfactory to the customer, customer or Company may cancel the commission, in which event the customer agrees to pay the Company for the work done up until then.",

              "If the commission has been cancelled before it's completed, customer will not be entitled to receive the Deliverables, and all rights shall remain with Company.",

              "Company shall assume that the commission has been cancelled if customer fails to respond Company request(s), or not submit requested materials for the progress of the commission within 14 days. The money paid by customer will not be refunded in this case.",

              "A full fee must be paid for the completed material ordered and delivered (revised as the proof), regardless of whether it is used or not by the customer. Customer will be entitled to receive the Deliverables.",

              "In the event of cancellation by Company (f.ex. because of illness etc.), it is obliged to inform customer as soon as humanly possible. Company's liability shall be limited to a full refund of any fees paid.",

              "Company has the right to suspend the execution of the commission / assignment before the end of the agreed period in situations where someone behaves threateningly or violently towards Company or due to inappropriate behavior.",

              "Company has the right to refuse to accept orders and also to terminate contracts if the material provided by the customer contains illegal, inappropriate, unethical, offensive, or otherwise suspicious content.",

              "<u>Digital services:</u>",

              "The 14-day cancellation period begins from the time at which the agreement concerning the digital content was finalised.",

              "The consumer loses the right to cancel the purchase if the delivery of the digital content was started during the cancellation period with the consumer's consent and the consumer was informed of the loss of the cancellation right.",

              "Such consent may be deemed to have been obtained when the customer activates the service via the link provided in the activation email and/or access rights to the service or content are created for the customer’s username.",

              "<u>Goods:</u>",

              "The 14-day cancellation period begins upon receipt of the goods ordered (or the final instalment of goods ordered).",

              "— <b>Manufactured or modified goods:</b>",

              "The cancellation right does not apply to products manufactured or modified according to the consumer's wishes or otherwise clearly made to correspond to the consumer's individual needs.",

              "Cancellation of confirmed placed order is not possible. Such goods cannot be returned because, by their nature, they cannot be returned for resale, inter alia because the finished products contain the customer's personal information.",

              "Company has the right to refuse to accept orders and also to terminate contracts if the material provided by the customer contains illegal, inappropriate, unethical, offensive, or otherwise suspicious content.",

              "— <b>Other Goods:</b>",

              "Consumers shall have the right to cancel the purchase made in the Services within 14 days from receipt of the goods. Should customer exercise their cancellation right, their must inform Company by email info@piiia.com always before shipping the goods.",

              "Right of return applies only for products that are in unused condition, in their original packaging and marketable products. Please note, that the goods must be returned within 14 days of giving the cancellation notice. Company do not accept returns sent more than 14 days after receipt of the ordered goods.",

              "NB! Company is not responsible for the cost of return. The consumer must cover the costs of returning the goods.",

              "Once Company has received the returned goods and verified that they meet all the requirements, Company will refund the price of the goods to the customer. Company is entitled to a refund of any costs and bank charges associated with the refund."
            ]
          },

          {
            title: "Defects and Complaints:",
            nested_paragraphs: [
              "If the product you ordered has been sold out or the service can't be provided, Company will inform customer as soon as possible, always by email.",

              "The Customer must inspect the received products for any possible defects. In the event ordered products are missing from the delivery or the delivery is otherwise defective, customer must inform Company of the issue without undue delay by email info@piiia.com, within 14 days after receiving the order.",

              "Company may primarily repair the fault or deliver a new product. If the product is partially defective, you can only complain about the defective part.",

              "If the order has been damaged in the transportation, please send reclamation to Finnish Postal Service / Posti (or Matkahuolto) immediately.",

              "Company provides no warranty that the deliverables provided, will not tear, fade or that they are water-resistant.",

              "Company is not responsible for spelling errors that have been in the material provided by customer. In the case of printed products, a slight color deviation is not considered a defect and will not be accepted as a subject of complaint. Please observe that pictures of insufficient quality (f.ex. digital pictures with low resolution), wrong / unsuitable file formats or definitions, an erroneous product selection, erroneous order quantity or spelling error due to human error are excluded from complaints. Customer is responsible for carefully verifying that the information provided is suitable for printing the product before submitting the files to Company.",

              "Commissions that include the draft or the proof and revision round, means that the draft or the proof is accepted by the customer and errors found after approval are not considered errors.",

              "Any restrictions encountered during the technical implementation phase that are inconsistent with the draft or the proof will be resolved in writing by a separately agreed compromise."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Force Majeure:",
            nested_paragraphs: [
              "If Company fails to comply with the terms and conditions or provision of Services hereunder because of unusual adverse circumstances outside of its control due to force majeure such as, but not limited to, labour strikes, civil unrest, war or natural catastrophes, shall such circumstances result in relief from damages and other measures. Company shall not be deemed to be in breach of an agreement, or otherwise be liable to customer, caused by a reason beyond Company's control (force majeure). In these cases customer shall not have a right for compensation from Company for breach of contract."
            ]
          },

          {
            title: "Personal Data:",
            nested_paragraphs: [
              'Please make sure you read through our <a href="/en/privacy">Privacy Policy, Data Protection Policy and Cookies Policy</a>. The link is visible alongside other important links within the footer of every page on Company\'s website.'
            ]
          },

          {
            title: "Applicable Law and Dispute Resolution:",
            nested_paragraphs: [
              "These Terms shall be governed by and construed in accordance with Finnish law. Any dispute arising out of these Terms will be primarily settled in good-faith negotiations between Company and customer. Should the parties fail to settle the dispute this way, it shall be finally settled in the Kymenlaakso district court."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Contact information:",
            nested_paragraphs: [
              "PIIIAshop / PIIIA.com",
              '<a href="mailto:info@piiia.com">info@piiia.com</a> <span class="f7 i">(Please notice three i\'s of piiia!)</span>'
            ]
          }
        ]
      }
    ]
  },

  faq: {
    /* => faq.vue */
    title: "FAQ",
    subtitle: "Frequently Asked Questions & Instructions",
    paragraph:
      'Please read <a href="/en/terms/">Terms & Conditions</a> and <a href="/en/privacy/">Privacy Policy and Data Protection Policy</a> of PIIIAshop carefully before using our Services. Below you will find some Frequently Asked Questions, instructions and our contact information for possible problem situations.',
    article: [
      {
        paragraphs: [
          {
            title: "Contact us:",
            nested_paragraphs: [
              "If any issues occurs or you have any questions concerning our products, please contact us by email:",
              '<a href="mailto:info@piiia.com">info@piiia.com</a> <span class="f7 i">(Please note three i\'s of piiia!)</span>'
            ]
          },

          {
            title: "Technical requirements:",
            nested_paragraphs: [
              "We recommend using the following browsers and the latest versions released: Google Chrome, Apple Safari and Mozilla Firefox. We can’t guarantee the accessibility of the site and web shop with using other browsers. We support just the browsers listed above.",
              "We update our web shop regularly. If you notice any bug or errors, kindly contact us."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Delivery methods & Delivery charges:",
            nested_paragraphs: [
              "Orders are delivered to the customer either by post, in digital form or as a digital service;",

              "<u>By post:</u>",

              "Orders delivered by post will be delivered to the address provided by the customer in their order. The customer must always ensure that they provides them complete and up-to-date contact information when placing an order. Company will not make any changes to the delivery address after the order has been confirmed.",

              "Delivery charges will be added to the price of orders delivered by post, unless otherwise agreed. Delivery charges include postage and handling. Delivery charges depend on the final weight, volume and destination country.",

              "— <i>stickers, keychains, custom illustrated products, bespoke photo books:</i> Finland <b>3,00 euros</b>, other EU countries <b>6,00 euros</b>",

              "We deliver orders by letter or package via Posti / Finnish Postal Service (or Matkahuolto). You will receive a delivery confirmation by email when the delivery is in transit. If the delivery method is a letter, the delivery may not include the shipment ID and therefore the delivery cannot be tracked through a tracking code.",

              "We primarily deliver orders (by post) to addresses in Finland and other EU countries. You can inquire from us about possible deliveries to a country outside the European Union. Please note that it might not be possible for us to deliver to some locations. International customers are responsible for their country’s customs, taxes, duties and/or VAT fees. Payment of those charges may be necessary to release your order from customs on arrival.",

              "Please note that orders not picked up or returned to us due to an incorrect address will be subject to an additional delivery charge, as well as the full value of the order.",

              "<u>In digital form:</u>",

              "Orders delivered in digital form will be delivered by email to the email address provided by the customer in their order.",

              "— <i>gift cards, consultations:</i>",

              "The email sent separately contains the products or services ordered, such as a gift card to be printed / sent to the recipient, or our answer to the issue / question focused on the consultation.",

              "— <i>other services:</i>",

              "Any other services delivered in digital form may include information about the services provided, any files, any link and/or other guidance as appropriate. The customer is responsible for any link through which the files are distributed to them. The customer should download the material to be shared via the link to their own computer, and preferably also back it up. The customer must download the material within 2 weeks at the latest. After that, the link is no longer required to be valid.",

              "* If the digital material is desired to be delivered in another way, it must be agreed with us separately. In that case, the customer is obliged to reimburse the costs incurred for another method of delivery, such as memory stick and delivery charges.",

              "<small><i>If we the Services have been ordered intented and purposeful with wrong billing and delivery address and/or not to the country where the customer belongs, Company is allowed to cancel the order and have right to not to sell the Services. You can choose the right delivery location in the <i>Buy now</i> -view or by clicking globe icon, which you can find on the navigation bar above.</i></small>",

              "<u>As a digital service:</u>",

              "Digital services purchased from the web shop, such as e-shopping lists, work electronically and after successful payment transaction, those can be accessed by signing in to the PIIIAshop -web shop.",

              "— <i>e-shopping lists:</i>",

              "After successful sign in, you will find the e-shopping lists you have purchased on the <i>Orders</i>-view - from where you can access those, as well as clicking the <i>Open</i>-button from the e-shopping lists' own product pages.",

              "When making your first purchase, you must activate your PIIIAshop user account. In connection with this, you will receive from us an email (in addition to the order confirmation) with the message <i>Activate your account</i>. Follow the instructions in the message to activate your PIIIAshop user account and create a password.",

              "<small><i>If we the Services have been ordered intented and purposeful with wrong billing and delivery address and/or not to the country where the customer belongs, Company is allowed to cancel the order and have right to not to sell the Services. You can choose the right delivery location in the <i>Buy now</i> -view or by clicking globe icon, which you can find on the navigation bar above.</i></small>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Delivery time:",
            nested_paragraphs: [
              "We do our best to get your orders delivered as quickly as possible. We will inform the customer of any delays in the delivery time.",

              "— <i>stickers:</i>",

              "We aim to post the ordered stickers within at least five (5) business days of receiving the order, after which the order will be transferred to the distribution supplier for delivery.",

              "Deliveries will arrive in Finland approximately within four (4) working days of sending the letter or package, and to other EU countries approximately within eight (8) working days of sending the letter or package. However, the delivery time is only an estimate.",

              "— <i>keychains, custom illustrated products, bespoke photo books:</i>",

              "Keychains, custom illustrated products and bespoke photo books are made to order. Keychains take about one (1) week to make, custom illustrated products take about three (3) weeks to make and bespoke photo books take about three-four (3-4) weeks to make. However, the production time is only an estimate and varies according to the current order situation.",

              "When the order is complete, it will be handed over to the distribution supplier for delivery. Deliveries will arrive in Finland approximately within four (4) working days of sending the letter or package, and to other EU countries approximately within eight (8) working days of sending the letter or package. However, the delivery time is only an estimate.",

              "— <i>gift cards:</i>",

              "Gift cards will be delivered within 48 hours of receiving the order. However, gift cards are valid immediately after a successful payment transaction - and the order confirmation also acts as a gift card if necessary (f.ex. if the gift is in a hurry!).",

              "— <i>consultations:</i>",

              "Consultations are made to order. We aim to deliver a consultation within at least five (5) business days of receiving the order. However, the delivery time is only an estimate and varies according to the current order situation.",

              "— <i>digital services:</i>",

              "Digital services, such as e-shopping lists, work electronically and after successful payment transaction, those can be accessed immediately by signing in to the PIIIAshop -web shop."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Payment methods:",
            nested_paragraphs: [
              "You can purchase the web shop's products and services from their own product pages by clicking the <i>Buy now</i> -button that appears there.",

              "The products are purchased one by one, unfortunately many products can't be collected in a single payment. When purchasing the stickers, keychains, custom illustrated product order or bespoke photo book order, you can choose the purchase quantity from 1 to 5.",

              "Orders and their delivery costs are paid in connection with the order. Payment methods used are PayPal payment or bank transfer;",

              "<u>PayPal payment:</u>",

              "PayPal is the safer, easier way to pay online. After clicking the <i>Buy Now</i> -button, select <b>PayPal</b> as your payment method, and PayPal will direct you.",

              "PayPal requires you to sign in to PayPal, after which you can make a secure card payment. Sign in to your existing PayPal account - or open a PayPal account (by clicking the <i>Create an Account / Luo tunnus</i> -button). If possible, you can also make a payment without creating a PayPal account, in which case select <i>Pay with debit or credit card</i>.",

              "After successful payment, PayPal will return you back to the PIIIAshop -web shop. Please note to confirm and accept your delivery address and other information passed from PayPal. After confirming your delivery information, you will receive an order confirmation in your email.",

              "Was the guide difficult to understand? You can watch the video guide below!",

              "<u>Bank transfer:</u>",

              "You can also pay by bank transfer. After clicking the <i>Buy Now</i> -button, select <b>Bank transfer</b> as your payment method. PIIIAshop will direct you to confirm your billing and delivery addresses. After that, you will receive the bank details to your email for payment. Once we have received your payment, we will send you an order confirmation (please notice possible delay)."
            ]
          },

          {
            title: "Video guide about how to make a PayPal payment:",
            nested_paragraphs: [
              '<div class="w-100"><iframe src="https://www.youtube.com/embed/JkX2rce99GQ" frameborder="0" allowfullscreen></iframe></div>'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Gift cards:",
            nested_paragraphs: [
              "<u>Buy a PIIIAshop -gift card:</u>",

              "PIIIAshop -web shop sells gift cards to the PIIIAshop -web shop. The gift card can be purchased for the amount you want and is valid for 6 months from the time of purchase.",

              "The PIIIAshop -gift card can be used as a payment method in the PIIIAshop -web shop. The gift card can be used all at once or in parts. The gift card cannot be exchanged for cash and can't be used to purchase gift cards.",

              "The gift card is delivered by email, and you can either print it or email it directly to the recipient!",

              "<u>Use the PIIIAshop -gift card you received:</u>",

              "Wonderful to hear! We look forward to what products and services you plan to use it for.",

              "The gift card can be used as a payment method in the PIIIAshop -web shop. The gift card can be used all at once or in parts - so if there is still value left on the gift card after your order, you can use the surplus amount in your next order. If the amount of the order is greater than the amount of the gift card, we will charge the excess portion to the gift card user. The gift card cannot be exchanged for cash and can't be used to purchase gift cards.",

              "To use your gift card, please contact us by email: info@piiia.com. Title your email 'Gift card PIIIAshop' and mention in your message your gift card, its code, and what you would like to use it for.",

              "The PIIIAshop -gift cards are valid for 6 months from the date of purchase.",

              "<u>Buy a piiiabooks -gift card:</u>",

              'PIIIAshop -web shop sells gift cards to the <a href="https://photobooks.piiia.com">piiiabooks — Bespoke Photo Book Design Service</a>. The gift card can be purchased for the amount you want and is valid for 6 months from the time of purchase.',

              'The piiiabooks -gift card can only be used for the bespoke photo book design service, which you can find at: <a href="https://photobooks.piiia.com">https://photobooks.piiia.com</a>.',

              "The piiiabooks -gift card is used only as a means of payment for ordering a piiiabooks -bespoke photo book. The gift card can be used all at once or in parts. The gift card cannot be exchanged for cash.",

              "The gift card is delivered by email, and you can either print it or email it directly to the recipient!"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Cancellation and Return Policy:",
            nested_paragraphs: [
              "<u>Digital services:</u>",
              "The 14-day cancellation period begins from the time at which the agreement concerning the digital content was finalised.",
              "The consumer <b>loses the right to cancel the purchase if the delivery of the digital content was started</b> during the cancellation period with the consumer's consent and the consumer was informed of the loss of the cancellation right.",
              "Such consent may be deemed to have been obtained when the customer activates the service via the link provided in the activation email and/or access rights to the service or content are created for the customer’s username.",

              "<u>Goods:</u>",
              "The 14-day cancellation period begins upon receipt of the goods ordered (or the final instalment of goods ordered).",

              "<b>— <i>Manufactured or modified goods:</i><b>",
              "The cancellation right <b>does not apply to products manufactured or modified according to the consumer's wishes or otherwise clearly made to correspond to the consumer's individual needs</b>.",
              "Cancellation of confirmed placed order is not possible. Such goods cannot be returned because, by their nature, they cannot be returned for resale.",
              "<small><i>Company has the right to refuse to accept orders and also to terminate contracts if the material provided by the customer contains illegal, inappropriate, unethical, offensive, or otherwise suspicious content.</i></small>",

              "<b>— <i>Other Goods:</i></b>",
              "Consumers shall <b>have the right to cancel the purchase made in the Services within 14 days from receipt of the goods</b>. Should customer exercise their cancellation right, their must inform Company by email info@piiia.com always before shipping the goods.",
              "Right of return applies only for products that are in unused condition, in their original packaging and marketable products. Please note, that the goods must be returned within 14 days of giving the cancellation notice. Company do not accept returns sent more than 14 days after receipt of the ordered goods.",
              "<b>NB! Company is not responsible for the cost of return.</b> The consumer must cover the costs of returning the goods.",
              "Once Company has received the returned goods and verified that they meet all the requirements, Company will refund the price of the goods to the customer. Company is entitled to a refund of any costs and bank charges associated with the refund.",

              "<u>Commissions, Service agreements:</u>",
              "The 14-day cancellation period begins from the time at which the commission, service agreement was finalized.",
              "The consumer <b>does not have the right to cancel the purchase if the service has been completed entirely</b> (f.ex. the order has sent to the print provider) upon the consumer's request before the cancellation period ended and the consumer has been informed of the lack of a cancellation right.",
              "Such a above-mentioned request can be considered when the customer purchases and orders the service from the web shop, while accepting the terms and conditions with their purchase decision. The consumer must be informed of the terms of the cancellation right or of the compensation liabilities related to cancellation.",
              "If service provision has begun upon the consumer's request before the cancellation period ended - <b>and the service is subsequently, partially completed, cancelled, the consumer must pay the Company reasonable compensation</b>, calculated in proportion to the overall price of the agreement.",
              "Reasonable compensation to be paid to the Company must take into account the work already done, the measures resulting from the suspension of work, possible purchases of materials and compensation for lost profits.",

              "Company shall assume that the commission has been cancelled if customer fails to respond Company request(s), or not submit requested materials for the progress of the commission within 14 days. The money paid by customer will not be refunded in this case.",
              "<small><i>Company has the right to refuse to accept orders and also to terminate contracts if the material provided by the customer contains illegal, inappropriate, unethical, offensive, or otherwise suspicious content.</i></small>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Defects and Complaints:",
            nested_paragraphs: [
              "If the product you ordered has been sold out or the service can't be provided, we will inform customer as soon as possible, always by email.",
              "The Customer must inspect the received products for any possible defects. In the event ordered products are missing from the delivery or the delivery is otherwise defective, customer must inform Company of the issue without undue delay by email info@piiia.com, within 14 days after receiving the order.",
              "Company may primarily repair the fault or deliver a new product. If the product is partially defective, you can only complain about the defective part.",
              "If the order has been damaged in the transportation, please send reclamation to Finnish Postal Service / Posti (or Matkahuolto) immediately."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "Custom illustrated products / Is the sticker, magnet, keychain or print illustrated from my own vehicle or building?:",
            nested_paragraphs: [
              'Yes! Custom illustration – <b><a href="/en/product/CSTM-LLSTRTN-1">Sticker</a></b>, <b><a href="/en/product/CSTM-LLSTRTN-2">Magnet</a></b>, <b><a href="/en/product/CSTM-LLSTRTN-3">Keychain</a></b> and <b><a href="/en/product/CSTM-LLSTRTN-4">Print</a></b> -orders include drawing and illustrating your adventure vehicle or building directly into 5 pieces of ready-made stickers / magnets / keychains, or into 1 ready-made print! You can capture your own vehicle or cabin, for example, as a sticker on a water bottle, a magnet on the refrigerator door or a keychain for the key of your vehicle.',

              "Your vehicle can be a van, a camper van, a motorhome, a car, a caravan or even a bus. And if you are adventuring with a vehicle not mentioned above — you can inquire about the option from us by email! The building can be, for example, your beloved home or summer cottage.",

              "The sticker/magnet/keychain/print shows the vehicle/building from the side. We use the photo (or photos) you provided of the vehicle/building as a basis for the design. Therefore, to begin making an order, you must provide us with a photo (or photographs) of your vehicle after a successful payment transaction.",

              "At least one of the photos should be a side view taken directly from the side of your vehicle/building — please stand far enough away and aim the shot directly at the center of your vehicle/building. Please take a photo in daylight. If you want, you can also include a few additional photos, such as a view directly from the front or close-ups that make your vehicle’s/building’s features and details even clearer and more accurate.",

              "Please send the photo(s) by email to: info@piiia.com (please note three i's of piiia!). Title your message 'Custom illustration' and also mention in your message the possible make and model of your vehicle, the possible text wishes and the order number of your order, which you will find in the order confirmation email."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Bespoke photo books / What is bespoke photo book?:",
            nested_paragraphs: [
              'The PIIIAshop web shop also sells products for the <b>piiiabooks - Bespoke Photo Book Design Service</b>. <b><a href="/en/product/CSTM-PHTBK-1">Size S</a></b>, <b><a href="/en/product/CSTM-PHTBK-2">size M</a></b> and <b><a href="/en/product/CSTM-PHTBK-3">size L</a></b> photo book packages are on sale, as well as <b><a href="/en/product/GFT-CRD-4">gift cards for the service</a></b>, for ordering a bespoke photo book.',

              "BESPOKE PHOTO BOOK -order includes a design work, a unique illustration on a subject taken from photos and a ready-made Bespoke photo book (size S, M or L, 1 pc, A4 portrait/landscape). The size options for photo book packages are following: Small — photos max: 100 pcs, Medium — photos max: 200 pcs ja Large — photos max: 300 pcs.",

              'We also offer Custom size photo books with unlimited photos - <a href="https://photobooks.piiia.com/order">read more</a>.',

              'Bespoke photo book ordering starts with purchasing a photo book package of the desired size (Small, Medium or Large). For Custom size orders, fill out our <a href="https://photobooks.piiia.com/order">separate order form</a> , on the basis of which we will send you a quote.',

              "After a successful payment, you will receive instructions in your email to send the photos. After submitting the photos, we will start designing your photo book and later on the printed photo book will arrive by post to you!",

              'Let’s tell your stories together! Find out more about the service and its terms and conditions at: <a href="https://photobooks.piiia.com">https://photobooks.piiia.com</a>.',

              "To begin making an order, it requires that after a successful payment transaction you provide us the photos coming to your photo book. You will receive instructions in your email.",

              'Do you have thousands of photos? No worries, we also offer <a href="https://photobooks.piiia.com/order">photo selection assistance</a>.'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "Consultations / What is a consultation? What does consultation mean?:",
            nested_paragraphs: [
              'The <b><a href="/en/product/VN-CNSLTTN-1">VAN CONVERSION CONSULTATION</a></b>, the <b><a href="/en/product/VN-CNSLTTN-2">WATER SYSTEMS CONSULTATION</a></b> and the <b><a href="/en/product/VN-CNSLTTN-3">SOLAR SYSTEMS CONSULTATION</a></b> are personal, short and fast (15 minute) consultations where we focus on one, specific issue or question you have chosen according to your needs in relation to the topics listed on the product pages of the consultations.',
              "The consultation will be conducted entirely by email. You can ask us one (1) question at a time. We will provide our answers to the question / focus on the question by email, which will be sent to the email address provided by the customer in their order.",
              "If more than one (1) question is submitted at a time, we will choose one (1) to answer at our sole discretion.",
              "If we feel that we are unable to answer the question asked (for example, in our opinion, in an adequate manner or because of its scope), we have the right to cancel the service. We will notify the customer of the cancellation of the service by email as soon as possible and will refund the customer the fees paid by the customer for the service by then.",
              "Please note that the service is for informational purposes only and that it or information obtained through it should not be taken as professional advice, suggestions, instructions or the like. Always consult a professional before possible implementations."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title:
              "E-shopping lists / Why should I purchase an e-shopping list?:",
            nested_paragraphs: [
              'DIY camper van conversion project ahead? Are you starting <a href="https://vanproject.piiia.com">a van build</a>? Or renovating an old caravan?',
              "The e-shopping lists' purpose is to help you - make product choices easier, save your time and help you avoid error purchases.",
              'When self-converting our own <a href="https://www.instagram.com/pepethevan/">@pepethevan</a>, we used countless amount of hours to make the most sustainable and the best product choices and to find out the best stores to buy or order those from.'
            ]
          },

          {
            title: "E-shopping lists / What do the e-shopping lists include?:",
            nested_paragraphs: [
              'The <b>SHOP OUR VAN, - PLUMBING and - SOLAR</b> -e-shopping lists include the products and the building materials we have used to self-convert our Ford Transit <a href="https://www.instagram.com/pepethevan/">@pepethevan</a>.',

              '<b><a href="/en/product/E-SHPPNGLST-1">SHOP OUR VAN</a> -e-shopping list</b> includes a list and information of the products regarding the following categories: <i>Garage, Hallway, Bathroom, Offices, Living area, Bedroom, Kitchen, Exterior</i> and <i>Interior</i>. So it\'s including for example the products we have used to insulate the van and for its interior decoration. <b>As a bonus</b> SHOP OUR VAN -e-shopping list includes also our van layout floor plan (without dimensions)!',
              'All the parts of our water system are on its own <b><a href="/en/product/E-SHPPNGLST-2">SHOP OUR PLUMBING</a> -e-shopping list</b>. It includes a list and information of the products regarding our water system: <i>Equipments, Tanks, Grey water connectors</i> and <i>Fresh water connectors</i>. <b>As a bonus</b> SHOP OUR PLUMBING -e-shopping list includes our plumbing diagram as well!',
              'All the parts of our solar system are also on its own <b><a href="/en/product/E-SHPPNGLST-3">SHOP OUR SOLAR</a> -e-shopping list</b>. It includes a list and information of the products regarding our solar system: <i>Equipments, Tools, Fuses, Wires</i> and <i>Connectors</i>. <b>As a bonus</b> SHOP OUR SOLAR -e-shopping list includes our solar diagram!',
              'If you are looking for some specific product info and you aren’t sure in which e-shopping list its included - please contact us and ask about it by email: info@piiia.com<span class="f7 i">(Please note three i\'s of piiia!)</span>.',
              '— <u>New:</u> We have added the <b><a href="/en/product/E-SHPPNGLST-5">SHOP OUR VAN DIMENSIONS</a> -listing</b> to our e-shopping lists! From the listing, you can get the exact dimensions of the self-built pieces, the possible indications of the installation heights, as well as the overall dimensions of the final floor plan of our <a href="https://www.instagram.com/pepethevan/">@pepethevan</a>-van. <b>As a bonus</b> listing also includes also our van layout floor plan!'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Digital services / Getting started & using:",
            nested_paragraphs: [
              "Digital services purchased from the web shop, such as e-shopping lists, work electronically and after a successful payment transaction, those can be accessed immediately by signing in to the PIIIAshop -web shop.",

              "When making your first purchase, you must activate your PIIIAshop user account. After a successful payment, you will receive an Activate your account -message in your email in addition to the order confirmation. Follow the instructions in the message to activate your PIIIAshop user account and create a password for it. Once you have done that, you will be able to sign in to the PIIIAshop -web shop and use the digital services you have purchased.",

              "Signing in to PIIIAshop is done using the <i>Sign in</i> -button, which you can find on the navigation bar above. The password is created by you during the first purchase, and the email address is the one to which the messages from PIIIAshop have arrived.",

              "After successfully signing in to the web shop, you will see the digital services you have purchased, such as e-shopping lists, on the <i>Orders</i>-view - and access them from there, or by clicking the <i>Open</i>-button on each digital service's own product page.",

              "You can return to the digital services you have purchased whenever you want, always in the same way by signing in to the PIIIAshop -web shop.",

              "When you later purchase more digital services using the same email address, new purchases will appear directly on the same <i>Orders</i>-view and will be immediately available without the account activation step.",

              "You can sign out of the web shop by clicking <i>Sign out</i> -button, which you can find on the navigation bar above, when you are signed in.",

              "Did that sound difficult? You can watch the video guide about getting started with e-shopping lists below!"
            ]
          },

          {
            title: "Video guide about how does an e-shopping list work:",
            nested_paragraphs: [
              '<div class="w-100"><iframe  src="https://www.youtube.com/embed/pMZH7HZq5xU" frameborder="0" allowfullscreen></iframe></div>'
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Registration in the PIIIAshop -web shop:",
            nested_paragraphs: [
              "Digital services require registration in the PIIIAshop -web shop.",

              "When registering, the user must provide all the information marked as mandatory. Registration for the services requires a valid email address, as the completion of the registration requires the processing of a confirmation message sent to the user's email.",

              "When purchasing the first digital service, the customer must activate the PIIIAshop user account. After a successful payment, the customer will receive an <i>Activate your account</i> -message in their email in addition to the order confirmation. Following the instructions in the message activates the PIIIAshop user account and creates a password for it.",

              "When registering, the user chooses an user ID (= email address used for order) and a password. The user is responsible for ensuring that their password is kept safe and secret and shall be liable for any transactions made with their user ID. User ID is personal. User may not disclose their User ID to a third party nor give any third party a permission to use their User ID. If the user notices or suspects that their ID has become public or that their ID is being used in an unauthorised manner, the user must immediately notify Company.",

              "Company reserves the right to terminate the right to use the user's IDs at any time if Company suspects that the IDs are being misused."
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "Sign in issues:",
            nested_paragraphs: [
              "If you're having trouble signing in, here are some things to check:",
              "<ul><li>E-mail address is the same you used to make your purchase (if your payment method is PayPal, then your PayPal account) on PIIIAshop</li><li>Password is the one you set when you registered in the PIIIAshop</li></ul>",
              "Did you forgot your PIIIAshop-password? Try to reset your password:",
              "<ul><li>Click <i>Sign in</i> -button which you can find on the navigation bar above. After clicking it, you will be directed to Signing in -page, where you can click <i>Forgot password?</i> -link.</li><li>Then, enter your correct email address you used to make your purchase (if your payment method is PayPal, then your PayPal account) on PIIIAshop. Follow the instructions in the email sent to the email address.</li><li>After you've created a new password, it will become active right away.</li></ul>"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "How the PIIIAshop -web shop was founded?:",
            nested_paragraphs: [
              'PIIIAshop has started with the van conversion project called <a href="https://vanproject.piiia.com/">PAKUPROJEKTI</a> which we started in the spring of 2018. With #pakuprojekti, we fulfilled our long-held dream — we self-converted a Ford Transit van into a tiny rolling home. The end result of the project, Pepe the van is e.g. solar powered and has everything you need in home! 🚐💕',

              "We built the van with zero experience or skills. We made the material and product choices for the project thoughtfully. The van met all our expectations, and Pepe became a such a beautiful, cozy and unique Scandinavian conversion entity!",

              "After the van conversion, we have received a lot of questions every week about what brand certain products and materials are AND where those have been purchased from. We wanted to find a way to be able to respond quickly to everyone, while passing on the knowledge we learned in the project and gained through it.",

              "Then we immediately embarked on a new project - and built the PIIIAshop -web shop from scratch (yes, from the van!).",

              "Now product listings that make your own van build project easier can be purchased from the PIIIAshop -web shop! After purchasing the product list, you get instant access to it!",

              "You will find the answers to your questions directly in the SHOP OUR VAN, SHOP OUR PLUMBING, SHOP OUR SOLAR and SHOP OUR VAN DIMENSIONS -product listings. As a bonus the list includes either our van layout floor plan, plumbing diagram or solar diagram!"
            ]
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "",
            nested_paragraphs: ['<p class="tc">♥  ♥  ♥</p>']
          }
        ]
      },

      {
        paragraphs: [
          {
            title: "What is PIIIA.com?:",
            nested_paragraphs: [
              '<a href="https://piiia.com/">PIIIA.com</a> is a place for all the explorers of their own lives. PIIIA.com media is founded in 2017 for road trips and everyday adventures. Piia Niemi is 29 years old entrepreneur from Finland. You can check out more below:',
              '<ul><li><a href="https://piiia.com/vlog">PIIIA.com</a> - media for road trips and everyday adventures ✨🌙</li><li><a href="https://www.youtube.com/PIIIA">PIIIA</a> - van life & car travel -oriented YouTube-channel</li><li><a href="https://vanproject.piiia.com">PAKUPROJEKTI</a> - the van conversion project we started back in spring 2018</li><li><a href="https://www.instagram.com/pepethevan/">@pepethevan</a> - our self-converted van / rolling home</li><li>@piiiacom-accounts on <a href="https://www.instagram.com/piiiacom/">Instagram</a>, <a href="https://www.facebook.com/piiiacom/">Facebook</a> & <a href="https://twitter.com/piiiacom">Twitter</a></li></ul>',

              'PIIIA.com is part of the same company as <a href="https://pepeunlimited.com/fi/">Pepe Unlimited</a>. Company provides a wide range of creative services - all the way from design & visual services to software development services:',
              '<ul><li>Social media consulting</li><li>Content creation (photos, videos, copywrite, graphics)</li><li>Social media management</li><li>Visual identity & Branding</li><li>Graphic design</li><li>Websites</li><li>Video editing</li><li>App development</li><li>Bespoke photo books -> <a href="https://photobooks.piiia.com">piiiabooks</a></li></ul>',
              "We are your one stop shop for many kind of projects. We will be pleased to hear from you!",
              'Contact us by <a href="mailto:info@pepeunlimited.com">email</a> or fill out an <a href="https://pepeunlimited.com">inquiry</a>.'
            ]
          }
        ]
      }
    ]
  },

  error: {
    terms: "Please agree the terms"
  },
  payment_method: {
    method: "Payment method options:",
    bank_transfer: "Bank transfer",
    pay: "Pay directly from your account"
  },
  sign_in: {
    /* => SignIn.vue */
    title: "Sign in",
    subtitle: "Sign in to your PIIIAshop-account",
    sign_in: "Sign in",
    sign_out: "Sign out",
    password: "Password",
    forgot: "Forgot password?",
    email: "Email",
    note:
      "• You are now able to sign in to the PIIIAshop -web shop and digital services you have purchased, with the password you have just created."
  },

  cart: {
    /* => CartTotal.vue */
    shipping_cost: "+ delivery charges:",
    total_amount: "Total:",
    vat: "(incl. VAT {vat} % • {taxes_with_shipping} {currency})"
  },

  purchase: {
    /* => Purchase.vue */
    status: "Status:"
  },

  order_status: {
    approved: "Paid",
    dispatched: "Dispatched",
    invoice_created: "Paid via bank transfer"
  }
};
