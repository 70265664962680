<template>
  <div>
    <header class="pv3 ph4 db-l dn">
      <div class="db mw9 center w-100">
        <nav class="flex justify-between">
          <div class="flex items-center pa3" href="">
            <router-link
              class="link pepe-link hover-piiia-blank"
              :to="$i18nRoute({ name: 'home' })"
            >
              <div class="fl bg-white pl4 pr3 pv2">
                <h1 class="f2-ns f5 lh-solid piiia-grey dm-serif ma0">piiia</h1>
                <h1 class="f6 fw3 lh-copy black roboto-slab ma0 tr">shop</h1>
              </div>
            </router-link>

            <nav-link
              toLink="home"
              css="black-70 mh4"
              translation="navigation.products"
            />

            <nav-link
              toLink="faq"
              css="black-70 mr4"
              translation="navigation.faq"
            />

            <nav-link
              toLink="https://pepeunlimited.com"
              css="black-70 mr4"
              translation="navigation.hireUs"
              v-bind:isRouter="false"
            />

            <nav-link
              toLink="https://pakuprojekti.piiia.com"
              css="black-70 mr4"
              translation="navigation.pakuprojekti"
              v-bind:isRouter="false"
            />

            <nav-link
              toLink="https://piiia.com"
              css="black-70 mr4"
              translation="PIIIA.com"
              v-bind:isTranslation="false"
              v-bind:isRouter="false"
            />
          </div>
          <div class="flex-grow pa3 flex items-center">
            <nav-link
              v-if="isAuthenticated"
              faIcon="bookmark"
              toLink="purchases"
              css="black-70 mr4"
              translation="navigation.myOrders"
            />
            <sign-in-button />
            <lang-changer />
            <globe-view />
          </div>
        </nav>
      </div>
    </header>

    <!-- only on mobile -->
    <header class="dn-l">
      <van-nav-bar
        title="piiiashop"
        :z-index="1"
        @click-left="onClickLeft"
        v-bind:border="false"
        v-bind:fixed="true"
      >
        <van-icon name="wap-nav" slot="left" />
      </van-nav-bar>

      <van-popup
        v-model="isBurgerMenu"
        closeable
        position="left"
        :style="{
          height: '100%',
          width: '70%',
          backgroundColor: '#000'
        }"
      >
        <nav class="flex flex-wrap h-100 pa4">
          <div class="flex flex-column">
            <nav-link toLink="faq" translation="navigation.faq" />

            <nav-link
              toLink="https://pepeunlimited.com"
              translation="navigation.hireUs"
              v-bind:isRouter="false"
            />

            <nav-link
              toLink="https://pakuprojekti.piiia.com"
              translation="navigation.pakuprojekti"
              v-bind:isRouter="false"
            />

            <nav-link
              toLink="https://piiia.com"
              translation="PIIIA.com"
              v-bind:isTranslation="false"
              v-bind:isRouter="false"
            />

            <nav-link
              v-if="isAuthenticated"
              faIcon="bookmark"
              toLink="purchases"
              translation="navigation.myOrders"
            />
            <sign-in-button class="white pa0" />
          </div>

          <div class="w-100 mt-auto">
            <div class="flex justify-between">
              <div class="w-30"></div>
              <div class="w-50">
                <lang-changer />
              </div>
              <div class="w-50">
                <globe-view />
              </div>
            </div>
          </div>
        </nav>
      </van-popup>
    </header>
  </div>
</template>
<script>
import LangChanger from "./LanguageChanger.vue";
import SignInButton from "./SignInButton.vue";
import GlobeView from "./GlobeView.vue";
import NavLink from "./NavLink.vue";

import { mapGetters } from "vuex";
export default {
  name: "custom-header",
  components: {
    "lang-changer": LangChanger,
    "sign-in-button": SignInButton,
    "globe-view": GlobeView,
    "nav-link": NavLink
  },
  methods: {
    onClickLeft() {
      this.showPopup();
    },
    showPopup() {
      this.$store.commit("user/setIsBurgerMenu", true);
    },
    onClickPIIIAShop() {
      this.$router.push({ name: "home" });
    }
  },
  mounted() {
    document
      .querySelector(".van-nav-bar__title")
      .addEventListener("click", this.onClickPIIIAShop);
  },
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: "authenticated"
    }),
    ...mapGetters("user", {
      isBurgerMenu: "isBurgerMenu"
    }),
    isBurgerMenu: {
      get() {
        return this.$store.state.user.isBurgerMenu;
      },
      set(show) {
        this.$store.commit("user/setIsBurgerMenu", show);
      }
    }
  }
};
</script>
