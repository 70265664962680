import { createAxios } from "./client.js";
const client = createAxios();

export default {
  forgotPassword(username, language) {
    return client.post("ForgotPassword", {
      username: username,
      language: language
    });
  },
  verifyResetPassword(token) {
    return client.post("VerifyResetPassword", {
      token: token
    });
  },
  resetPassword(token, password, language) {
    return client.post("ResetPassword", {
      token: token,
      password: password,
      language: language
    });
  }
};
