import httpbin from "./repository/httpbin/resource";
import orderpayment from "./repository/orderpayment/resource";
import user from "./repository/user/resource";
import nginxjwt from "./repository/nginxjwt/resource";
import shoppinglist from "./repository/shoppinglist/resource";
import image from "./repository/image/resource";

const repositories = {
  httpbin: httpbin,
  orderpayment: orderpayment,
  user: user,
  nginxjwt: nginxjwt,
  shoppinglist: shoppinglist,
  image: image
  //others...
};

export const RepoFactory = {
  get: name => repositories[name]
};
