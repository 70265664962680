<template>
  <div id="app">
    <custom-header />
    <router-view></router-view>
    <custom-footer />
    <cookies />
  </div>
</template>

<script>
import header from "./components/Header.vue";
import footer from "./components/Footer.vue";
import cookies from "./components/Cookies.vue";

export default {
  name: "App",
  components: {
    "custom-header": header,
    "custom-footer": footer,
    cookies: cookies
  }
};
</script>
