import { createAxios } from "./client.js";
const client = createAxios();

export default {
  shoppingList(sku, token, language) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Accept-Language"] = language;
    return client.get(sku);
  },
  categories(sku, token, language) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Accept-Language"] = language;
    return client.get(`${sku}/categories`);
  },
  sections(sku, categoryId, pageToken, pageSize, token, language) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Accept-Language"] = language;
    return client.get(`${sku}/section/${categoryId}`, {
      params: { page_size: pageSize, page_token: pageToken }
    });
  }
};
