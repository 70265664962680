import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");
import Vue from "vue";
import { BANK_TRANSFER, PAYPAL } from "./paymentmethod.js";
// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: false,
  cart: {
    /* sku => { product: product, quantity: quantity } */
  },
  paymentMethod: PAYPAL /*  */
};

// getters
const getters = {
  cart(state) {
    return state.cart;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  productIds(state) {
    return Object.keys(state.cart).map(key => {
      const item = state.cart[key];
      return { product_id: item.product.id, quantity: item.quantity };
    });
  },
  isPayPal(state) {
    return state.paymentMethod == PAYPAL;
  },
  isBankTransfer(state) {
    return state.paymentMethod == BANK_TRANSFER;
  }
};

// actions
const actions = {};

// mutations
const mutations = {
  /* { product: product, quantity: quantity }  */
  add(state, product) {
    Vue.set(state.cart, product.product.sku, product);
  },
  remove(state, sku) {
    Vue.delete(state.cart, sku);
  },
  removeAll(state) {
    Object.keys(state.cart).forEach(key => {
      Vue.delete(state.cart, key);
    });
  },
  increment(state, product) {
    const item = state.cart[product.sku].product;
    Vue.set(state.cart, product.sku, {
      product: item,
      quantity: product.quantity
    });
  },
  setPaymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
