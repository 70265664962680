export function host(url) {
  const host = url.replace(/^https?:\/\//, "").replace(/\/.*$/, "");
  const parts = host.split(".").slice(-3);
  if (parts[0] === "www") parts.shift();
  return parts.join(".");
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

export function is401(error, dispatch, cookie) {
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    if (error.response.status == 401) {
      dispatch("auth/signOut", { cookie: cookie }, { root: true });
    }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    //console.log(error.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    // console.log('Error', error.message);
  }
  //console.log(error);
}
