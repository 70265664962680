import { createAxios } from "./client.js";
const client = createAxios();

export default {
  async image(imageId) {
    return client.post("GetImage", { image_id: imageId });
  },
  async imageIds(productId, sku, pageSize, pageToken) {
    if (productId == null) {
      productId = 0;
    }
    return client.post("GetImageIds", {
      sku: sku,
      product_id: productId,
      page_size: pageSize,
      page_token: pageToken
    });
  },
  async createImage(productId, sku, mimeType, name, source, token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post("CreateImage", {
      product_id: productId,
      mime_type: mimeType,
      name: name,
      sku: sku,
      source: source
    });
  },
  async deleteImage(imageId, token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post("DeleteImage", {
      image_id: imageId
    });
  }
};
