import { createAxios } from "./client.js";
const client = createAxios();

export default {
  productLists(language) {
    return client.post("orders.payments.ProductService/GetProductLists", {
      language: language
    });
  },
  /*
   * products: [{ product_id: 1, quantity: 1 }]
   **/
  createOrder(payer, products, shipmentCostId, taxRateId, currency, language) {
    return client.post("orders.payments.OrderService/CreateOrder", {
      payer: payer,
      shipment_cost_id: shipmentCostId,
      tax_rate_id: taxRateId,
      currency: currency,
      products: products,
      language: language
    });
  },
  async createPayPalPayment(paymentId, orderHash, payer, language) {
    return client.post("orders.payments.PaymentService/CreatePayPalPayment", {
      payment_id: paymentId,
      order_hash: orderHash,
      payer: payer,
      language: language
    });
  },
  async createBankTransferPayment(orderHash, payer, language) {
    return client.post(
      "orders.payments.PaymentService/CreateBankTransferPayment",
      {
        order_hash: orderHash,
        payer: payer,
        language: language
      }
    );
  },
  getProducts(
    pageSize,
    pageToken,
    language,
    currency,
    residentCode,
    productListId
  ) {
    if (productListId == null) {
      productListId = 0;
    }
    return client.post("orders.payments.ProductService/GetProducts", {
      page_size: pageSize,
      page_token: pageToken,
      language: language,
      currency: currency,
      resident_code: residentCode,
      product_list_id: productListId
    });
  },
  getPurchases(pageSize, pageToken, token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post("orders.payments.PurchasesService/GetPurchases", {
      page_size: pageSize,
      page_token: pageToken
    });
  },
  getProduct(sku, productId, language, currency, residentCode) {
    if (productId == null) {
      productId = 0;
    }
    return client.post("orders.payments.ProductService/GetProduct", {
      sku: sku,
      product_id: productId,
      language: language,
      currency: currency,
      resident_code: residentCode
    });
  },
  getCountries(language, currency) {
    return client.post("orders.payments.CountryService/GetCountries", {
      language: language,
      currency: currency
    });
  },
  localization() {
    return client.post("orders.payments.CountryService/GetLocalization", {});
  },
  priceCalculate(product, currency, language, residentCode, shipmentCostId) {
    if (shipmentCostId == null) {
      shipmentCostId = 0;
    }
    return client.post("orders.payments.PricingService/PriceCalculate", {
      product: { product_id: product.productId, quantity: product.quantity },
      resident_code: residentCode,
      language: language,
      currency: currency,
      shipment_cost_id: shipmentCostId
    });
  },
  pricesCalculate(products, currency, language, residentCode, shipmentCostId) {
    /*var converted = [];
    products.forEach(item => {
      converted.push({ product_id: item.product.id, quantity: item.quantity });
    });
    if (shipmentCostId == null) {
      shipmentCostId = 0;
    }*/
    return client.post("orders.payments.PricingService/PricesCalculate", {
      products: products,
      resident_code: residentCode,
      language: language,
      currency: currency,
      shipment_cost_id: shipmentCostId
    });
  },
  isPurchasable(productId, residentCode) {
    return client.post("orders.payments.ProductService/GetIsPurchasable", {
      product_id: productId,
      resident_code: residentCode
    });
  },
  shipmentMethod(productIds, residentCode, language, currency) {
    return client.post("orders.payments.ShipmentService/GetShipmentMethod", {
      product_ids: productIds,
      resident_code: residentCode,
      language: language,
      currency: currency
    });
  },
  purchasedProducts(pageSize, nextPageToken, language, token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post(
      "orders.payments.PurchasesService/GetPurchasedProducts",
      {
        page_size: pageSize,
        page_token: nextPageToken,
        language: language
      }
    );
  },
  isPurchased(productId, token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return client.post("orders.payments.PurchasesService/GetIsPurchased", {
      product_id: productId
    });
  }
};
