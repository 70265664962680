import { RepoFactory } from "../../api/repofactory";
const nginxjwt = RepoFactory.get("nginxjwt");
const user = RepoFactory.get("user");

import { AUTHORIZATION } from "./cookie.type";

// initial state
const state = {
  loading: false,
  error: null,
  isAuthenticated: false,
  isMismatchPassword: false,
  isPasswordReset: true,
  isNote: false
};

// getters
const getters = {
  authenticated: state => {
    return state.isAuthenticated;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  isPasswordReset: state => {
    return state.isPasswordReset;
  },
  isNote: state => {
    return state.isNote;
  }
};

// actions
const actions = {
  async signIn({ commit }, { email, password, cookie, router }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await nginxjwt.signIn(email, password);
      cookie.set(AUTHORIZATION, data.token);
      commit("setAuthenticated", true);
      commit("setLoading", false);
      commit("setIsNote", false);
      router.push({ name: "purchases" });
    } catch (error) {
      commit("setError", error);
    }
  },
  async signOut({ commit, dispatch }, { cookie }) {
    try {
      cookie.delete(AUTHORIZATION);
      commit("setAuthenticated", false);
      dispatch("purchase/reset", {}, { root: true });
    } catch (error) {
      commit("setError", error);
    }
  },
  async forgotPassword({ commit }, { email, language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      user.forgotPassword(email, language);
      commit("setIsPasswordReset", false);
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  },
  // used on ResetPasswordSuccess
  async toSignIn({ commit }, { router }) {
    try {
      router.push({ name: "signIn" });
      commit("setIsPasswordReset", true);
    } catch (error) {
      commit("setError", error);
    }
  },
  async verifyResetPasswordToken({ commit }, { token }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await user.verifyResetPassword(token);
      commit("setLoading", false);
    } catch (error) {
      commit("setMsgError", error);
    }
  },
  async resetPassword(
    { commit },
    { token, password, reEnterPassword, router, language }
  ) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      if (password !== reEnterPassword) {
        commit("setMismatchPassword", true);
      } else {
        commit("setMismatchPassword", false);
        const resp0 = await user.verifyResetPassword(token);
        const resp1 = await user.resetPassword(token, password, language);
        router.push({ name: "signIn" });
        commit("setIsNote", true);
      }
      commit("setLoading", false);
    } catch (error) {
      commit("setMsgError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setMsgError(state, error) {
    state.loading = false;
    if (error.response) {
      const resp = error.response.data;
      if (resp.hasOwnProperty("msg")) {
        state.error = resp.msg;
      } else {
        state.error = error;
      }
    } else {
      state.error = error;
    }
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setAuthenticated(state, is) {
    state.isAuthenticated = is;
  },
  setMismatchPassword(state, isMismatchPassword) {
    state.isMismatchPassword = isMismatchPassword;
    if (isMismatchPassword) {
      state.error = "Mismatched password! please check your password..";
    } else {
      state.error = null;
    }
  },
  setIsPasswordReset(state, isPasswordReset) {
    state.isPasswordReset = isPasswordReset;
  },
  setSSRAuthenticated(state, cookies) {
    const authorization = cookies.get(AUTHORIZATION);
    state.isAuthenticated = !!authorization;
  },
  setIsNote(state, isNote) {
    state.isNote = isNote;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
