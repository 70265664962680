<template>
  <footer class="footer w-100 tc">
    <p class="f6 lh-copy tracked mt0 i mt5">
      {{ $t("footer.share1") }}
      <a
        class="piiia-rust dim link"
        href="https://www.instagram.com/pakuprojekti"
        >{{ $t("footer.share2") }}</a
      >

      {{ $t("footer.share3") }}
    </p>
    <div class="f2 mt3">
      <a
        class="mr1 piiia-grey hover-piiia-turqoise link"
        href="https://www.youtube.com/PIIIA"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'youtube']" />
      </a>
      <a
        class="mr1 piiia-grey hover-piiia-turqoise link"
        href="https://www.instagram.com/pepethevan"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a
        class="mr1 piiia-grey hover-piiia-turqoise link"
        href="https://www.instagram.com/pakuprojekti"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
    </div>

    <div class="mt4 mb5">
      <span class="f7 lh-copy roboto sans-serif ttu tracked b"
        >2023 © PIIIA.com | All Rights Reserved</span
      >
      <div>
        <router-link
          class="f7 fw6 roboto sans-serif ttu tracked piiia-rust dim link mr2"
          :to="$i18nRoute({ name: 'terms' })"
          >{{ $t("footer.terms") }}</router-link
        >
        <router-link
          class="f7 fw6 roboto sans-serif ttu tracked link dim piiia-rust"
          :to="$i18nRoute({ name: 'privacy' })"
          >{{ $t("footer.privacy") }}</router-link
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "custom-footer"
};
</script>
