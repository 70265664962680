<template>
  <div>
    <div v-if="countries.length > 0" class="dib" style="max-width:90%;">
      <select class="w-100" v-model="residentCode">
        <option
          v-for="country in countries"
          v-bind:value="country.country_code"
          :key="country.country_code"
          >{{ country.country }}</option
        >
      </select>
    </div>
    <div v-if="error" class="dark-red">{{ error }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Trans } from "../i18n/trans.js";

export default {
  name: "country",
  methods: {
    fetchCountries() {
      return this.$store.dispatch("country/countries", {
        language: this.$route.params.lang
      });
    }
  },
  computed: {
    ...mapState({
      error: state => state.country.error,
      loading: state => state.country.loading,
      countries: state => state.country.countries
    }),
    ...mapGetters("user", {
      residentCode: "residentCode",
      currency: "currency"
    }),
    residentCode: {
      get() {
        return this.$store.state.user.residentCode;
      },
      set(residentCode) {
        this.$store.commit("user/setTempResidentCode", residentCode);
      }
    },
    language() {
      return this.$route.params.lang;
    }
  },
  mounted() {
    this.fetchCountries();
  },
  watch: {
    $route: "fetchCountries",
    // call again the method if the route changes
    currency(newValue, oldValue) {
      //this.fetchCountries();
    }
  }
};
</script>
