import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");
import { AUTHORIZATION } from "./cookie.type";
import { is401 } from "../../util/error.js";
import Vue from "vue";
// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: true,
  purchases: {
    /* [order_id: number]: PurchasedProducts */
  },
  isPurchased: {
    /* [product_id]: Boolean */
  }
};

// getters
const getters = {
  isLoading(state) {
    return state.loading;
  },
  purchases(state) {
    return state.purchases;
  },
  isError(state) {
    return state.error;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode(state, getters, rootState) {
    return rootState.user.residentCode;
  },
  isPurchased(state) {
    return state.isPurchased;
  }
};

// actions
const actions = {
  async isPurchased({ commit, dispatch }, { productId, cookie }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const token = cookie.get(AUTHORIZATION);
      const { data } = await orderpayment.isPurchased(productId, token);
      commit("setIsPurchased", {
        productId: productId,
        isPurchased: String(data.is_purchased) == "true"
      });
      commit("setLoading", false);
    } catch (error) {
      is401(error, dispatch, cookie);
      commit("setError", error);
    }
  },
  async isPurchasable({ commit, getters }, { productId }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await orderpayment.isPurchasable(
        productId,
        getters.residentCode
      );
      commit("setIsPurchasable", data.is_purchasable);
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  },
  async purchasedProducts({ commit, getters, dispatch }, { cookie, language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const maxSize = 20;
      var pageToken = 0;
      var isNotEmpty = true;
      const token = cookie.get(AUTHORIZATION);
      while (isNotEmpty) {
        // paginate until found empty response
        // paginate until found empty response
        const { data } = await orderpayment.purchasedProducts(
          maxSize,
          pageToken,
          language,
          token
        );
        const purchases = data.purchased_products;
        const pageNextToken = data.next_page_token;
        if (!Array.isArray(purchases) || !purchases.length) {
          // array is empty or not array
          isNotEmpty = false;
        } else {
          commit("setPurchases", purchases);
          pageToken = pageNextToken;
        }
      }

      if (Object.keys(getters.purchases).length == 0) {
        commit("setPurchasedEmpty");
      }
      commit("setLoading", false);
    } catch (error) {
      is401(error, dispatch, cookie);
      commit("setError", error);
    }
  },
  async setIsPurchasable({ commit }, { isPurchasable }) {
    commit("setIsPurchasable", isPurchasable);
  },
  async reset({ commit }) {
    commit("reset");
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  reset(state) {
    state.isPurchasable = null;
    Object.keys(state.purchases).forEach(function(key) {
      Vue.delete(state.purchases, key);
    });
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIsPurchasable(state, { isPurchasable }) {
    state.isPurchasable = isPurchasable;
  },
  setPurchases(state, purchases) {
    if (purchases == null) {
      state.purchases = {};
      return;
    }
    // TODO: maybe check when we have cart
    purchases.forEach(purchase => {
      Vue.set(state.purchases, purchase.order_id, purchase);
    });
  },
  setPurchasedEmpty(state) {
    Vue.set(state.purchases, 0, false);
  },
  setIsPurchased(state, purchased) {
    Vue.set(state.isPurchased, purchased.productId, purchased.isPurchased);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
