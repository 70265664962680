import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");

import { CURRENCY } from "./cookie.type";

// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: false,
  prices: null
};

// getters
const getters = {
  prices(state) {
    return state.prices;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode: (state, getters, rootState) => {
    return rootState.user.residentCode;
  },
  loading(state) {
    return state.loading;
  }
};

// actions
const actions = {
  async calculate({ commit, getters }, { cart, language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const productIds = cart.map(item => item.product_id);
      const resp0 = await orderpayment.shipmentMethod(
        productIds,
        getters.residentCode,
        language,
        getters.currency
      );
      const shipmentCostId = resp0.data.shipment_method.shipment_cost_id;
      const { data } = await orderpayment.pricesCalculate(
        cart,
        getters.currency,
        language,
        getters.residentCode,
        shipmentCostId
      );
      if (typeof shipmentCostId === "undefined") {
        commit(
          "shipment/setShipmentCostId",
          { shipmentCostId: null },
          { root: true }
        );
      } else {
        commit(
          "shipment/setShipmentCostId",
          { shipmentCostId: shipmentCostId },
          { root: true }
        );
      }
      commit("setPrices", data);
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  },
  async setPrices({ commit }, { prices }) {
    commit("setPrices", prices);
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPrices(state, prices) {
    state.prices = prices;
  },
  remove(state) {
    state.prices = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
