import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");

// READMORE:
// https://www.reddit.com/r/vuejs/comments/akeycw/is_it_considered_a_good_practice_to_wrap_all/
// https://github.com/taskill/taskill/blob/master/client/src/store/modules/project.js
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

// initial state
const state = {
  error: null,
  loading: false,
  countries: [],
  language: null
};

// getters
const getters = {
  isPurchasable(state) {
    return state.isPurchasable;
  },
  isLoading(state) {
    return state.loading;
  },
  purchasedProducts(state) {
    return state.purchasedProducts;
  },
  isError(state) {
    return state.error;
  },
  countries(state) {
    return state.countries;
  },
  country(state, getters, rootState) {
    return state.countries.find(
      country => country.country_code === rootState.user.residentCode
    );
  },
  taxRateId(state, getter) {
    return getter.country.tax_rate_id;
  },
  currency: (state, getters, rootState) => {
    return rootState.user.currency;
  },
  residentCode(state, getters, rootState) {
    return rootState.user.residentCode;
  },
  language(state) {
    return state.language;
  }
};

// actions
const actions = {
  async countries({ commit, getters, dispatch }, { language }) {
    try {
      commit("setError", null);
      commit("setLoading", true);
      if (!getters.countries.length || getters.language != language) {
        commit("setLanguage", language);
        const { data } = await orderpayment.getCountries(
          language,
          getters.currency
        );
        commit("setCountries", data.countries);
      }
      commit("setLoading", false);
    } catch (error) {
      commit("setError", error);
    }
  }
};

// mutations
const mutations = {
  setError(state, error) {
    state.loading = false;
    state.error = error;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setDelete(state) {
    state.countries = [];
  },
  setLanguage(state, language) {
    state.language = language;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
