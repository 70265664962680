import { RepoFactory } from "../../api/repofactory";
const orderpayment = RepoFactory.get("orderpayment");

// initial state
const state = {
  shipmentCostId: null
};

// getters
const getters = {
  shipmentCostId(state) {
    return state.shipmentCostId;
  }
};

// actions
const actions = {};

// mutations
const mutations = {
  setShipmentCostId(state, { shipmentCostId }) {
    state.shipmentCostId = shipmentCostId;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
