<template>
  <button
    v-on:click="onClick()"
    class="f6 fw6 hover-piiia-turqoise link roboto sans-serif ttu tracked black-70 mr3"
  >
    <font-awesome-icon fas fa icon="lock" />
    {{ translated }}
  </button>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "sign-in-button",
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$store.commit("user/setIsBurgerMenu", false);
      if (this.isAuthenticated) {
        this.$store.dispatch("auth/signOut", { cookie: this.$cookie });
      } else {
        this.$router.push({ name: "signIn" });
      }
    }
  },
  computed: {
    ...mapState({
      error: state => state.auth.error,
      loading: state => state.auth.loading,
      isAuthenticated: state => state.auth.isAuthenticated
    }),
    translated() {
      if (this.isAuthenticated) {
        return this.$i18n.t("signInButton.signOut");
      } else {
        return this.$i18n.t("signInButton.signIn");
      }
    }
  },
  created() {},
  mounted() {}
};
</script>
