import axios from "axios";

const TARGET_NODE = process.env.VUE_ENV === "server";
const domain = process.env.VUE_APP_SHOPPIIIACOM_SERVER;
const ssr = process.env.VUE_APP_SSR_ORDERS_PAYMENTS_SERVER;

export function createAxios() {
  var baseURL;
  if (TARGET_NODE) {
    baseURL = `${ssr}/twirp/`;
  } else {
    baseURL = `${domain}/twirp/`;
  }
  return axios.create({
    baseURL: baseURL,
    timeout: 15000,
    headers: { "Content-Type": "application/json" }
  });
}
