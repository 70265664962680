import Vue from "vue";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";
import { sync } from "vuex-router-sync";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import { faCouch } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faShuttleVan } from "@fortawesome/free-solid-svg-icons";
import { faPaintRoller } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faTint } from "@fortawesome/free-solid-svg-icons";
import { faWind } from "@fortawesome/free-solid-svg-icons";
import { faWater } from "@fortawesome/free-solid-svg-icons";
import { faSolarPanel } from "@fortawesome/free-solid-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-regular-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

Vue.use(VueCookie);

Vue.config.productionTip = true;

import "../static/custom.css";
import "../static/4100-tachyons.min.css";

// for using vant components
import Vant from "vant";
import "vant/lib/index.css";
import VueCookie from "vue-cookie";
Vue.use(Vant);

// global loading
Vue.prototype.$loading = isLoading => {
  if (isLoading) {
    Vue.prototype.$toast.loading({
      mask: true,
      message: "",
      overlayStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.1)"
      },
      duration: 0
    });
  } else {
    Vue.prototype.$toast.clear();
  }
};

// Fonts
dom.watch();
library.add(
  faLock,
  faTruckLoading,
  faDoorOpen,
  faShower,
  faLaptop,
  faCouch,
  faBed,
  faUtensils,
  faShuttleVan,
  faPaintRoller,
  faTimes,
  faTachometerAlt,
  faTint,
  faWind,
  faWater,
  faSolarPanel,
  faTools,
  faBolt,
  faRoute,
  faPlug,
  faPlus,
  faBookmark,
  faYoutube,
  faInstagram,
  faFacebookSquare,
  faTwitter,
  faGlobe,
  faListAlt,
  faDollarSign,
  faEuroSign,
  faPoundSign,
  faCompass,
  faRuler,
  faCcPaypal,
  faUniversity
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// i18n
import { i18n } from "./i18n/index.js";
import { Trans } from "./i18n/trans.js";
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

export function createApp() {
  const router = createRouter();
  const store = createStore();
  // sync the router with the vuex store.
  // this registers `store.state.route`
  sync(store, router);
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  });
  return { app, router, store };
}

// promise.finally Polyfill
if (!Promise.prototype.finally) {
  Promise.prototype.finally = function(callback) {
    let P = this.constructor;
    return this.then(
      value => P.resolve(callback()).then(() => value),
      reason =>
        P.resolve(callback()).then(() => {
          throw reason;
        })
    );
  };
}

/**
 * Object.prototype.forEach() polyfill
 * https://gomakethings.com/looping-through-objects-with-es6/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.prototype.forEach) {
  Object.defineProperty(Object.prototype, "forEach", {
    value: function(callback, thisArg) {
      if (this == null) {
        throw new TypeError("Not an object");
      }
      thisArg = thisArg || window;
      for (var key in this) {
        if (this.hasOwnProperty(key)) {
          callback.call(thisArg, this[key], key, this);
        }
      }
    }
  });
}
